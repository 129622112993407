.feature {
  position: relative;
  width: 100%;
  
  &.full-width {
    width: calc(100vw - 15px); //remove the scrollbar width
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &__container {
    position: relative;
    min-height: 35vw;

    @media(min-width: $lg) {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 5.625rem;
      padding-bottom: 5.625rem;
    }
  }

  &__video-container {
    height: 100%;
    position: relative;

    .notVisibleMobile {
      display: none;

      @media(min-width: $lg) {
        display: block;
      }
    }

    video {
      width: 100%;
    }

    .feature_vid_ctrls {
      bottom: 1.25rem;
    }

    @media(min-width: $lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;

      video {
        width: auto;
        min-height: 100%;
        min-width: 100%;
      }
    }
  }

  &__img-container {
    width: 100%;

    @media(min-width: $lg) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  &__container-inner {
    display: flex;
    max-width: 105rem;
    justify-content: flex-start;

    @media(min-width: $lg) {
      width: calc(100% - 3.75rem);
    }

    &.right {
      justify-content: flex-end;
    }
  }

  &__content {
    width: 100%;
    padding: 2rem 1.5rem 2.5rem 1.25rem;
    z-index: 2;
    @media(min-width: $lg) {
      max-width: 43.75rem;
      padding: 3.75rem 3.75rem 3.75rem 3.75rem;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 0.9375rem;
    }
  }

  &__title {
    @include h2;
    color: var(--colorHeadingPrimary);

    &--dark {
      @include h2;
      color: var(--colorHeadingDarkBG);
    }
  }

  &__body {
    @include p;
    color: var(--colorTextBase);

    &--dark {
      color: var(--colorTextDarkBg);
    }
  }

  &__buttons {
    &:not(:empty) {
      margin-top: 0.5rem;
    }
    
    .button-link-block {
      margin-bottom: 0;
    }
    
    .button-wrapper {
      margin-top: 1rem;
    }

    &.button {
      display: inline-block;
      margin-right: 1rem;
      margin-top: 1rem;

      @include button-style-lg;
    }
  }

  &__mobile-image {
    display: block;

    @media(min-width: $lg) {
      display: none;
    }
  }

  &__desktop-image {
    display: none;

    @media(min-width: $lg) {
      display: block;
    }
  }

  &__background-image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    display: none;

    @media(min-width: $lg) {
      display: block;
    }
  }
}