.sweden {
  position: relative;

  &__svg-container {
    position: relative;
  }

  &__markers-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &__tool-tip {
    white-space: nowrap;
    color: var(--swedenTooltipColor);
  }
}