.report-flow-step-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media(min-width: $xl) {
    margin: auto;
    max-width: 70rem;
  }

  &__header-title {
    text-align: center;
  }

  &__summary {
    width: 100%;

    & h5 {
      margin-top: 0;
    }
  }

  &__table {
    margin: 1.25rem 0;
    overflow: auto;

    & .amount {
      text-align: right;
    }

    & tbody td.amount {
      min-width: 7.8125rem;
      font-weight: var(--font-weight-heavy);
    }

    & tbody td.correction {
      min-width: 8.4375rem;
    }

    & .table-wrapper table caption {
      @include h6;
      display: table-caption;
    }
  }

  &__edit-button {
    display: flex;
    justify-content: flex-end;

    & button {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
  }

  &__invoice-ref-section {
    width: 100%;
    margin-top: 1.25rem;

    & h5 {
      margin-top: 0;
    }
  }

  &__invoice-ref {
    margin: 0.9375rem 0;
  }

  &__submit-button {
    margin-top: 2.5rem;
    text-align: center;

    & button {
      @include button-style-lg;
      padding-left: 6.25rem;
      padding-right: 6.25rem;
    }
  }

  &__cancel-button {
    margin-top: 0.9375rem;
    text-align: center;
  }

  &__error-messages {
    width: 100%;
    margin-top: 1.25rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }

  &__cancel-button-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }
}