.past-pickups {
  margin: 1.875rem 0;
  padding-bottom: 2rem;

  th:first-child {
    padding-left: 3.125rem;
  }

  td:first-child {
    white-space: nowrap;

    .checkbox-wrapper {
      float: right;
      margin-right: 0.625rem;
      margin-top: -0.0625rem;

      @media(min-width: $md) {
        float: left;
      }
    }
  }

  &__top-panel {
    width: 100%;
    padding: 1.25rem 0;
    margin-bottom: 1.25rem;
    border-radius: 0.9375rem;
    background-color: var(--pastPickupsPanelBgColor);
  }

  &__download-button {
    margin: 0.625rem 0;
  }
}