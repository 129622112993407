.search-app {
  position: relative;
  max-width: calc(100vw - 2.5rem);
  max-height: 12rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0.9375rem;
  border-radius: 1.875rem;
  background-color: var(--searchBlockBgColor);
  transform: translateY(-20%);
  -webkit-font-smoothing: subpixel-antialiased;

  @media(min-width: $xs) {
    max-height: 14rem;
  }

  @media(min-width: $md) {
    max-height: 12.125rem;
    padding-top: 1.5625rem;
    padding-bottom: 0.625rem;
  }

  @media(min-width: $lg) {
    transform: translateY(-29%);
  }

  @media(min-width: $xl) {
    max-width: 63.75rem;
  }

  &.noRecommendations {
    height: 5rem;

    @media(min-width: $md) {
      height: 6.875rem;
    }
  }

  &__container {
    max-width: 56.25rem;
    margin-left: auto;
    margin-right: auto;
  }
}