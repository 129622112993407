.card {
  position: relative;
  
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;

    .panicon {
      margin-left: 0.625rem;
    }

    &.primary {
      background-color: var(--primary);
      border: 0.0625rem solid var(--primary);
      color: var(--colorTextDarkBg);
    }

    &.info {
      background-color: var(--backgroundInfo);
      border: 0.0625rem solid var(--borderColorBase);
      color: var(--colorTextAlertInfo);
    }

    &.danger {
      background-color: var(--backgroundDanger);
      border: 0.0625rem solid var(--backgroundDanger);
      color: var(--colorTextAlertDefault);
    }

   &.success {
      background-color: var(--backgroundSuccess);
      border: 0.0625rem solid var(--backgroundSuccess);
      color: var(--colorTextAlertDefault);
    }
  }
  
  &__header-text {
    text-transform: uppercase;
    font-weight: var(--font-weight-heavy);
    font-size: 0.75rem;
  }
  
  &__content {
    padding: 0.625rem;
    background-color: white;
    border-bottom-left-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    border-left: 0.0625rem solid var(--borderColorBase);
    border-right: 0.0625rem solid var(--borderColorBase);
    border-bottom: 0.0625rem solid var(--borderColorBase);
  }
}