.schedule {
  position: relative;
  width: 100%;

  .tooltip__wrapper {
    position: absolute;
    width: 100%;
    top: -5rem;
    left: 0;
    padding-top: 0.625rem;

    .tooltip__container {
      font-size: 0.75rem !important;
      line-height: 1.4;
    }
  }

  &__heading {
    @include h2;
    color: var(--scheduleHeadingColor);
  }

  &__loading-place {
    line-height: 1.2;
    font-weight: 900;
    font-size: 1rem;
    margin-bottom: 0.625rem;

    @media(min-width: $md) {
      font-size: 1.125rem;
    }
    color: var(--scheduleHeadingColor);

    .hidden {
      display: none;
    }
  }

  .day-container {
    $ScheduleBarColor: var(--scheduleBarColor);
    display: table;
    width: 100%;
    table-layout: auto;
    margin-top: 10.5rem;
    border-top: 0.0625rem solid rgba($ScheduleBarColor, 0.3);

    &__day {
      position: relative;
      display: table-cell;
      height: 1.875rem;
      padding: 0.3125rem 0.25rem 1.25rem 0.15rem;
      text-align: center;
      vertical-align: middle;

      & > span {
        color: var(--scheduleDayColor);
        font-weight: bold;
        font-size: 0.75rem;
      }

      &.today {
        & > span {
          display: inline-block;
          color: var(--scheduleTodayColor);
          background-color: var(--scheduleTodayBgColor);
          border-radius: 50%;
          width: 1.5rem;
          height: 1.5rem;
          padding-top: 0.375rem;
        }
      }

      .day-column {
        position: absolute;
        bottom: 3.125rem;
        left: 0;
        height: 5rem;
        width: 50%;
        background-image: linear-gradient(var(--scheduleDayColumnColor) 33%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 0.1rem 0.75rem;
        background-repeat: repeat-y;

        &::after {
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          width: 200%;
          color: var(--scheduleDayColumnColor);
          font-weight: bold;
          font-size: 0.75rem;
          content: attr(data-today);
          transform: translate(0, -1.25rem);
        }
      }

      .marker {
        opacity: 1;

        &.opaque {
          opacity: 0.25;
        }

        img {
          max-width: 100%;
          height: 100%;
          transform: scale(1);
          transition: transform 0.25s;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
        }

        transition: transform 0.25s;
        animation: scaleIn 0.5s;
        animation-fill-mode: both;

        @keyframes scaleIn {
          0% {
            opacity: 0;
            transform: scale(0);
          }
          60% {
            opacity: 1;
            transform: scale(1.25);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }

  .week-container {
    width: calc(100% + 0.5rem);
    display: table;
    table-layout: auto;
    margin-right: -0.25rem;
    margin-left: -0.25rem;
    border-spacing: 0.25rem;
    border-collapse: separate;

    &__week {
      display: table-cell;
      height: 1.875rem;
      background-color: var(--scheduleWeekBgColor);
      color: var(--white);
      font-weight: bold;
      font-size: 0.75rem;
      text-align: center;
      vertical-align: middle;

      &:first-child {
        border-top-left-radius: 0.9375rem;
        border-bottom-left-radius: 0.9375rem;
      }

      &:last-child {
        border-top-right-radius: 0.9375rem;
        border-bottom-right-radius: 0.9375rem;
      }
    }
  }
}