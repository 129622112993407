.zero-reportings-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    @include h3;
  }

  &__table {
    margin: 1.25rem 0;
  }

  &__correct-text {
    text-align: center;
    margin-top: 2.5rem;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .zero-reportings-edit-button {
      margin-bottom: 0.625rem;
      width: 100%;
    }

    & .zero-reportings-submit-button {
      width: 100%;
    }

    & button {
      width: 100%;
      @include button-style-lg;
    }

    @media(min-width: $md) {
      flex-direction: row;
      & .zero-reportings-edit-button {
        margin-right: 0.625rem;
        margin-bottom: 0;
        width: auto;
      }
      & .zero-reportings-submit-button {
        width: auto;
      }
      & button {
        width: auto;
      }
    }
  }
}