.order-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .bag-labels-submit-order-btn {
    margin: 1.5625rem 0;

    & button {
      @include button-style-lg;
      width: 10.1875rem;
    }
  }

  &__error-messages {
    width: 100%;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(min-width: $sm) {
      width: 25rem;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__input-fields {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media(min-width: $sm) {
      width: 25rem;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__field {
    margin-bottom: 0.9375rem;
  }
}