.igloos-block {
  position: relative;
  height: 100%;

  &__heading {
    font-size: 1.125rem;
    color: var(--colorWidgetText);
    margin-bottom: 0.375rem;
  }

  &__sub-heading {
    @include headingCommon;
    color: var(--colorWidgetText);
    font-size: 1.875rem;
  }

  &__button {
    margin-top: 0.625rem;
    margin-bottom: 1.875rem;
  }

  &__igloo {
    position: relative;
    margin: auto;
    color: var(--secondary);
  }

  &__igloo-count {
    @include headingCommon;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    bottom: 1.875rem;

    .igloo-count {
      position: relative;
      font-size: 3.6875rem;
      line-height: 1;
    }

    .igloo-count-extension {
      position: absolute;
      font-size: 1.0625rem;
      line-height: 1;
      bottom: 0.375rem;
      left: calc(100% + 0.25rem);
    }
  }
  
  &__no-data-message {
    margin: 3rem auto;
    color: var(--colorTextDarkBg);
    text-align: center;
  }
}