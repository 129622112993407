.column-wrapper {
  position: relative;
  width: 100%;

  p:last-child {
    margin-bottom: 1.563rem;

    @media (min-width: $md) {
      margin-bottom: 3.125rem;
    }
  }

  .link-container { // LinkContainer component Wrapper
    padding-top: 0.01rem;
  }

  &.related-links, .related-links { // RelatedLinks component Wrapper
    margin-top: 1.875rem;
    margin-bottom: 3.75rem;
  }

  .news-container { // NewsContainer component Wrapper
    margin-top: 1rem;
  }

  .column-date {
    margin-top: 0;
    margin-bottom: 1.25rem;

    & > .panicon {
      margin-right: 0.5rem;
    }
  }

  &.links-container, .links-container {
    margin-bottom: 1.5625rem;

    @media (min-width: $md) {
      margin-bottom: 3.125rem;
    }
  }

  .link-sm-col {
    min-height: 3.125rem;

    @media (min-width: $md) {
      min-height: 3.75rem;
    }

    & + div:not(.link-sm-col) {
      margin-top: 0.75rem;

      @media (min-width: $md) {
        margin-top: 2.1875rem;
      }
    }
  }

  .link-lg-row {
    margin-bottom: 1.3125rem;
  }

  .link-lg-col {
    min-height: 16.4375rem;

    @media (min-width: $md) {
      min-height: 14.9375rem;
    }
  }

  h2:first-child,
  h3:first-child,
  h4:first-child {
    margin-top: 0;
  }

  .full-screen {
    h2:first-child {
      margin-top: 0.83rem;
    }

    h3:first-child {
      margin-top: 1.17rem;
    }

    h4:first-child {
      margin-top: 1.33rem;
    }
  }

  p > img {
    max-width: 100%;
    height: auto;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;

    @media (min-width: $md) {
      margin-top: 2.1875rem;
      margin-bottom: 2.1875rem;
    }
  }
}