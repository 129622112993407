.saved-registration-item {
  display: flex;
  flex-direction: column;
  @media(min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__article-description {
    @include headingCommon;
    font-size: 1.125rem;
    margin: 0.4375rem 0 0.75rem 0;
  }

  &__data-descriptions {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 0.9375rem;

    & button {
      width: 100%;
    }

    & .delete-registration-button {
      margin-bottom: 0.625rem;
    }

    @media(min-width: $sm) {
      flex-direction: row;
      & button {
        width: 6.875rem;
      }
      & .delete-registration-button {
        margin-right: 0.625rem;
        margin-bottom: 0;
      }
    }
    @media(min-width: $md) {
      margin-top: 0;
      & .delete-registration-button {
        margin-right: 0.625rem;
      }
    }
  }
}