.article-item-box {
  width: 100%;
  height: 26.25rem;
  display: inline-flex;
  flex-direction: column;
  position: relative;

  & > div {
    flex: 1;
    position: relative;
  }

  & .panel__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  & button {
    width: 100%;
  }

  &__checkbox-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    & img {
      max-width: 100%;
      max-height: 12.5rem;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;

    & span:not(.panicon) {
      margin-bottom: 0.3125rem;
      font-size: 0.875rem;
    }

    & .article-details-button {
      margin-top: 0.625rem;
    }
  }

  &__title {
    & > h5 {
      display: inline-block;
      margin-right: 0.3125rem;
      max-width: calc(100% - 1.875rem);
      color: var(--colorTextDarkBg);
    }

    & .panicon {
      margin-top: -0.1875rem;
    }
  }
}