.all-customers-dropdown {
  &__customers-wrapper {
    border-top: 0.0625rem solid var(--borderColorBase);
    border-bottom: 0.0625rem solid var(--borderColorBase);
    padding: 0.9375rem 0;
  }

  &__option-wrapper {
    &:not(:last-child) {
      margin-bottom: 0.9375rem;
    }

    & .panel__content {
      padding: 1.25rem;
    }

    & button:not(.button--expandable) {
      height: 3.125rem;
      width: 100%;
      min-width: 8.875rem;
      border-radius: 1.5625rem;
      font-size: 1rem;
      font-weight: 900;
      padding: 0.9375rem 1.75rem;

      &:focus::before {
        border-radius: 1.5625rem;
      }
    }

    @media(min-width: $sm) {
      & button:not(.button--expandable) {
        width: auto;
      }

      & .panel__content {
        padding: 2.5rem;
      }
    }
  }

  &__head-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media(min-width: $sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .customer {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625rem;

    @media(min-width: $sm) {
      margin-bottom: 0;
    }

    &__name {
      @include h3;
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 0.625rem;
      overflow-wrap: break-word;
      word-break: break-word;
      color: var(--colorTextDarkBg);

      @media(min-width: $sm) {
        margin-bottom: 0;
        margin-top: 0;
        overflow-wrap: normal;
        word-break: normal;
      }
    }

    &__org-nr {
      font-size: 0.875rem;
      margin-top: 0.625rem;
      font-weight: normal;
      color: var(--colorTextDarkBg);
    }

    &__step-title {
      display: inline-block;
      margin-top: 0.625rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.3125rem;
      background-color: var(--pendingRegistrationTitleBgColor);
      color: var(--pendingRegistrationTitleColor);
      font-size: 0.75rem;

      @media(min-width: $sm) {
        transform: translateY(-0.3125rem);
      }
    }
  }

  &__no-customers-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.8125rem 0.625rem;

    .no-customers-message {
      font-size: 0.875rem;
      text-align: center;
      width: 100%;
      line-height: 1.5;

      & div[role='link'] span {
        font-size: 0.875rem;
      }

      @media(min-width: $md) {
        font-size: 1.125rem;
        & div[role='link'] span {
          font-size: 1.125rem;
        }
      }

      @media(min-width: $lg) {
        width: 52.5rem;
      }
    }
  }
}