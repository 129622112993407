@mixin button-style-lg {
  min-height: 3.125rem;
  border-radius: 1.5625rem;
  font-size: 0.75rem;
  font-weight: var(--font-weight-heavy);
  padding: 0.9375rem 1.25rem;

  @media(min-width: $sm) {
    font-size: 1rem;
    padding: 0.9375rem 2.5rem;
  }

  &.outlined {
    padding: 0.9375rem 2.5rem;

    &:hover:not(:disabled) {
      padding: 0.9375rem 2.5rem;
    }
  }

  &:focus::before {
    border-radius: 1.5625rem;
  }
}

@mixin button-pantamera {
  border-radius: 0rem !important;
  border: 0;
  text-transform: uppercase;
  transition: all 0.3s;
  font-family: var(--font-family-pantamera);
  margin-left: 0.7rem;
  margin-bottom: 0.7rem;

  &:hover:not(:disabled) {
    margin-top: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0;
  }
  &:focus::before {
    border-radius: 0rem !important;
  }

  &:active::before {
    border: 0rem !important;
  }
}

@mixin button-outlined {
  background-color: transparent;
  border: 0.125rem solid var(--backgroundButtonSecondary);
  color: var(--backgroundButtonSecondary);
  padding: 0.4375rem 1.125rem;
  transition: background-color 0.1s ease, color 0.1s ease;


  &:hover:not(:disabled) {
    background-color: var(--backgroundButtonSecondary);
    color: var(--colorTextDarkBg);
    border-color: transparent;
  }

  &::before {
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    border-radius: 0.4375rem;
  }
}

@mixin button-outlined-inverted {
  border: 0.125rem solid var(--colorTextDarkBg);
  color: var(--colorTextDarkBg);
  background: transparent;

  &:hover:not(:disabled) {
    border-color: transparent;
    background-color: var(--colorTextDarkBg);
    color: var(--backgroundButtonSecondary);
  }

  &::before {
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    border-radius: 0.4375rem;
  }
}
