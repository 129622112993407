.articles {
  $ItemsPerRowSm: 2;
  $ItemsPerRowLg: 3;
  $ItemsPerRowXxl: 4;
  $ArticleMargin: 1.875rem;

  display: flex;
  flex-wrap: wrap;

  .article-item-box {
    margin-bottom: $ArticleMargin;
  }

  td.status .panicon {
    padding-left: 0.625rem;
  }

  @media(min-width: $sm) {
    .article-item-box {
      width: calc(50% - (#{$ArticleMargin} * (#{$ItemsPerRowSm} - 1) / #{$ItemsPerRowSm}));
      margin-right: $ArticleMargin;

      &:nth-child(#{$ItemsPerRowSm}n) {
        margin-right: 0;
      }
    }
  }

  @media(min-width: $md) {
    th.articleDescription .header-text {
      padding-left: 1.875rem;
    }

    td > .article-details-button {
      float: right;
    }
  }

  @media(min-width: $lg) {
    .article-item-box {
      width: calc(33% - (#{$ArticleMargin} * (#{$ItemsPerRowLg} - 1) / #{$ItemsPerRowLg}));
      margin-right: $ArticleMargin;

      &:nth-child(#{$ItemsPerRowLg - 1}n) {
        margin-right: $ArticleMargin;
      }

      &:nth-child(#{$ItemsPerRowLg}n) {
        margin-right: 0;
      }
    }
  }

  @media(min-width: $xxl) {
    .article-item-box {
      width: calc(25% - (#{$ArticleMargin} * (#{$ItemsPerRowXxl} - 1) / #{$ItemsPerRowXxl}));
      margin-right: $ArticleMargin;

      &:nth-child(#{$ItemsPerRowXxl - 1}n) {
        margin-right: $ArticleMargin;
      }

      &:nth-child(#{$ItemsPerRowXxl}n) {
        margin-right: 0;
      }
    }
  }

  &__article-column {
    display: inline-flex;
    justify-content: space-between;
    width: 85%;

    > div {
      width: 3.75rem;
      justify-content: center;
      order: 2;
      margin-top: -0.3125rem;
    }

    > span {
      order: 1;
    }

    @media(min-width: $md) {
      width: auto;
      justify-content: flex-start;

      > div {
        margin-top: 0;
        width: auto;
        order: 1;
      }

      > span {
        order: 2;
        margin-left: 0.625rem;
      }
    }
  }
}
