.recycling-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.3125rem;

    &__label-wrapper {
        display: flex;
        flex-direction: column;

        & .panicon {
            font-size: 1.375rem;
        }

        @media(min-width: $md) {
            & .panicon {
                font-size: 1.75rem;
            }
        }
    }

    &__description {
        color: var(--colorTextBase);
        font-weight: var(--font-weight-medium);
        margin-bottom: 0.125rem;
    }

    &__label {
        font-size: 1rem;
        
        @media(min-width: $md) {
            font-size: 1.125rem;
        }
    }
}