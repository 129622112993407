.monthly-reports {
  position: relative;
  height: 100%;

  &__heading {
    font-size: 1.125rem;
    color: var(--monthlyReportsWidgetTextColor);
    margin-bottom: 0.375rem;
  }

  &__sub-heading {
    min-height: 0.0625rem;
    margin-bottom: 0.75rem;
    font-size: 1.875rem;
    font-weight: var(--font-weight-heavy);
    color: var(--monthlyReportsWidgetTextColor);
  }

  &__reports-table {
    margin-top: 1.25rem;
    @include widget-table;

    & td.period span {
      font-size: 0.75rem;
      padding-bottom: 0;
    }

    @media(min-width: $md) {
      & th:not(.type):not(.period),
      & td:not(.type):not(.period) {
        text-align: right;
      }
    }
  }

  &__disclaimer {
    display: block;
    font-size: 0.625rem;
    color: var(--colorWidgetText);
    margin: 0.625rem 0 0.9375rem 0;
    font-weight: var(--font-weight-heavy);
  }
}