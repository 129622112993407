.faq-items {
  margin-bottom: 6.25rem;
  row-gap: 0;

  .faq-item {
    position: relative;
    margin-bottom: 1.25rem;
    border-radius: 0.9375rem;
    border: 0.0625rem solid var(--faqItemBorderColor);

    &--expanded {
      .faq-item__answer {
        transition: max-height .5s, padding .25s;
        border-top: 0.0625rem solid var(--faqItemBorderColor);
        padding: 1.5625rem;
        max-height: 100rem;

        @media(min-width: $md) {
          max-height: 75rem;
        }
      }
    }

    &__toggle {
      position: absolute;
      top: 1.5625rem;
      right: 1.5625rem;
    }

    &__question {
      margin-top: 0;
      margin-bottom: 0;
      padding: 1.5625rem 3.4375rem 1.5625rem 1.5625rem;
    }

    &__answer {
      max-height: 0;
      padding: 0 1.5625rem;
      transition: padding 0.25s;
      overflow: hidden;

      p:last-child {
        margin-bottom: 0;
      }

      img:last-child {
        margin-bottom: 0;
      }
    }

  }
}