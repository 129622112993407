.all-reports-modal {
  & h5 {
    margin-top: 0;
  }

  &__reports-table {
    margin-top: 0.9375rem;
    @include widget-table;

    & td.period span {
      font-size: 0.75rem;
      padding-bottom: 0;
    }

    @media(min-width: $md) {
      & th:not(.type):not(.period),
      & td:not(.type):not(.period) {
        text-align: right;
      }
    }
  }
}