.generate-wrapper {
  text-align: center;

  .flyer-preview {
    position: relative;
    width: 20.9375rem;
    height: 29.9375rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.1875rem;

    &__image {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
      }
    }

    &__field {
      position: absolute;
      width: 100%;
      left: 0;
      font-size: 1.1875rem;
      font-family: 'Arial';
      font-weight: 700;
      padding-left: 2.5625rem;
      padding-right: 2.5625rem;
      text-align: center;
      transform: translateY(-50%);

      &.sm-blue {
        color: var(--primary);
        font-size: 0.5rem;
        text-transform: uppercase;
      }

      &.sm-black {
        font-size: 0.5rem;
        font-weight: 500;
      }

      &.sm-black-flyer {
        font-weight: 500;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        font-size: 0.685rem !important;
      }

      &.blue {
        color: var(--primary);
      }

      &.association {
        padding-left: 4.5rem;
        padding-right: 4.5rem;
      }

      &.flyer-text {
        font-weight: 700;
        font-size: 0.5375rem !important;
      }
    }

    &__field--left {
      left: auto;
      right: 0.0625rem;
      width: 50%;
      padding: 0;

      &.sm-black-flyer {
        right: 10%;
      }
    }

    &__field--right {
      left: 0.9625rem;
      width: 50%;
      padding: 0;

      &.sm-black-flyer {
        left: 9%;
      }
    }

    &__logo {
      position: absolute;
      width: 100%;

      img {
        height: 2.75rem;
      }
    }
  }

  &__field-container {
    text-align: left;
    margin-bottom: 1.25rem;

    .input-text + .input-text-area, .input-text + .input-text {
      padding-top: 0.625rem;
    }

    .input-text-area + .input-text {
      padding-top: 0.4375rem;
    }

    .input-toggle-button__option {
      width: 50%;
    }

    p {
      font-size: 0.875rem;
    }
  }

  &__button-container {
    margin-top: 3.125rem;

    .button-wrapper {
      margin-bottom: 1.875rem;

      button {
        width: 17.1875rem;

        @include button-style-lg;
      }
    }
  }
}