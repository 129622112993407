@import "../scss/mixins/button-mixins";
@import "../scss/layout-variables";

.button-wrapper {
  position: relative;
  display: inline-block;
}

.button {
  border-radius: 1.25rem;
  padding: 0.4375rem 1.25rem;
  font-size: 0.75rem;
  font-weight: var(--font-weight-heavy);
  font-family: var(--font-family-base);
  position: relative;
  cursor: pointer;

  &:not(&--expandable) {
    min-height: 1.875rem;
  }

  &:disabled {
    opacity: 0.4;
    cursor: initial;
  }

  &--large {
    @include button-style-lg;
  }

  &--primary {
    background-color: var(--backgroundButtonPrimary);
    color: var(--colorButtonPrimary);
    border: none;

    &:hover:not(:disabled) {
      color: var(--colorButtonPrimaryHover);
      background-color: var(--backgroundButtonPrimaryHover);
    }
  }

  &--secondary {
    background-color: var(--backgroundButtonSecondary);
    color: var(--colorButtonSecondary);
    border: none;

    &:hover:not(:disabled) {
      color: var(--colorButtonSecondaryHover);
      background-color: var(--backgroundButtonSecondaryHover);
    }
  }

  &--outlined {
    background-color: transparent;
    border: 0.125rem solid var(--backgroundButtonSecondary);
    color: var(--backgroundButtonSecondary);
    padding: 0.4375rem 1.125rem;
    transition: background-color 0.1s ease, color 0.1s ease;


    &:hover:not(:disabled) {
      background-color: var(--backgroundButtonSecondary);
      color: var(--colorTextDarkBg);
      border-color: transparent;
    }

    &::before {
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      width: calc(100% + 0.5rem);
      height: calc(100% + 0.5rem);
      border-radius: 0.4375rem;
    }
  }

  &--outlined-inverted {
    @include button-outlined-inverted;
  }

  &--pantamera-primary {
    @include button-pantamera;
    background-color: var(--backgroundButtonPantamera);
    color: var(--colorButtonPantamera);
    box-shadow: -0.35rem 0.35rem 0 0 var(--primary), -0.7rem 0.7rem 0 0 var(--boxShadowColorButtonPantamera);

    &:hover:not(:disabled) {
      background-color: var(--backgroundcolorButtonPantameraHover);
      color: var(--colorPantameraButtonHover);
      box-shadow: 0 0 0 0 var(--colorPantameraButtonHoverBackground), 0 0 0 0 var(--colorPantameraButtonHover);
    }
  }

  &--pantamera-secondary {
    @include button-pantamera;
    background-color: var(--backgroundButtonPantameraSecondary);
    color: var(--colorButtonPantameraSecondary);
    box-shadow: -0.35rem 0.35rem 0 0 var(--secondary), -0.7rem 0.7rem 0 0 var(--boxShadowColorButtonPantameraSecondary);

    &:hover:not(:disabled) {
      background-color: var(--backgroundcolorButtonPantameraSecondaryHover);
      color: var(--colorPantameraSecondaryButtonHover);
      box-shadow: 0 0 0 0 var(--colorPantameraSecondaryButtonHoverBackground), 0 0 0 0 var(--colorPantameraSecondaryButtonHover);
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .panicon {
    margin-left: 0.6rem;
  }
}

