.activation-content {
  .company-item {
    margin: 0.9375rem 0;


    &__name {
      @include h3;
      color: var(--colorTextDarkBg);

      & {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &__org-nr {
      font-size: 0.875rem;
      font-weight: normal;
      color: var(--colorTextDarkBg);
    }
  }

  &__error-message {
    width: 100%;
    margin: 1.25rem 0 1.25rem auto;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;

    & button {
      @include button-style-lg;
      min-width: 16.875rem;
    }
  }
}