@import '../../../../../../Frontend/scss/layout-variables';
@import '../../../../../../Frontend/scss/variables';
@import '../../../../../../Frontend/scss/mixins/layout-mixins';

.deposit-label-order-summary {
  .panel__content {
    padding: 1rem 1.25rem 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media(min-width: $md) {
      flex-direction: row;
    }
  }

  p {
    @include small
  }

  &__order-info {
    p:last-of-type {
      margin-bottom: 0;
    }

    @media(min-width: $md) {
      max-width: 65%;
    }
  }

  &__divider {
    border-bottom: 0.0625rem solid var(--borderColorBase);
    margin-bottom: 0.5rem;
  }

  &__buttons {
    margin-top: 1rem;

    & .remove-order-button {
      width: calc(50% - 0.375rem / 2);
      margin-right: 0.375rem;
    }

    & .edit-order-button {
      width: calc(50% - 0.375rem / 2);
    }

    & button {
      width: 100%;
    }

    @media(min-width: $md) {
      margin-top: 0;
      & .edit-order-button,
      & .remove-order-button {
        width: auto;
      }
      & button {
        width: auto;
      }
    }
  }
}