.rvm-list {
  --rvMargin: 1.875rem;

  & > div {
    margin-bottom: var(--rvMargin);
  }

  @media(min-width: $sm) {
    & > div {
      display: inline-block;
      margin-right: var(--rvMargin);
      width: calc((100% - var(--rvMargin)) / 2);

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @media(min-width: $lg) {
    & > div {
      width: calc((100% - var(--rvMargin) * 2) / 3);

      &:nth-child(2n) {
        margin-right: var(--rvMargin);
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}