.input-text {
  position: relative;
  display: flex;
  flex-direction: column;
  
  &__label {
    font-weight: var(--font-weight-heavy);
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }
  
  &__wrapper {
    position: relative;
    color: var(--colorTextBase);

    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border-radius: 0.4375rem;
    }

    &:focus-within {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineFocusTextInput);
      }
    }

    &.error {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineErrorTextInput);
      }
    }
    
    & input {
      position: relative;
      display: inline-block;
      font-family: var(--font-family-base);
      font-size: 0.875rem;
      height: 2.5rem;
      width: 100%;
      min-width: 6.25rem;
      background: var(--backgroundTextInput);
      border-radius: 0.3125rem;
      border: 0.0625rem solid var(--borderTextInput);
      transition: all ease-in-out 0.1s;
      padding: 0 0.9375rem;

      &:disabled {
        background: var(--backgroundDisabledTextInput);
      }

      &::placeholder {
        font-style: italic;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
