.mega-dropdown {
  position: absolute;
  top: 3.75rem;
  left: 0;
  max-height: calc(100vh - var(--headerBottomHeight));
  background-color: var(--backgroundMegaMenuDropdown);
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  pointer-events: none;
  box-shadow: 0 0 0.9375rem rgba(0, 105, 180, 0.3);
  outline-color: transparent;
  z-index: 1;

  &.open {
    display: none;
    opacity: 1;
    visibility: visible;
    overflow: auto;
    pointer-events: auto;
    outline: none;

    @media(min-width: $xl) {
      display: block;
    }
  }

  &__container {
    display: table;
    width: 100%;
  }

  .teaser {
    display: table-cell;
    width: 34.6875rem;
    padding: 1.875rem;
    vertical-align: middle;
    text-align: center;

    .bgImage {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    & .button {
      @include button-style-lg;
      display: inline-block;
    }

    .dark {
      @include dark-mode;
    }

    &__image {
      display: block;
      margin-bottom: 0.9375rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 18.75rem;
    }

    &__heading {
      color: var(--colorMegaMenuTeaserHeading);
      display: block;
      margin-bottom: 1rem;
    }

    &__teaser-text {
      color: var(--colorMegaMenuTeaserText);
      display: block;
      margin-bottom: 1.25rem;

      p {
        font-size: 0.875rem;
      }

      a {
        color: var(--colorMegaMenuTeaserTextLink);
      }
    }
  }

  .menu {
    display: table-cell;
    vertical-align: top;
    padding: 3.125rem;

    a:not(.menu__title) {
      font-size: 1.125rem;
      font-weight: 900;
      color: var(--colorMegaMenuLink);
      line-height: 1.2;
      transition: color 0.2s ease-in-out;
      position: relative;

      .panicon {
        position: relative;
        right: 0;
        transition: right 0.2s linear;
      }

      &:after,
      &.link__locked:after,
      &.link__external:after {
        position: absolute;
        font-family: 'PanIcons';
        transition: right 0.2s linear;
      }

      &:after {
        content: '\e922';
        font-size: 0.75rem;
        right: -1.25rem;
        bottom: 0.25rem;
        color: var(--colorMegaMenuLinkArrow);
      }

      &.link__locked {
        &:after {
          content: '\e92f';
          font-size: 1.125rem;
          right: -1.25rem;
          bottom: 0.1rem;
        }
      }

      &.link__external {
        border: none !important;

        &:after {
          content: '\e90a';
          font-size: 1rem;
          right: -1.4375rem;
          bottom: 0.125rem;
        }

        &:not(:focus):before {
          display: none !important;
        }
      }

      &:hover {
        color: var(--colorMegaMenuLink);

        &:after {
          right: -1.4375rem;
        }
      }

      @media (min-width: 103.125rem) {
        font-size: 1.3125rem;
      }
    }

    &__title {
      @include h2;
      margin-bottom: 1.875rem;
      padding: 0.625rem 0.625rem 0 0.625rem;
      font-family: var(--font-family-pantamera);

      .panicon {
        color: var(--secondary);
        font-size: 44px;
        min-width: 44px;
        transform: translateY(-0.5rem);
        margin-right: 0.75rem;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;

      &::after {
        content: '';
        flex: 0 0 32%;
      }
    }
    
    &__list-item {
      position: relative;
      flex: 0 0 32%;
      min-height: 4.375rem;
      margin-bottom: 1.25rem;
      padding: 0.625rem 1.875rem 0.625rem 0.9375rem;

      &.current-page {
        border-left: 0.375rem solid var(--backgroundBorderActiveMenuItem);
      }
      
      .list-item {
        &__container {
          position: relative;
        }
        
        &__teaser-text {
          display: block;
          font-size: 0.875rem;
          padding-top: 0.55rem;
          line-height: 1.2;
        }
      }
    }
  }
}