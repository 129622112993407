.article-registrations-block {
  margin-bottom: 3.75rem;

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 3.125rem 0 1.25rem 0;

    & h3 {
      margin: 0;
    }

    & .new-article-button {
      margin-top: 0.625rem;

      & button {
        @include button-style-lg;
      }
    }

    @media(min-width: $sm) {
      flex-direction: row;
      align-items: center;
      & .new-article-button {
        margin-top: 0;
        margin-left: 1.25rem;
      }
    }
  }

  .tab-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.25rem;

    &__item {
      position: relative;
      color: var(--colorTextSecondary);
      cursor: pointer;
      font-size: 1.3125rem;
      font-weight: var(--font-weight-heavy);
      margin-right: 1.25rem;
      opacity: 0.3;
      padding-bottom: 0.625rem;
      transition: opacity 0.2s ease-in-out;

      &.active {
        opacity: 1;
        border-bottom: 0.125rem solid var(--colorTextSecondary);
      }

      @include focusStyle;
    }

    &__item-count {
      font-weight: var(--font-weight-base);
      margin-left: 0.3125rem;
    }
  }
}