.link-badge {
  position: relative;
  display: block;
  cursor: pointer;

  &__wrapper {
    position: relative;
    display: block;
    cursor: pointer;
  }

  &:hover {
    display: block;
    border: none;
    transform: scale(1.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  &__container {
    position: relative;
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: row;
    justify-content: center;
    border-radius: 0.3125rem;
    height: 3.125rem;
    width: 100%;
    max-height: 14.8125rem;
    background-color: var(--backgroundLinkBadge);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    &--yellow {
      background-color: var(--backgroundLinkBadgeYellow);
    }

    &--turquoise {
      background-color: var(--backgroundLinkBadgeTurquoise);
    }

    /* Sizes */
    &.sm {
      height: 2.5rem;
      transition: margin 0.1s ease-in-out, height 0.1s ease-in-out,
      width 0.1s ease-in-out, padding 0.1s ease-in-out;
    }

    &.md {
      height: 3.125rem;
      transition: margin 0.1s ease-in-out, height 0.1s ease-in-out,
      width 0.1s ease-in-out, padding 0.1s ease-in-out;
    }

    &.lg {
      transition: margin 0.1s ease-in-out, max-height 0.1s ease-in-out,
      width 0.1s ease-in-out, padding 0.1s ease-in-out;
      padding: 1.25rem;
      height: auto;
      flex-direction: column;
      border-radius: 0.9375rem;
    }

    /* Turn off transitions in Edge and Firefox */
    @supports (display: -ms-grid) or (-moz-appearance: none) {
      &.sm,
      &.md,
      &.lg {
        transition: none;
      }
    }

    @media(min-width: $md) {
      &.sm {
        height: 3.125rem;
      }
      &.md {
        height: 3.75rem;
      }
    }

    .loading-indicator {
      margin-left: auto;
      margin-right: auto;
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__top-container {
    display: flex;
    flex: 1;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex: auto;
    }
  }

  &__description-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    background-color: var(--blue);
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    color: var(--brightsun) !important;

    &--yellow {
      background-color: var(--brightsun);

      & .panicon {
        color: var(--blue) !important;
      }
    }

    &--turquoise {
      background-color: var(--transparent);

      & .panicon {
        color: var(--lightyellow) !important;
      }
    }

    &--blue {
      & .panicon {
        color: var(--brightsun) !important;
      }
    }

    & .panicon {
      font-size: 1.25rem !important;
    }

    /* Sizes */
    &.sm {
      width: 2.5rem;

      & .panicon {
        font-size: 1.5rem !important;
      }
    }

    &.lg {
      background-color: var(--transparent);
      order: 2;
      border-top-left-radius: 0.3125rem;
      border-top-right-radius: 0.3125rem;
      border-bottom-left-radius: 0;
      width: 3.75rem;
      height: auto;
      min-height: 4.6875rem;
      max-height: 5.4rem;
      margin: 0;

      & .panicon {
        font-size: 3rem !important;
      }
    }

    @media(min-width: $md) {
      &.sm {
        width: 3.125rem;
        transition: width 0.1s ease-in-out, height 0.1s ease-in-out,
        margin-left 0.1s ease-in-out, padding-left 0.1s ease-in-out;
      }
      &.md {
        width: 3.75rem;
        transition: width 0.1s ease-in-out, height 0.1s ease-in-out,
        margin-left 0.1s ease-in-out, padding-left 0.1s ease-in-out;
      }
      &.panicon {
        font-size: 1.5rem !important;
      }
    }
    /* Turn off transitions in Edge and Firefox */
    @supports (display: -ms-grid) or (-moz-appearance: none) {
      &.sm,
      &.md {
        transition: none;
      }
    }
    /* Turn off transitions in IE */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &.sm,
      &.md {
        transition: none;
      }
    }
  }

  &__news-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: var(--font-weight-heavy);
    color: var(--colorLinkBase);

    & span:first-child {
      font-size: 1.125rem;
    }

    & span:last-child {
      font-size: 0.625rem;
    }

    &.lg {
      & span:first-child {
        font-size: 1.5rem;
      }

      & span:last-child {
        font-size: 0.75rem;
      }
    }

    &--turquoise {
      color: var(--lightyellow);
    }
  }

  &__link-text-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 0.9375rem;
    padding-right: 0.9375rem;
    font-size: 0.875rem;
    font-weight: var(--font-weight-heavy);
    color: var(--primary);
    overflow: hidden;

    &--turquoise {
      color: var(--lightyellow);
    }

    /* Sizes */
    &.sm {
      font-size: 0.75rem;
    }

    &.lg {
      order: 1;
      margin-left: 0;
      font-size: 1.1875rem;
    }

    @media(min-width: $md) {
      margin-left: 1.25rem;
      padding-right: 1.25rem;
      font-size: 1rem;

      &.sm {
        font-size: 0.875rem;
      }
      &.lg {
        font-size: 1.5rem;
      }
    }
  }

  &__link-text {
    $MaxNrOfTitleLines: 1;
    $MaxNrOfTitleLinesLg: 3;
    $LineHeightFactor: 1.2;
    $LineHeightTitle: 1rem;
    $LineHeightTitleLg: 1.5rem;

    display: block;
    display: -webkit-box;
    overflow: hidden;
    line-height: $LineHeightFactor;
    text-overflow: ellipsis;
    -webkit-line-clamp: $MaxNrOfTitleLines;
    -webkit-box-orient: vertical;
    max-height: calc($LineHeightTitle * $MaxNrOfTitleLines * $LineHeightFactor);

    &.lg {
      -webkit-line-clamp: $MaxNrOfTitleLinesLg;
      max-height: calc($LineHeightTitleLg * $MaxNrOfTitleLinesLg * $LineHeightFactor);
    }
  }

  &__link-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);

    &--turquoise {
      color: var(--lightyellow);
    }

    & .panicon {
      margin-right: 0.9375rem !important;
      font-size: 1rem !important;
    }

    /* Sizes */
    &.sm {
      &.panicon {
        font-size: 0.875rem !important;
      }
    }

    &.lg {
      display: none;
    }

    @media(min-width: $md) {
      &.sm {
        &.panicon {
          margin-right: 0.9375rem !important;
          font-size: 1rem  !important;
        }
      }
      &.panicon {
        margin-right: 1.25rem !important;
        font-size: 1.125rem !important;
      }
    }
  }

  &__content-container {
    display: none;
    margin-top: 1.25rem;
    line-height: 1.5rem;

    &.lg {
      position: relative;
      color: var(--blue);
      font-weight: var(--font-weight-heavy);
      display: block;
      display: -webkit-box;
      overflow: hidden;
      height: calc(1.5rem * 4);
      max-height: calc(1.5rem * 4);
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;

      @media(min-width: $md) {
        max-height: calc(1.5rem * 3);
        -webkit-line-clamp: 3;
      }
    }
  }

  &__content-text {
    color: var(--colorTextBase);
    font-weight: 400;
  }   
}