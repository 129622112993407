@import "../../../Frontend/scss/layout-variables";
@import "../TopListHeader/Scss/top-list-header";

.association-battle {
    background-color: var(--blue);
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @media(min-width: $sm) {
        padding-top: 2rem;
        
        &--wide {
            padding-left: 10rem;
            padding-right: 10rem;
        }
    }

    @media(min-width: $xl) {
        padding-top: 2rem;
        
        &--wide {
            padding-left: 20rem;
            padding-right: 20rem;
        }
    }
}