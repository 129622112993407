.future-pickups {
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50vw);
    width: 100vw;
    height: 100%;
    background-color: var(--backgroundScheduleContainer);
    z-index: -1;
  }
  
  &__schedule {
    position: relative;
    margin-top: 4rem;
    padding: 4.6875rem 0 4.6875rem 0;
    background: var(--backgroundScheduleContainer);
    background-size: cover;
    background-position: center center;
    overflow-x: visible;

    .schedule {
      margin-bottom: 2.5rem;
    }

    td:first-child {
      width: 13.75rem;
    }

    td:nth-child(2) {
      width: 17.1875rem;
    }
  }

  &__equipment-button {
    position: absolute;
    top: -3.125rem;
    right: 0.9375rem;
    z-index: 1;

    @media(min-width: $md) {
      top: 0;
    }
  }
}