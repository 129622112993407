﻿.summary-panel-container {
  margin-bottom: 1.875rem;
  color: var(--colorTextDarkBg);

  .panel {
    margin-bottom: 1.875rem;
  }

  .preview-section-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &:last-child {
      margin-bottom: 0;
    }

    &__sub-heading {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 0.625rem;

      span {
        @include headingCommon;
      }
    }

    &__item-heading {
      font-size: 0.75rem;
      font-weight: 900;
      padding-bottom: 0.5rem;
    }

    &__item-wrapper {
      margin-bottom: 0.75rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 0.9375rem;

      @media (min-width: $sm) {
        width: 50%;
      }

      @media (min-width: $md) {
        width: 25%;
      }

      &--slim {
        @media (min-width: $md) {
          width: 16%;

          &:nth-child(4) {
            width: 36%;
          }
        }
      }

      &--single {
        width: 100% !important;
      }

      & label {
        font-weight: var(--font-weight-heavy);
        font-size: 0.75rem;
        margin-bottom: 0.1875rem;
      }

      & span {
        @include small;
        word-break: break-word;
      }

      .supplierTypeRow {
        font-size: inherit;
        margin-bottom: inherit;
      }
    }
  }
}