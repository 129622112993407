.edit-supplier-type-modal {
  .modal__header {
    text-align: center;
  }

  margin: 3.125rem 0;

  &__fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2.5rem;
  }

  &__field {
    margin-bottom: 0.9375rem;
    width: 100%;

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.25rem;

    & .edit-supplier-type-submit-button {
      margin-bottom: 0.625rem;
      width: 100%;
      text-align: center;

      button {
        @include button-style-lg;
        width: 100%;
        @media(min-width: $sm) {
            width: auto;          
        }
      }
    }    
  }

  &__error-messages {
    width: 100%;
    padding-top: 1.25rem;
    margin: auto;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }
}