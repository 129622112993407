@import "../layout-variables";

@mixin headingCommon {
  display: block;
  font-weight: 900;
  color: var(--colorHeadings);
  line-height: 1.2;
}

@mixin h1 {
  @include headingCommon;
  font-size: 1.6875rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  font-family: var(--font-family-pantamera);

  @media (min-width: $md) {
    font-size: 2.8125rem;
    margin-top: 2.25rem;
  }
}

@mixin h2 {
  @include headingCommon;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;

  @media (min-width: $md) {
    font-size: 2.25rem;    
  }
}

@mixin h3 {
  @include headingCommon;
  font-size: 1.3125rem;
  margin-bottom: 0.9375rem;
  margin-top: 0.9375rem;

  @media (min-width: $md) {
    font-size: 1.6875rem;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }
}

@mixin h4 {
  @include headingCommon;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;

  @media (min-width: $md) {
    font-size: 1.375rem;
  }
}

@mixin h5 {
  @include headingCommon;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;

  @media (min-width: $md) {
    font-size: 1.125rem;
  }
}

@mixin h6 {
  @include headingCommon;
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;

  @media (min-width: $md) {
    font-size: 0.875rem;
  }
}