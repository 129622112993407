.payments-block {
  margin-bottom: 6rem;

  &__download-buttons {
    display: flex;
    flex-direction: column;
    min-height: 5rem;

    & .linkbadge-root {
      width: 100%;
      margin-top: 0.625rem;
    }

    @media(min-width: $sm) {
      & .linkbadge-root {
        width: 17.5rem;
      }
    }

    @media(min-width: $md) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      & .linkbadge-root {
        margin-bottom: 0;
        margin-left: 0.625rem;
      }
    }
  }
}