.monthly-stats {
  position: relative;
  height: 100%;

  &__heading {
    font-size: 1.125rem;
    color: var(--monthlyStatsWidgetTextColor);
    margin-bottom: 0.375rem;
  }
  
  &__date {
    min-height: 0.0625rem;
    margin-bottom: 0.75rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: var(--monthlyStatsWidgetTextColor);

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__vis {
    position: relative;
    width: 100%;
    height: calc(100% - 3.75rem);

    @media(min-width: $lg) {
      display: flex;
      flex-direction: row-reverse;

      .monthly-stats-legend {
        flex: 1 1 40%;
        align-self: center;
      }

      .monthly-stats-donut {
        flex: 1 1 60%;
        align-self: center;
      }
    }
  }

  .monthly-stats-legend {
    text-align: center;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    @media(min-width: $lg) {
      text-align: left;
    }

    .pet, .can {
      position: relative;
      color: var(--monthlyStatsWidgetLegendTextColor);
      margin-left: 1.5625rem;
      margin-right: 0.625rem;
      margin-bottom: 0.9375rem;
      font-size: 0.75rem;
      font-weight: 700;
      display: inline-block;

      @media(min-width: $lg) {
        display: block;
      }

      &::before {
        content: ' ';
        position: absolute;
        left: -1.5625rem;
        top: -0.3125rem;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.1875rem;
      }
    }
  }

  .pet {
    &::before {
      background-color: var(--monthlyStatsPetColor);
    }
  }

  .can {
    &::before {
      background-color: var(--monthlyStatsCanColor);
    }
  }

  &__no-data-message {
    margin: 3rem auto;
    color: var(--colorTextDarkBg);
  }
}