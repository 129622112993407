.detail-form {
  position: relative;

  &__input {
    margin-bottom: 1.5rem;

    .input-toggle-button__option {
      width: 50%;
      height: 4.6875rem;

      img {
        margin-right: 0.3125rem;
      }
    }
  }

  &__preview {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__add-button {
    margin-top: 1rem;
  }

  &__error-messages {
    width: 100%;
    margin-top: 0.9375rem;

    & .danger {
      width: 100%;
      margin-top: 0;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }
}