.sales-statement-details-filter {
  --period-width: 13rem;

  margin-bottom: 1rem;
  color: var(--colorTextDarkBg);

  & .panel__content {
    padding: 1.25rem;
  }

  &__filter-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625rem;

    @media(min-width: $xxl) {
      flex-direction: row;
    }
  }

  &__filter-fields {
    .sales-statement-details-filter__field {
      display: block;
      width: 100%;
    }

    @media(min-width: $sm) {
      .sales-statement-details-filter__field {
        &:first-child {
          width: var(--period-width);
        }

        display: inline-block;

        &:not(:first-child) {
          width: var(--period-width)
        }
      ;
        margin-right: 0.625rem;
      }
    }

    @media(min-width: $lg) {
      .sales-statement-details-filter__field {
        &:not(:first-child) {
          margin-right: 0.625rem;
        }

        &:last-child {
          display: inline-block;
        }

      }
    }
    @media(min-width: $xl) {
      .sales-statement-details-filter__field {
        &:not(:first-child) {

          margin-right: 0.625rem;
        }

        &:last-child {
          display: inline-block;
        }

      }
    }
  }

  &__field {
    height: 3.5rem;
    margin-top: 0.225rem;
    @media(min-width: $sm) {
      margin-right: 0.625rem;
    }
  }

  &__buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;

    @media(min-width: $sm) {
      flex-direction: row;
      justify-content: space-between;
    }
    @media(min-width: $md) {
      padding: 0.625rem 0;
    }

    .link-badge__link-wrap {
      width: 100%;

      @media(min-width: $sm) {
        width: auto;
        margin-bottom: 0;
      }
      @media(min-width: $md) {
        width: 17.5rem;
        position: absolute;
        right: 0;
      }
    }
  }
}