.register-flow-step-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 62.5rem;

  &__bread-text {
    text-align: center;
  }

  &__fields {
    margin-top: 1.5rem;
    width: 100%;
    @media(min-width: $md) {
      width: 43.75rem;
    }
  }

  &__error-messages {
    width: 100%;
    margin: 0.625rem 0;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .next-button {
      margin-top: 0.625rem;
      margin-bottom: 0.9375rem;

      & button {
        @include button-style-lg;
        width: 17.1875rem;
      }
    }

    & .prev-button {
      margin-bottom: 1.875rem;

      & > span:first-of-type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .panicon {
          margin-right: 0.3125rem;
          margin-left: 0;
        }

        & > span:last-child {
          line-height: 0.75rem;
        }
      }
    }

    & .save-button {
      margin-bottom: 0.9375rem;

      & button {
        @include button-style-lg;
        width: 17.1875rem;
      }
    }

    & .cancel-button {
      button {
        width: 5.625rem;
      }

      & > span:first-of-type {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .panicon {
          margin-right: 0.3125rem;
          margin-left: 0;
        }

        & > span:last-child {
          line-height: 0.75rem;
        }
      }
    }
  }

  &__cancel-button-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
      margin-left: 0;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }

  &__prev-button-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
      margin-left: 0;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }
}