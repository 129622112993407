.all-customers-dropdown-container {
  width: 100%;
  
  &__customer-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;

    & .connect-customer-btn {
      margin-bottom: 0.625rem;
      width: 100%;
    }

    & .add-customer-btn {
      width: 100%;
    }

    & button {
      width: 100%;
    }

    label {
      margin-left: auto;
    }

    @media(min-width: $sm) {
      flex-direction: row;
      & .add-customer-btn {
        width: auto;
      }
      & .connect-customer-btn {
        margin-right: 0.625rem;
        margin-bottom: 0;
        width: auto;
      }
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 1.25rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }

  &__hidden-form-buttons {
    & .new-company-button {
      display: none;
    }
  }
}