.step-2 {
  max-width: 62.5rem;
  padding: 0 0.9375rem 0 0.9375rem;

  .panel {
    margin-top: 1.5rem;
  }

  .panel__content {
    & > p {
      margin-bottom: 0;
    }
  }

  @media(min-width: $md) {
    padding: 0;
  }

  &__form {
    width: 100%;
    @media(min-width: $md) {
      width: 43.75rem;
    }
    margin-left: auto;
    margin-right: auto;
    display: none;

    &.visible {
      display: block;
    }
  }

  &__top {
    text-align: center;
    margin-bottom: 3rem;
  }

  &__top-header {
    @include h3;
  }

  &__form-header {
    @include h5;
    margin-bottom: 1.25rem;

    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  &__input {
    margin-bottom: 1rem;
  }

  &__bottom {
    text-align: center;
    margin-top: 3rem;
  }

  &__preview-button {
    margin-bottom: 1rem;

    .button-wrapper {
      button {
        width: 17.1875rem;
        @include button-style-lg;
      }
    }
  }

  &__preview {
    display: none;
    width: 100%;
    @media(min-width: $xl) {
      width: 62.5rem;
    }
    margin-left: auto;
    margin-right: auto;

    &.visible {
      display: block;
    }
  }

  &__preview-header {
    @include h3;
    margin-bottom: 0.5rem;
  }

  &__error-messages {
    width: 100%;
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    text-align: left;

    & .danger {
      width: 100%;
      margin-top: 0;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__previous-button {
    .button-wrapper {
      .panicon {
        margin-right: 1.25rem;
        margin-left: -0.3125rem;
      }
    }
  }

  &__order-summary-header {
    @include h4;
    margin-top: 2rem;
  }

  &__edit-button {
    text-align: right;

    .button-wrapper {
      button {
        min-width: 6.875rem;
      }
    }
  }

  &__terms {
    p {
      margin: 0 !important;
    }
  }

  &__submit-button {
    text-align: center;
    margin-top: 4rem;

    button {
      min-width: 17.1875rem;
      @include button-style-lg;
    }
  }
}