.tooltip {
  &__wrapper {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  &__trigger-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--backgroundTooltip);
    border-radius: 1rem;
    width: 15rem;
    padding: 1.875rem;
    border: 0.0625rem solid var(--borderTooltip);
    transition: opacity 0.4s ease-out, visibility 0.2s ease-in;

    &.top {
      margin-top: -0.625rem;
      left: 50%;
      transform: translate(-50%, -100%);
    }

    &.right {
      left: 100%;
      margin-left: 0.625rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &.bottom {
      top: 100%;
      margin-top: 0.625rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &.left {
      margin-left: -0.625rem;
      transform: translate(-100%, -50%);
      top: 50%;
    }

    &.on {
      height: auto;
      opacity: 1;
      z-index: 3;
    }

    &.off {
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__arrow {
    position: absolute;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 0.625rem solid transparent;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 0.5625rem solid transparent;
    }

    &.top {
      bottom: 0;
      left: calc(50% - 0.625rem);

      &:before {
        left: 0;
        top: 100%;
        border-top-color: var(--borderTooltip);
      }

      &:after {
        left: 0.0625rem;
        top: 100%;
        border-top-color: var(--backgroundTooltip);
      }
    }

    &.right {
      left: 0;
      top: calc(50% - 0.625rem);

      &:before {
        top: 0;
        right: 100%;
        border-right-color: var(--borderTooltip);
      }

      &:after {
        top: 0.0625rem;
        right: 100%;
        border-right-color: var(--backgroundTooltip);
      }
    }

    &.bottom {
      top: 0;
      left: calc(50% - 0.625rem);

      &:before {
        left: 0;
        bottom: 100%;
        border-bottom-color: var(--borderTooltip);
      }

      &:after {
        left: 0.0625rem;
        bottom: 100%;
        border-bottom-color: var(--backgroundTooltip);
      }
    }

    &.left {
      right: 0;
      top: calc(50% - 0.625rem);

      &:before {
        top: 0;
        left: 100%;
        border-left-color: var(--borderTooltip);
      }

      &:after {
        top: 0.0625rem;
        left: 100%;
        border-left-color: var(--backgroundTooltip);
      }
    }
  }
}