.sales-statements {
  position: relative;
  padding-bottom: 6rem;
  padding-right: 1rem;
  padding-left: 1rem;

  &__sales-statement {
    margin-bottom: 0.9375rem;

    .panel__content {
      background-color: var(--backgroundColorSalesStatementsPanel);
    }

    .expandable-panel__inner-content {
      overflow: hidden;
    }
  }
}