.report-flow {
  position: relative;
  margin-left: 1.25rem;
  margin-right: 1.25rem;

  @media(min-width: $xxl) {
    margin-left: 8rem;
    margin-right: 8rem;
    transition: margin-left 0.15s ease 0.1s, margin-right 0.15s ease 0.1s;

    .open + & {
      margin-left: 33.75rem;
      margin-right: 1.25rem;
    }
  }

  &__cancel-modal-content {
    text-align: center;
    display: flex;
    flex-direction: column;

    & > p {
      font-size: 1.125rem;
    }

    & button {
      @include button-style-lg;
    }
  }

  &__modal-button {
    margin-top: 0.9375rem;
  }
}