.equipment-order-step-1 {
  max-width: 62.5rem;
  padding: 0 0.9375rem 0 0.9375rem;

  &__form {
    width: 100%;

    @media(min-width: $md) {
      width: 43.75rem;
    }

    margin-left: auto;
    margin-right: auto;

    & > h3 {
      text-align: center;
    }
  }

  &__input {
    margin-bottom: 1rem;
  }

  &__not-available {
    padding: 0.9375rem;
    text-align: center;
    border-radius: 0.3125rem;
    font-size: 0.875rem;
    background-color: var(--equipmentNotAvailableBgColor);
  }

  &__submit {
    text-align: center;
    margin-top: 4rem;

    button {
      @include button-style-lg;
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 1.25rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}