.reports-search-and-filter {
  margin-bottom: 1.25rem;

  & .panel__content {
    padding: 1.25rem;
  }

  &__panel-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--colorTextDarkBg);
  }

  &__show-older {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.25rem;
    font-size: 0.75rem;
  }
}