.reporting-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  color: var(--colorTextDarkBg);

  &__sales-period {
    font-size: 1.125rem;
    font-weight: var(--font-weight-heavy);
    margin-bottom: 0.625rem;
    @media(min-width: $md) {
      margin-bottom: 0;
    }
  }

  &__sales-period-text {
    display: flex;
    margin-bottom: 0.625rem;
    @media(min-width: $md) {
      display: inline-flex;
      margin-right: 0.625rem;
    }
  }

  &__report-date {
    margin-right: 0.625rem;
  }

  &__reporter {
    margin-left: 0.625rem;
  }
}