.table-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  
  &__thead {    
    &--sticky {
      position: sticky;
      top: 0;
      
      &.sm {
        @media (max-width: $sm) {
          position: static;
        }
      }

      &.md {
        @media (max-width: $md) {
          position: static;
        }
      }

      &.lg {
        @media (max-width: $lg) {
          position: static;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    caption {
      @include h3;
      display: table-caption;
      text-align: left;
    }

    thead {
      color: var(--white);
      font-size: 0.875rem;
      box-shadow: 0 0 0 0.0625rem var(--backgroundTableHeader);
      border-top-left-radius: 0.9375rem;
      border-top-right-radius: 0.9375rem;

      & span {
        font-weight: var(--font-weight-super-heavy);
      }
    }

    tr:first-child {
      & th {
        &:first-child,
        &.first-column {
          border-top-left-radius: 0.9375rem;
        }

        &:last-child,
        &.last-column {
          border-top-right-radius: 0.9375rem;
        }
      }
    }

    th {
      vertical-align: bottom;
      padding-bottom: 0.625rem;
      transition: background-color 0.4s ease;
      background-color: var(--backgroundTableHeader);

      &.sortable {
        cursor: pointer;

        &:hover {
          background-color: var(--backgroundTableHeaderHover);
        }
      }

      &:first-child,
      &.first-column {
        padding-left: 2.5rem;

        &:not(.expandable) {
          padding-left: 1.25rem;
        }
      }

      .header-text {
        display: inline-flex;
        align-items: center;
      }

      & .panicon {
        margin-left: 0.3125rem;
        font-size: 0.75rem;
      }

      &.sorted .panicon {
        color: var(--colorTableHeaderIcon);
      }

      &:not(.sorted) .panicon {
        color: transparent;
      }

      @media (min-width: $xs) {
        padding-right: 0.625rem;
        padding-left: 0.625rem;
        &:not(.visible-on-mobile) {
          display: none;
        }
      }

      &.sm {
        @media (min-width: $sm) {
          @include table-head-mobile-breakpoint;
        }
      }

      &.md {
        @media (min-width: $md) {
          @include table-head-mobile-breakpoint;
        }
      }

      &.lg {
        @media (min-width: $lg) {
          @include table-head-mobile-breakpoint;
        }
      }
    }

    tbody {
      border-bottom-left-radius: 0.9375rem;
      border-bottom-right-radius: 0.9375rem;
      box-shadow: 0 0 0 0.0625rem var(--borderTableBody);

      & tr {
        & td {
          background-color: var(--backgroundTableRowOdd);
        }

        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.125rem;

        &:not(:first-child):not(.mobile-expand-row) {
          border-top: 0.0625rem solid var(--borderTableBody);
        }

        &:nth-child(4n-1),
        &:nth-child(4n) {
          & td {
            background-color: var(--backgroundTableRowEven);
          }
        }

        &.table-footer-row,
        &.table-nodata-row.last-row,
        &.last-row.mobile-expand-row.expanded {
          & td {
            border-bottom-left-radius: 0.9375rem;
            border-bottom-right-radius: 0.9375rem;
          }
        }

        &.table-nodata-row {
          & td {
            padding-left: 1.25rem;
          }
        }

        &.mobile-expand-row td {
          padding: 0;
        }

        &.last-row:not(.expanded) {
          border-bottom-left-radius: 0.9375rem;
          border-bottom-right-radius: 0.9375rem;
          transition: border-radius 0.01s linear 0.3s;

          & .first-column {
            border-bottom-left-radius: 0.9375rem;
          }

          & .last-column {
            border-bottom-right-radius: 0.9375rem;
          }
        }

        &.last-row.expanded {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          & .first-column {
            border-bottom-left-radius: 0;
          }

          & .last-column {
            border-bottom-right-radius: 0;
          }
        }

        &.sm {
          @media (min-width: $sm) {
            @include table-body-mobile-breakpoint;
          }
        }

        &.md {
          @media (min-width: $md) {
            @include table-body-mobile-breakpoint;
          }
        }

        &.lg {
          @media (min-width: $lg) {
            @include table-body-mobile-breakpoint;
          }
        }
      }
    }
  }
}

.full-screen.open {
  .table-wrapper .table-wrapper__thead--sticky {
    top: -3.75rem;
  }
}