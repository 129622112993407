.question-bubble {
  position: relative;
  font-size: 1.3rem;
  font-weight: var(--font-weight-heavy);
  font-family: var(--font-family-base);
  color: var(--colorTextSecondary);

  @media (min-width: $md) {
    font-size: 1.75rem;
  }

  &.sm {
    font-size: 1.75rem;
  }

  &::after {
    position: absolute;
    content: '?';
    top: 0.625rem;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.lg {
    @media (min-width: $md) {
      font-size: 2.5rem;
    }

    &::after {
      top: 0.3125rem;
      left: 0.625rem;

      @media (min-width: $md) {
        top: 0.75rem;
        left: 1.25rem;
      }
    }

    svg {
      width: 1.8956rem;
      height: 1.8886rem;

      @media (min-width: $md) {
        width: 3.7912rem;
        height: 4.2146rem;
      }
    }
  }
}