.search-modal {
  position: absolute;
  top: 3.75rem;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 3.75rem);
  padding: 1.875rem;
  background-color: var(--white);
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
  z-index: 2;
  transition: opacity 0.3s, transform 0.5s, visibility 0.3s;
  overflow: auto;
  visibility: visible;
  
  &.hide {
    opacity: 0;
    transform: translateY(-3.75rem);
    pointer-events: none;
    visibility:hidden;
  }
  
  &__search-container {
    max-width: 43.75rem;
    margin-left: auto;
    margin-right: auto;
  }
}