@mixin tooltipDimensions($innerDotWidth, $tooltipWidth, $tooltipWidthCompressed) {
  &.more-than-four {
    width: #{$tooltipWidthCompressed}rem;
    left: #{calc(($innerDotWidth / 2) - calc($tooltipWidthCompressed / 2))}rem;
  }

  &.four-or-less {
    width: #{$tooltipWidth}rem;
    left: #{calc(($innerDotWidth / 2) - calc($tooltipWidth / 2))}rem;
  }
}

.timeline {
  /* StepIndicator changes */
  $InnerDotWidth: 1.1875;
  $OuterDotWidth: 1.9375;
  $LeftPadding: #{calc(($OuterDotWidth - $InnerDotWidth) / 2)}rem;
  $LeftMargin: 6;

  /* Timeline constants */
  $TooltipWidth: 12.5;
  $TooltipWidthCompressed: 8;
  $TooltipWidthSmToLg: 16;
  $TooltipMobileMaxHeight: 5.625;
  $TopCurveRadius: 1.0625;
  $ArrowSize: 0.625;
  $MaxNrOfLines: 3;
  $LineHeight: 1.5;

  padding-top: 1.875rem;
  width: 100%;
  @media(min-width: $lg) {
    padding-top: 6.875rem;
  }

  &__step-indicator {
    margin: 0 0.9375rem 0 0;

    & .timeline-step-indicator {
      position: relative;
      flex-direction: column;
      width: $OuterDotWidth * 1rem;
      height: auto;
      padding-top: $LeftPadding;
      padding-left: 0;

      & .step-separator {
        height: 6.25rem;
        width: 0.0625rem;
      }

      & .inner-dot, & .step-dot {
        width: $InnerDotWidth * 1rem;
        height: $InnerDotWidth * 1rem;
      }

      & .step-indicator__outer-dot {
        width: $OuterDotWidth * 1rem;
        height: $OuterDotWidth * 1rem;
        left: 0;
        top: var(--timelineOuterDotTop);
      }
    }

    @media(min-width: $lg) {
      margin: 0.9375rem 0 0 0;

      & .timeline-step-indicator {
        flex-direction: row;
        width: calc(100% - $LeftMargin * 2rem);
        padding-top: 0;
        margin-left: $LeftMargin * 1rem;
        margin-right: $LeftMargin * 1rem;
        height: $OuterDotWidth * 1rem;

        & .step-separator {
          width: var(--separatorAutoWidth);
          height: 0.0625rem;
        }

        & .step-indicator__outer-dot {
          top: 0;
          left: var(--timelineOuterDotLeft);
        }
      }
    }
  }

  & .timeline-icon-descriptions {
    display: flex;
    flex-direction: row;
    margin-top: 2.5rem;
    flex-wrap: wrap;
    @media(min-width: $lg) {
      margin-top: 1.25rem;
    }

    &__description {
      font-size: 0.875rem;
      font-weight: var(--font-weight-heavy);
      color: var(--colorTextSecondary);
      margin-right: 1.25rem;
      margin-bottom: 0.3125rem;

      & .panicon {
        font-size: 0.875rem;
      }
    }
  }

  .step-tooltip {
    position: absolute;
    width: $TooltipWidth * 1rem;
    left: $OuterDotWidth * 1rem;
    margin-left: 0.625rem;
    height: $TooltipMobileMaxHeight * 1rem;
    top: calc((#{$InnerDotWidth} / 2 - #{$TooltipMobileMaxHeight} / 2) * 1rem);

    & .step-tooltip__tooltip {
      top: 50%;
      transform: translateY(-50%);
    }

    &.active {
      & .step-tooltip__tooltip {
        color: var(--colorTooltipActive);
        background-color: var(--backgroundTooltipActive);
      }

      & .top-curve {
        color: var(--backgroundTooltipActive);
        background-color: var(--backgroundTooltipActive);
      }

      & .bottom-arrow {
        border-right-color: var(--backgroundTooltipActive);
      }
    }

    &.in-range {
      & .step-tooltip__tooltip {
        color: var(--colorTooltipInRange);
        background-color: var(--backgroundTooltipInRange);
      }

      & .top-curve {
        background-color: var(--backgroundTooltipInRange);
      }

      & .bottom-arrow {
        border-right-color: var(--backgroundTooltipInRange);
      }
    }

    &.future {
      & .step-tooltip__tooltip {
        color: var(--colorTooltipFuture);
        background-color: var(--backgroundTooltipFuture);
      }

      & .top-curve {
        background-color: var(--backgroundTooltipFuture);
      }

      & .bottom-arrow {
        border-right-color: var(--backgroundTooltipFuture);
      }
    }

    @media(min-width: $sm) {
      width: $TooltipWidthSmToLg * 1rem;
    }
    @media(min-width: $lg) {
      margin-bottom: 1.25rem;
      margin-left: 0;
      bottom: 0;
      @include tooltipDimensions($InnerDotWidth, $TooltipWidth, $TooltipWidthCompressed);
      height: auto;
      top: auto;
      & .step-tooltip__tooltip {
        top: 0;
        transform: none;
      }
      &.active {
        & .bottom-arrow {
          border-right-color: transparent;
          border-top-color: var(--backgroundTooltipActive);
        }
      }
      &.in-range {
        & .bottom-arrow {
          border-right-color: transparent;
          border-top-color: var(--backgroundTooltipInRange);
        }
      }
      &.future {
        & .bottom-arrow {
          border-right-color: transparent;
          border-top-color: var(--backgroundTooltipFuture);
        }
      }
    }
    @media(min-width: $xl) {
      &.more-than-four, &.four-or-less {
        width: $TooltipWidth * 1rem;
        left: calc((#{$InnerDotWidth} / 2 - #{$TooltipWidth} / 2) * 1rem);
      }
    }

    &__tooltip {
      position: relative;
      border-radius: 0.3125rem;
      background-color: var(--backgroundTooltipDefault);
      color: var(--colorTooltipDefault);
      font-size: 0.875rem;
      font-weight: var(--font-weight-heavy);
      padding: 0.9375rem 1.5rem;
      text-align: center;
      line-height: 1.5;

      .top-curve {
        position: absolute;
        border-top-left-radius: #{($TopCurveRadius) + rem};
        border-top-right-radius: #{($TopCurveRadius) + rem};
        width: #{($TopCurveRadius * 2)}rem;
        height: calc((#{$TopCurveRadius} + 0.0625) * 1rem);
        top: -#{($TopCurveRadius)}rem;
        padding-top: 0.3125rem;
        padding-left: 0.3125rem;
        left: calc(50% - #{$TopCurveRadius} * 1rem);
      }

      .bottom-arrow {
        position: absolute;
        bottom: calc(50% - #{$ArrowSize} * 1rem);
        left: -#{($ArrowSize)}rem;
        width: 0;
        height: 0;
        border-top: #{$ArrowSize}rem solid transparent;
        border-bottom: #{$ArrowSize}rem solid transparent;
        border-right: #{$ArrowSize}rem solid var(--backgroundTooltipDefault);

        @media(min-width: $lg) {
          bottom: -#{$ArrowSize}rem;
          left: calc(50% - #{$ArrowSize}rem);
          border-left: #{$ArrowSize}rem solid transparent;
          border-right: #{$ArrowSize}rem solid transparent;
          border-top: #{$ArrowSize}rem solid var(--backgroundTooltipDefault);
          border-bottom: none;
        }
      }
    }

    &__tooltip-text {
      display: block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: $MaxNrOfLines;
      -webkit-box-orient: vertical;
      max-height: calc(#{$LineHeight} * #{$MaxNrOfLines}) rem;
    }

    &__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      background-color: var(--backgroundTooltipIcon);
    }
  }
}