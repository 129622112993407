:root {
  --chartDataHeight: 21.25rem;
  --yAxisLabelFontSizeXs: 0.5rem;
  --yAxisLabelFontSizeSm: 0.625rem;
  --yAxisLabelFontSizeMd: 0.875rem;
}

.bar-chart {
  @media(min-width: $xl) {
    margin-right: 3.125rem;
  }
}

.chart-wrapper {
  display: flex;
  flex-direction: row;

  &__y-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 0.3125rem;
    height: calc(var(--chartDataHeight) + var(--yAxisLabelFontSizeXs));
    margin-top: calc((var(--yAxisLabelFontSizeXs) / 2) - var(--yAxisLabelFontSizeXs));

    @media (min-width: $sm) {
      height: calc(var(--chartDataHeight) + var(--yAxisLabelFontSizeSm));
      margin-top: calc(
              (var(--yAxisLabelFontSizeSm) / 2) - var(--yAxisLabelFontSizeSm)
      );
    }

    @media (min-width: $md) {
      margin-right: 0.625rem;
      height: calc(var(--chartDataHeight) + var(--yAxisLabelFontSizeMd));
      margin-top: calc(
              (var(--yAxisLabelFontSizeMd) / 2) - var(--yAxisLabelFontSizeMd)
      );
    }

    .y-label {
      font-weight: var(--font-weight-heavy);
      color: var(--colorHeadings);
      font-size: var(--yAxisLabelFontSizeXs);

      @media (min-width: $sm) {
        font-size: var(--yAxisLabelFontSizeSm);
      }

      @media (min-width: $md) {
        font-size: var(--yAxisLabelFontSizeMd);
      }
    }
  }

  .x-label {
    position: absolute;
    bottom: -0.9375rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: var(--font-weight-heavy);
    color: var(--colorHeadings);
    font-size: var(--yAxisLabelFontSizeXs);

    @media (min-width: $sm) {
      font-size: var(--yAxisLabelFontSizeSm);
    }

    @media (min-width: $md) {
      bottom: -1.25rem;
      font-size: var(--yAxisLabelFontSizeMd);
    }
  }

  &__data {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    height: var(--chartDataHeight);
    margin-right: 0.9375rem;

    @media (min-width: $sm) {
      margin-right: 0;
    }
  }

  &__horizontal-limit {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 0.0625rem;
    background-color: var(--borderColorBase);
  }

  &__bar-group-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}
