.material-specification-item {
  margin-bottom: 1.25rem;
  color: var(--colorTextDarkBg);

  & h5 {
    margin-top: 0;
  }

  &__top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .file-upload {
      display: flex;
      flex-direction: column;
      align-items: center;

      & button {
        width: 8.75rem;
      }

      @media(min-width: $sm) {
        flex-direction: row;
      }

      &__filename {
        @include small;
        display: flex;
        align-items: center;
        font-style: italic;
        margin-bottom: 0.3125rem;

        &.file-selected {
          font-style: normal;
          text-align: right;
          padding-left: 0.9375rem;

          & .remove-icon {
            display: inline-block;
          }
        }

        @media(min-width: $sm) {
          margin-bottom: 0;
          margin-right: 0.625rem;
        }

        .remove-icon {
          color: var(--backgroundButtonSecondary);
          margin: auto 0.3125rem auto 0;
          cursor: pointer;
          display: none;

          &:hover {
            color: var(--backgroundButtonSecondaryHover);
          }

          & .panicon {
            font-size: 1.25rem;
          }

          @media(min-width: $md) {
            & .panicon {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  &__info-text-panel {
    margin: 0.625rem 0;

    & .info-text-panel {
      font-size: 0.75rem;
      line-height: 1.5;
      padding: 0.9375rem;
      border-radius: 0.3125rem;
    }
  }
}