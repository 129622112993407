@import '../../../../../../Frontend/scss/layout-variables';
@import '../../../../../../Frontend/scss/variables';
@import '../../../../../../Frontend/scss/mixins/layout-mixins';
@import '../../../../../../Frontend/scss/mixins/heading-mixins';

.nutrition-declaration {
  & .nutrition-declaration-info-panel {
    font-size: 0.75rem;
    line-height: 1.5;
    padding: 0.9375rem;
    border-radius: 0.3125rem;
    color: var(--colorTextDarkBg);
  }

  &__table {
    margin-top: 0.9375rem;

    & td.nutrition {
      font-weight: var(--font-weight-heavy);
    }
  }

  &__table-footer {
    padding: 0.9375rem;
  }

  &__nutrition-field {
    display: flex;
    flex-direction: row;

    .remove-icon {
      color: var(--backgroundButtonSecondary);
      margin: auto 0.625rem auto 0;
      cursor: pointer;

      &:hover {
        color: var(--backgroundButtonSecondaryHover);
      }
    }
  }
}