.main-menu-page {
  @include page;

  &__container {
    @include block;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__main-body {
    display: flex;
    flex-direction: column;
    align-self: baseline;

    @media (min-width: $lg) {
      max-width: var(--max-full-width-text);
    }
  }

  .links-container {
    --gap: 2rem;

    .content-area {
      gap: var(--gap);
    }

    .content-area__item {
      @media (min-width: $lg) {
        flex: 1 1 calc(50% - (var(--gap) / 2));
        max-width: calc(50% - (var(--gap) / 2));
      }

      @media (min-width: $xl) {
        flex: 1 1 calc(33.3333% - (2 * var(--gap) / 3));
        max-width: calc(33.3333% - (2 * var(--gap) / 3));
      }
    }
  }

  &__main-content-area {
    width: 100%;
    
    &.FullWidth {
      width: calc(100vw - 15px); //remove the scrollbar width
    }

    .content-area .content-area__item:last-of-type {
      margin-bottom: 2rem;
    }
  }
}