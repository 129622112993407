.add-customer-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 0 3.125rem 0;

  & h3,
  & p {
    text-align: center;
  }

  & button {
    @include button-style-lg;
    width: 11.25rem;
  }

  &__input-fields {
    width: 100%;
    margin-bottom: 2.1875rem;

    @media(min-width: $sm) {
      width: 31.25rem;
    }

    & .customernr-orgnr-input{
      margin-top: 1.25rem;
      margin-bottom: 0.625rem;
    }
  }

  &__error-messages {
    width: 100%;
    margin-bottom: 1.25rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }
}