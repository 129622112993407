.balance-row {
  display: contents;

  &__row {
    position: relative;
    page-break-inside: avoid;
    @include small;
    vertical-align: middle;

      &.data,
      &.subtotal {
      background-color: var(--balanceSheetDataRowBgColor);
      border-radius: 0.3125rem;
      height: 2.5rem;
    }

    &.total {
      position: relative;
      height: 3.75rem;
      background-color: var(--balanceSheetTotalRowBgColor);
      box-shadow: 0 0 0 0.0625rem var(--balanceSheetTotalBorderColor);
      border-radius: 0.3125rem;
    }
  }

  &__col {
    padding: 0.3125rem 1.25rem;
    min-width: 9.375rem;

    &:not(:first-child) {
      text-align: right;
    }

    &.data,
    &.subtotal {
      &:first-child {
        border-top-left-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
      }

      &:last-child {
        background-color: var(--balanceSheetDataRowLastColBgColor);
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
      }
    }

    &.subtotal,
    &.total {
      &:first-child {
        font-weight: var(--font-weight-heavy);
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0.3125rem;
      }

      &:last-child {
        font-weight: var(--font-weight-heavy);
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
      }
    }
  }

  &__sub-row {
    height: 1.875rem;
    font-size: 0.625rem;
    page-break-inside: avoid;

    @media (min-width: $md) {
      font-size: 0.75rem;
    }
  }

  &__sub-col {
    padding: 0.3125rem 1.25rem;
    min-width: 9.375rem;
    &:first-child {
      padding: 0.3125rem 1.25rem 0.3125rem 1.875rem;
    }
    &:not(:first-child) {
      text-align: right;
    }
  }

  &__spacer {
    height: 0.625rem;
    page-break-inside: avoid;
  }
}