.contact-form-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    @include button-style-lg;
  }

  .fields-wrapper {
    max-width: 31.25rem;
  }

  &__info-text {
    text-align: center;
  }

  &__error {
    @include formErrorMessages;
    margin-bottom: 1.25rem;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    margin-bottom: 1.875rem;

    & h5 {
      margin-bottom: 1.25rem;
    }

    &__items {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: $sm) {
        flex-direction: row;
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 0.9375rem;
      }

      & a {
        font-size: 1rem;
      }

      & p {
        margin: 0;
      }

      & > span {
        margin-left: 1rem;
      }

      @media (min-width: $sm) {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 1.25rem;
        }
      }

      @media (min-width: $md) {
        & .inline-link {
          font-size: 1.125rem;
        }
      }
    }

    &__icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 2.25rem;
      height: 2.25rem;
      min-width: 2.25rem;
      color: var(--colorTextSecondary);
      background-color: var(--secondary);
      border-radius: 50%;
    }
  }
}