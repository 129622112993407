.flyers {
  padding-bottom: 3.125rem;

  &__title {
    @include h1;
  }

  &__container {
    text-align: center;
    padding-bottom: 1.875rem;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}