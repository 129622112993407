.header-top {
  height: 5rem;
  background-color: var(--backgroundColorHeadingTop);
  position: relative;
  display: none;

  @media(min-width: $xl) {
    display: block;
  }

  .rightNav {
    @media(min-width: $xs) {
      display: none;
    }
    @media(min-width: $md) {
      display: block;
    }
  }

  &__logo {
    padding: 1rem 0;
    height: 3rem;
    max-width: 5rem;
    margin: 0 auto;

    img {
      height: 3rem;
      width: 5rem;
    }

    a {
      display: block;
      height: 3rem;
    }
  }

  &__nav {
    @media(min-width: $xs) {
      display: none;
    }
    @media(min-width: $md) {
      display: block;
    }

    &:not(.bottom) {
      position: absolute;
      top: 1.25rem;

      @media(min-width: $xs) {
        right: 5.5rem;
      }

      @media(min-width: $md) {
        right: 4.0625rem;
      }
    }

    &.header-bottom__nav-right {
      visibility: visible;
      transition: opacity 0.2s ease-in-out;
      pointer-events: auto;

      @media(min-width: $xl) {
        visibility: hidden;
        display: block;
        pointer-events: none;
        opacity: 0;
      }
    }

    &.bottom {
      display: block;
      margin-left: auto;

      ul {
        text-align: center;
      }

      @media(min-width: $xl) {
        margin-left: 0;
        transform: translateX(100%);
        animation: slideOutRight 2s normal backwards;
      }
    }
  }

  &__navigation {
    width: 100%;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      white-space: nowrap;

      li {
        display: inline-block;
        vertical-align: bottom;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        @media(min-width: $sm) {
          padding: 0 0.75rem;
        }

        @media(min-width: $xl) {
          padding: 0 1.5rem;
        }

        &.header-top--link {
          button,
          span,
          a {
            transition: color 0.2s ease-in-out;
          }

          &:hover {
            button,
            span,
            a {
              color: var(--headerTopLinkHover);
            }
          }
        }
      }

      a,
      button {
        background-color: transparent;
        display: block;
        font-size: 0.875rem;
        font-weight: var(--font-weight-heavy);
        text-decoration: none;
        line-height: 1.6;
        transition: color 0.3s linear;
        border: none;
        padding: 0;
        margin: 0;
        color: var(--colorMenuTopIcon);
        cursor: pointer;

        span.panicon {
          font-size: 1rem;
          color: var(--colorMenuTopIcon);
          line-height: 1.2;
          display: block;
          &.icon-check {
            font-size: 12px;
          }
        }

        span:not(.panicon) {
          display: none;

          @media(min-width: $xl) {
            display: inline-block;
            font-size: 0.875rem;
          }
        }
      }
    }

    &.bottom ul {
      li {
        text-align: center;
        padding: 0 0.3rem;

        @media(min-width: $xs) {
          padding: 0 0.625rem;
        }

        span.panicon {
          @media(min-width: $md) {
            color: var(--colorMenuTopIcon);
            font-size: 1.125rem;
            font-weight: var(--font-weight-medium);
          }
        }

        span:not(.panicon) {
          display: none;

          @media(min-width: $md) {
            color: var(--colorMenuTopIcon);
            font-size: 0.625rem;
            font-weight: var(--font-weight-medium);
          }
        }

        &.header-top--link {
          button,
          span,
          a {
            transition: color 0.2s ease-in-out;
          }

          &:hover {
            button,
            span,
            a {
              color: var(--headerTopLinkHover);
            }
          }
        }
      }
    }

    span {
      display: inline-block;
      max-width: 9.375rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }    
  }  
}