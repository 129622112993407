.filter {
  margin-bottom: 1.875rem;
  padding: 1.25rem 1.25rem 0.625rem 1.25rem;
  background-color: var(--faqFilterBgColor);
  border-radius: 0.9375rem;

  &__search {
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;

    @media (min-width: $md) {
      display: inline-block;
    }

    /* IE specific fix */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
    }
  }

  &__toggle-button {
    display: inline-block;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--faqFilterBorderColor);
    color: var(--faqToggleButtonSelectedTextColor);
    background-color: var(--faqToggleButtonSelectedBgColor);
    cursor: pointer;
    font-weight: 700;
    font-size: 0.75rem;

    &--selected {
      color: var(--faqToggleButtonTextColor);
      background-color: var(--faqToggleButtonBgColor);
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}