.order-store-flyers-modal-content {
  text-align: center;

  &__input-container {
    text-align: left;
    max-width: 31.25rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__button-container {
    margin-top: 1.875rem;

    button {
      @include button-style-lg;
      min-width: 14.375rem;
    }
  }

  &__error-message {
    display: inline-block;
    width: 100%;
    margin-top: 1.25rem;
    text-align: initial;

    & .danger {
      width: 100%;
    }

    @media (min-width: $md) {
      width: 31.25rem;
    }
  }
}