﻿.custom-date-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.2s ease-in;

  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    font-weight: var(--font-weight-medium);
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    width: 15rem;

    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border-radius: 0.4375rem;
    }

    &:focus-within {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineFocusTextInput);
      }
    }

    &.error {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineErrorTextInput);
      }
    }

    @media (min-width: $sm) {
        width: 23.75rem;
    }
  }
  
  input {
    position: relative;
    display: inline-block;
    height: 3.125rem;
    flex: 1;
    min-width: 6.25rem;
    font-family: var(--font-family-base);
    font-size: 0.875rem;
    outline: none;
    color: var(--colorTextInput);
    background: var(--backgroundTextInput);
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    border: 0.0625rem solid var(--borderTextInput);
    transition: all ease-in-out 0.1s;
    padding: 0 0.9375rem;
  }

  &__date-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundPrimary);
    width: 3.125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    transition: all ease-in-out 0.1s;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: var(--backgroundDatePickerButtonHover);
    }
  }
}