.connect-customer-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    @include button-style-lg;
  }

  &__header {
    text-align: center;
  }

  &__footer {
    text-align: center;
  }

  &__links {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1.25rem 0 1.875rem 0;

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__link-item {
    height: 4.375rem;
  }
}