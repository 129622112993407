.store-flyers {
  background-color: var(--backgroundStoreFlyersBlock);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  @media (min-width: $lg) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media (min-width: $xl) {
    margin-left: calc(50% - 43.75rem);
    margin-right: calc(50% - 43.75rem);
    padding-left: 10rem;
    padding-right: 10rem;
  }

  #storeFlyerRow {
    justify-content: space-between;
    margin-bottom: 1.8125rem;
    margin-top: 1.4rem;
  }

  &__wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding-top: 2rem;

    @media (min-width: $sm) {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__title-container {
    @include h2;
    font-family: var(--font-family-pantamera);
    color: var(--colorStoreFlyersHeading);
  }

  &__text-container {
    color: var(--colorTextDarkBg);
    margin-left: -15px;
    margin-right: -15px;
  }

  .flyer-container {
    text-align: center;
    padding-bottom: 1.875rem;
    position: relative;

    img {
      width: 100%;
    }

    .storeFlyerVideo {
      position: absolute;
      left: 0;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--colorTextDarkBg);
      margin-bottom: 0.625rem;
      font-style: italic;
      font-size: var(--fontSize18);

      @media (min-width: $lg) {
        min-height: 5rem;
      }

      h4 {
        color: var(--colorStoreFlyersHeading);
        font-style: normal;
      }
    }

    &__button-container {
      margin-top: 1.425rem;

      .button-wrapper {
        button {
          font-size: 1rem;
          padding: 0.9375rem 2.5rem;
          width: 17.1875rem;

          @media (min-width: $md) {
            width: 11.1875rem;
            font-size: 0.75rem;
          }

          @media (min-width: $xl) {
            font-size: 1rem;
            padding: 0.9375rem 2.5rem;
            width: 17.1875rem;
          }
        }
      }
    }
  }
}