.hero-scroll {
  position: relative;
  height: 100%;
  width: 100%;

  &__content {
    position: relative;
    height: 100%;
    width: 100%;
    transform: translateY(0px);
    will-change: transform;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--primary);
    opacity: 0;
    transition: opacity 2s;
    pointer-events: none;
    z-index: 1;
  }
}