@import './_layout-variables.scss';

.input-search {
  position: relative;
  width: 100%;

  & .panicon {
    position: absolute;
    top: 0.8125rem;
    left: 0.9375rem;
    color: var(--primary);
    font-size: 14px;
    min-width: 14px;
  }

  & input {
    padding: 0 0.9375rem 0 2.1875rem !important;
  }

  @media (min-width: $sm) {
    width: 16.25rem;
  }
}