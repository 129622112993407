.sales-statement-info {
  .panel__content {
    padding: 0;
  }

  &__details {
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;

    @media(min-width: $xl) {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.mobile-padding-top {
      padding-top: 1.875rem;
      @media(min-width: $xl) {
        padding-top: 0;
      }
    }

    &.dashes {
      position: relative;
      padding-bottom: 1.875rem;
      @media(min-width: $xl) {
        padding-bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        top: auto;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 0.0625rem;
        background-image: linear-gradient(
                        to right,
                        var(--primary) 50%,
                        transparent 50%
        );
        background-size: 1.875rem 100%;
        @media(min-width: $xl) {
          width: 0.0625rem;
          height: 100%;
          right: -15px;
          background-image: linear-gradient(
                          to bottom,
                          var(--primary) 50%,
                          transparent 50%
          );
          background-size: 100% 1.875rem;
        }
      }
    }
  }

  &__details-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__details-column {
    flex: 1;

    &:first-letter {
      text-transform: uppercase;
    }

    &.heading {
      font-weight: var(--font-weight-heavy);
      margin-bottom: 1rem;
      
      @media(min-width: $xl) {
        min-height: 3rem;
      }
      @media(min-width: $xxl) {
        min-height: 2rem;
      }
    }

    &.alignRight {
      text-align: right;
      justify-content: right;
    }

    &.desktopOnly {
      display: none;
      @media(min-width: $sm) {
        display: block;
      }
    }

    &.hide-xl-up {
      @media(min-width: $xl) {
        display: none;
      }
    }
    
    &.wide {
      @media(min-width: $xl) {
        flex: 6% 1 0;
      }      
    }

    &.mobileHeading {
      font-weight: var(--font-weight-heavy);
    }
  }

  &__mobile-details {
    margin-bottom: 0.625rem;
    @media(min-width: $sm) {
      display: none;
    }

    .sales-statement-info__details-column:not(.mobileHeading) {
      flex: 1 1 40%;

      &:first-of-type {
        flex: 1 1 60%;
      }
    }
  }

  &__button {
    display: flex;
    margin-top: 1.875rem;
    padding-bottom: 0.125rem;
    flex: 1;
    gap: 0.625rem;
    flex-direction: column;
    @media(min-width: $sm) {
      flex-direction: row;
      justify-content: flex-end;

      button {
        width: auto;
      }
    }

    button {
      width: 100%;
    }

    .button--outlined {
      @include button-outlined;
    }
  }
}