.hero-text {
  position: relative;
  text-align: center;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  & > span {
    font-size: 1rem;
    line-height: 1.2;

    @media(min-width: $md) {
      font-size: 1.5rem;
    }
  }
  
  &.preHeadingPantamera > span {
    font-family: 'Pantamera';
  }

  .heading {
    @include h1;
  }

  & > p {
    margin-bottom: 2rem;
  }
  &.teaserTextPantamera > p {
    font-family: 'Pantamera';
  }
  
  .button-link-block {
    margin-bottom: 0;
    width: auto;
  }

  & button:not(.modal__close-button) {
    display: inline-block;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;

    @include button-style-lg;
  }  
}