.contact-info-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__contact-info {
    display: flex;
    flex-direction: column;
    margin: 0.9375rem 0 2.1875rem 0;
  }

  .contact-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1.875rem;
    }

    & .link > span {
      font-size: 1rem;
    }

    @media(min-width: $md) {
      & .link > span {
        font-size: 1.125rem;
      }
    }

    &__icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 2.25rem;
      height: 2.25rem;
      margin-right: 0.625rem;
      color: var(--colorTextSecondary);
      background-color: var(--secondary);
      border-radius: 50%;
    }
  }

  .rvm-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 14.6875rem;
    color: var(--colorTextDarkBg);
    @include small;

    @media(min-width: $md) {
      flex-direction: row;
      width: auto;
    }

    @media(min-width: $lg) {
      width: 43.75rem;
    }

    &__item {
      display: flex;
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 0.625rem;
      }

      & label {
        font-weight: var(--font-weight-heavy);
        margin-right: 0.3125rem;
      }

      @media(min-width: $md) {
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 0.625rem;
        }
      }
    }
  }

  .error-messages {
    width: 100%;
    margin-top: 1.25rem;
    text-align: left;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}