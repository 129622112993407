.company-information-agreements {
  &__button {
    text-align: right;
  }

  &__table {
    margin-bottom: 2.5rem;

    & h4 {
      margin-bottom: 1.3125rem;
    }
  }

  &__no-agreements {
    margin: 1.25rem 0;
  }
}