.component-error {
    display: flex;
    align-items: center;
    border: 0.0625rem solid var(--borderComponentError);
    border-radius: 0.9375rem;
    padding: 2.25rem;

    &__text-container {
        display: inline-block;
        margin-left: 1.25rem;
    }
    &__header {
        font-size: 1.3125rem;
        line-height: 1.5625rem;
        font-weight: var(--font-weight-heavy);
        color: var(--colorTextErrorHeader);
    }

    &__text {
        display: block;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.1875rem;
    }
}