.login-modal-content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  // IE needs this to not overflow modal
  & > * {
    max-width: 100%;
  }

  & .login-modal-createaccount-button {
    margin: 0.625rem 0;
    & button {
      @include button-style-lg;
    }
  }
  & .login-modal-login-button {
    margin: 0.625rem 0;
  }
}