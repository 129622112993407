.compensations {
  margin: 2.5rem 0;

  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.9375rem;

    & span:first-child {
      @include h4;
      margin: 0 0.3125rem 0 0;
    }

    & span:nth-child(2) {
      @include h4;
      font-weight: var(--font-weight-base);
      margin: 0 0.625rem 0 0;
    }
  }

  &__table {
    @media(min-width: $md) {
      overflow-x: auto;
      padding: 0 0 1.25rem 0.0625rem;
    }

    & .unitNetAmount,
    .numberOfItems,
    .totalNetAmount {
      text-align: right;
    }

    td.table-column {
      &.unitNetAmount,
      &.numberOfItems,
      &.totalNetAmount {
        padding-right: calc(1.25rem + 0.75rem + 0.3125rem);
      }
    }
    
    @media(min-width: $xl) {
      overflow-x: initial;
      padding: 0;
    }
  }
}