.input-toggle-button {
  position: relative;
  display: flex;
  flex-direction: column;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  label {
    display: inline-block;
    font-size: 0.75rem;
    font-weight: var(--font-weight-heavy);
    margin-bottom: 0.3125rem;
  }

  &__radio-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;

    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border-radius: 0.4375rem;
      z-index: -1;
    }

    &.error {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineErrorTextInput);
      }
    }
  }

  &__option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 34%;
    font-size: 0.75rem;
    font-weight: var(--font-weight-super-heavy);
    height: 2.5rem;
    min-width: 6.25rem;
    padding: 0 0.9375rem;
    background-color: var(--backgroundToggleOption);
    color: var(--backgroundToggleOptionSelected);
    border: 0.0625rem solid var(--backgroundToggleOptionHover);
    transition: all 0.05s ease-in-out;

    @include focusStyle;

    &--few-items {
      &:first-of-type {
        border-radius: 0.3125rem 0 0 0.3125rem;
      }
      &:last-of-type {
        border-radius: 0 0.3125rem 0.3125rem 0;
      }
    }

    &--many-items {
      min-width: 2.5rem;
      flex: 1 1 calc(100% / 7);
      padding: 10px;
    }

    &--selected {
      background-color: var(--backgroundToggleOptionSelected);
      color: var(--backgroundToggleOption);
      border: 0.0625rem solid var(--backgroundToggleOptionSelected);
    }

    &:not(.input-toggle-button__option--selected) {
      cursor: pointer;

      &:hover {
        background-color: var(--backgroundToggleOptionHover);
      }
    }
  }
}