@import "@sass/layout-variables";
@import "@sass/mixins/typography-mixins";

.info-window {    
    &__content {
        display: flex;
        flex-direction: column;
        padding: 0.9375rem;
        font-family: var(--font-family-base);

        & .more-info-modal-link-container {

            font-weight: var(--font-weight-heavy);
            color: var(--colorLinkBase);
            border-bottom: 0.125rem solid var(--colorLinkBorder);
            cursor: pointer;
            &:hover {
                border-bottom: 0.125rem solid var(--colorLinkBorderHover);
            }
        }

        @media(min-width: $md) {
            padding: 2.5rem;
        }
    }

    &__close-button {
        position: absolute;
        top: -0.375rem;
        right: -0.375rem;
        width: 2.0625rem;
        height: 2.0625rem;
        border: none;
        background-color: var(--backgroundModalCloseButton);
        border-radius: 50%;
        color: var(--colorModalCloseButton);
        opacity: 1;
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 1;
        cursor: pointer;
        padding: 0;
        box-sizing: border-box;
        transform-style: preserve-3d;
        transition: background-color 0.2s ease-in;
    
        &:hover {
            background-color: var(--hoverBackgroundModalCloseButton);
        }
    
        &:before {
            content: '';
            position: absolute;
            top: -0.3125rem;
            left: -0.25rem;
            background-color: var(--backgroundModal);
            width: 2.625rem;
            height: 2.625rem;
            transform: translateZ(-0.0625rem);
            border-radius: 50%;
        }
        &:after {
            content: '×';
            position: absolute;
            top: 0.125rem;
            left: 0.5rem;
        }
    }

    &__content-header{
        & h2 {
            margin: 0.625rem 0;
        }
    }

    &__header-bottom {
        display: flex;
        flex-direction: column;

        & .panicon {
            color: var(--colorPexMapInfoHeadings);
        }
        & > .danger {
            background-color: var(--backgroundDanger);
            color: var(--colorTextAlertDefault);
            font-weight: var(--font-weight-heavy);
        }

        @media(min-width: $md) {
            flex-direction: row;
            align-items: center;
        }
    }

    &__opening-hours-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.3125rem;
    
        @media(min-width: $md) {
            margin-bottom: 0;
        }
    }

    &__opening-hours {
        color: var(--colorPexMapInfoHeadings);
        font-size: 0.875rem;
        font-weight: var(--font-weight-heavy);
        margin: 0.3125rem 0;

        @media(min-width: $sm) {
            font-size: 1.125rem;
        }

        @media(min-width: $md) {
            font-size: 1.3125rem;
            margin: 0 0.625rem;
        }
    }

    &__opening-hours-info{
        margin-top: 1.25rem;
        & .panel__content.info {
            font-weight: var(--font-weight-medium);
            background-color: var(--backgroundInfo);
            text-align: center;
            line-height: 1.5;

            & a {
                border-bottom: none;
                &:hover {
                    border-bottom: none;
                }
            }
        }
    }

    &__icons {
        display: flex;
        flex-direction: row;
    
        & .panicon {
            font-size: 1.375rem;
        }
    
        @media(min-width: $sm) {
            & .panicon {
                font-size: 1.75rem;
            }
        }
    }

    &__section-label {
        @include small;
        font-weight: var(--font-weight-heavy);
        margin-top: 1.25rem;
        margin-bottom: 0.3125rem;
    }

    &__section-text {
        font-size: 0.875rem;
        white-space: pre-wrap;
        
        @media(min-width: $md) {
            font-size: 1.125rem;
            .link {
                font-size: 1.125rem;
            }
        }

        &__opening-hour {
            display: block;
        }
    }

    .link {
        font-size: 0.875rem;
        font-weight: var(--font-weight-heavy);
        color: var(--primary);

        .panicon {
            display: inline-block;
            border: 0;
        }
    }
}