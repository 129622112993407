.step-indicator {
  $InnerDotWidth: 0.5625;
  $OuterDotWidth: 0.9375;
  $SeparatorWidth: 1.875;
  $BorderWidth: 0.0625;
  $TransitionTime: 200ms;  
  $LeftPadding: calc(($OuterDotWidth - $InnerDotWidth) / 2);
  
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: $LeftPadding * 1rem;
  height: $OuterDotWidth * 1rem;
  width: var(--step-indicator-width);

  &__outer-dot {
    position: absolute;
    top: 0;
    width: $OuterDotWidth * 1rem;
    height: $OuterDotWidth * 1rem;
    background-color: var(--backgroundStepIndicatorOuterDot);
    border-radius: 50%;
    border: $BorderWidth * 1rem solid var(--backgroundStepIndicatorInRange);
    transition: left $TransitionTime ease-in-out;
    left: var(--outerDotLeft);
  }

  .step-dot {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $InnerDotWidth * 1rem;
    height: $InnerDotWidth * 1rem;
  }

  .inner-dot {
    position: relative;
    width: $InnerDotWidth * 1rem;
    height: $InnerDotWidth * 1rem;
    border-radius: 50%;
    background-color: var(--backgroundStepIndicator);
    z-index: 1;
    transition: background-color $TransitionTime ease-in-out;

    &.in-range:not(.active) {
      background-color: var(--backgroundStepIndicatorInRange);
    }

    &.active {
      background-color: var(--backgroundStepIndicatorActive);
    }

    &__anchored-element {
      position: absolute;
    }
  }

  .step-separator {
    height: 0.0625rem;
    width: $SeparatorWidth * 1rem;
    background-color: var(--backgroundStepIndicator);
    transition: background-color $TransitionTime ease-in-out;

    &.in-range {
      background-color: var(--backgroundStepIndicatorInRange);
    }
  }
}