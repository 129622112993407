@import "../../../../Frontend/scss/layout-variables";

.top-list-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem;
    border-radius: 0.3125rem;
    margin-bottom: 0.625rem;
    font-size: 0.625rem;

    & span {
        color: var(--brightsun);
        text-transform: uppercase;
        font-weight: 700;
    }

    @media (min-width: $md) {
        font-size: 1rem;
    }
}

.top-list-no-result {
    margin-top: 2.5rem;
    text-align: center;
    color: var(--brightsun);
    font-weight: 700;
}

.top-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue);
    color: var(--ceramicwhite);
    text-transform: uppercase;
    padding: 0.4375rem 0.625rem;
    border-radius: 0.3125rem;
    margin-top: 0.375rem;
    font-size: 0.625rem;

    &__own {
        background-color: var(--brightsun);
        color: var(--blue);
    }

    &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
    }

    &__count {
        text-align: right;
        min-width: 6.25rem;
        text-transform: none;
    }

    @media (min-width: $md) {
        font-size: 1rem;
    }
}

.top-list-button-wrapper {
    margin-top: 1.25rem;
}

.separator-wrapper {
    position: relative;
    height: 0.125rem;
    margin: 0.625rem;

    &__separator {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
            to right,
            var(--skyblue) 50%,
            transparent 50%
        );
        background-size: 1.5rem 100%;
    }
}