.edit-email-modal {
  .modal__header {
    text-align: center;
  }

  margin: 3.125rem 0;

  &__fields {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2.5rem;
  }

  &__field {
    margin-bottom: 0.9375rem;
    width: 18.75rem;

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.25rem;

    & .edit-email-submit-button {
      margin-bottom: 0.625rem;
      width: 100%;

      button {
        @include button-style-lg;
      }
    }

    & .edit-email-delete-button {
      margin-bottom: 0.625rem;
    }

    @media(min-width: $sm) {
      & .edit-email-submit-button {
        width: auto;
      }
    }
  }

  &__error-messages {
    width: 100%;
    padding-top: 1.25rem;
    margin: auto;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }
}