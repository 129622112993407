﻿.help {
  height: 100%;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;

  .link-md-col {
    margin: 0 0.3125rem;
    height: 5.625rem;
  }

  &__corner-button {
    display: flex;
    position: relative;
    align-items: flex-end;
    pointer-events: all;
    width: 4.375rem;
    height: 2.8125rem;
    border-top-right-radius: 100%;
    cursor: pointer;
    background: linear-gradient(180deg, var(--gradientStartHelpButton), var(--gradientEndHelpButton));

    &.fullscreen {
      position: fixed;
    }

    @include focusStyle;

    & > span {
      font-family: var(--font-family-pantamera);
      color: var(--backgroundHelpContent);
      font-size: 0.875rem;
      font-weight: var(--font-weight-heavy);
      margin-bottom: 0.875rem;
      margin-left: 0.4375rem;

      @media (min-width: $md) {
        font-size: 1.8rem;
        margin-bottom: 1.875rem;
        margin-left: 1.875rem;
      }
    }

    @media (min-width: $md) {
      width: 10.9375rem;
      height: 7.0625rem;
    }
  }

  &__bubble-wrapper {
    position: absolute;
    top: -0.1875rem;
    right: -0.25rem;

    @media (min-width: $md) {
      top: 0;
      right: 1.25rem;
    }
  }

  &__shadow {
    position: fixed;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    box-shadow: 1.25rem 0 2.25rem 0 var(--backgroundPrimary);
    transition: width 0.25s ease-in-out, opacity 0.25s ease-in-out;

    @media (min-width: $md) {
      &.open {
        opacity: 0.3;
        width: calc(var(--helpWidthLg) - 1.5rem);
      }
    }
  }

  .content-wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    pointer-events: all;
    justify-content: space-between;
    padding: 3.75rem 2.5rem 2.5rem;
    background-color: var(--backgroundHelpContent);
    left: calc(-1 * var(--helpWidthSm));
    top: 0;
    height: 100%;
    width: var(--helpWidthSm);
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 0.25s;

    @media (min-width: $md) {
      left: calc(-1 * var(--helpWidthLg));
      width: var(--helpWidthLg);
    }

    &.open {
      visibility: visible;
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    &__header {
      position: relative;
      display: inline-block;
    }

    &__text {
      position: relative;
      font-size: 2.8125rem;
      font-weight: var(--font-weight-heavy);
      color: var(--primary);
      font-family: var(--font-family-pantamera);
    }

    &__bubble-wrapper {
      position: absolute;
      display: inline-block;
      top: -1.25rem;
      right: -2.8125rem;
    }

    &__content {
      position: relative;
      overflow-y: scroll;
      height: 100%;
      margin: 0.3125rem 0;
      padding-bottom: var(--helpScrollFadeHeight);

      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &__bottom {
      position: relative;
    }

    &__scroll-fade {
      position: absolute;
      top: calc(var(--helpScrollFadeHeight) - 0.3125rem);
      left: 0;
      width: 100%;
      height: var(--helpScrollFadeHeight);
      background-image: linear-gradient(
                      to bottom,
                      rgba(255, 255, 255, 0),
                      rgba(255, 255, 255, 1)
      );
    }

    &__close {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      cursor: pointer;
      color: var(--primary);

      @include focusStyle;

      & .panicon {
        display: block;
        position: relative;
        font-size: 0.875rem;

        &::after {
          position: absolute;
          content: '';
          z-index: -1;
          top: -0.5rem;
          left: -0.5rem;
          width: 1.875rem;
          height: 1.875rem;
          background-color: var(--backgroundButtonPrimary);
          border-radius: 50%;
        }
      }

      & > span:last-child {
        margin-left: 1.25rem;
        font-size: 1.3125rem;
        font-weight: var(--font-weight-heavy);
      }
    }
  }
}