@mixin container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media(min-width: $sm) {
    max-width: 768px;
  }

  @media(min-width: $md) {
    max-width: 9920px;
  }

  @media(min-width: $lg) {
    max-width: 930px;
  }

  @media(min-width: $xl) {
    max-width: 1200px;
  }
}
