.description-labels {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3.125rem;
  
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.625rem;

    &:not(:first-child) {
      margin-left: 0.9375rem;
    }

    & > span {
      font-size: 0.75rem;
      font-weight: var(--font-weight-heavy);
      color: var(--colorHeadings);
    }

    & > div {
      border-radius: 0.1875rem;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.3125rem;
    }

    &.primary > div {
      background-color: var(--backgroundBarPartPrimary);
    }
    &.secondary > div {
      background-color: var(--backgroundBarPartSecondary);
    }
    &.other > div {
      background-color: var(--backgroundBarPartOther);
    }

    &.previous > div,
    &.current > div {
      display: flex;
      flex-direction: column;
      & > div {
        background-color: var(--backgroundBarPartOther);
      }
      & > div:first-child {
        border-top-left-radius: 0.1875rem;
        border-top-right-radius: 0.1875rem;
        background-color: var(--backgroundBarPartPrimary);
      }
      & > div:nth-child(2) {
        background-color: var(--backgroundBarPartSecondary);
      }
      & > div:last-child {
        border-bottom-left-radius: 0.1875rem;
        border-bottom-right-radius: 0.1875rem;
      }
    }
    & > div > div {
      flex: 1;
    }
    &.previous > div {
      width: 0.625rem;
      & > div {
        opacity: 0.5;
      }
    }

    &.previous.parts-1 > div > div {
      background-color: var(--backgroundBarPartPrimary);
    }
    &.current.parts-1 > div > div {
      background-color: var(--backgroundBarPartPrimary);
      opacity: 0.5;
    }

    &.previous.parts-2 > div {
      & > div:first-child {
        background-color: var(--backgroundBarPartPrimary);
        opacity: 0.5;
      }
      & > div:last-child {
        background-color: var(--backgroundBarPartSecondary);
        opacity: 0.5;
      }
    }
    &.current.parts-2 > div {
      & > div:first-child {
        background-color: var(--backgroundBarPartPrimary);
      }
      & > div:last-child {
        background-color: var(--backgroundBarPartSecondary);
      }
    }
  }
}