.manage-user-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    width: 31.25rem;
    margin: 3.125rem 0;
  }

  &__header {
    @include h3;
    text-align: center;
  }

  &__field {
    margin: 1.25rem 0;
  }

  &__access-levels-link {
    text-align: center;
  }

  &__error-messages {
    width: 100%;
    margin: 1.25rem 0;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    margin-top: 2.5rem;

    & .submit-new-user-button {
      & button {
        @include button-style-lg;
        width: 17.1875rem;
      }
    }
  }
}