.contact-persons {
  &__contact-types {
    font-weight: var(--font-weight-super-heavy);
  }

  &__table {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__table-buttons {
    text-align: right;
    display: flex;
    justify-content: space-around;

    button {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
      margin-bottom: 0.3125rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    button {
      @include button-style-lg;
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 0.9375rem;
    margin-left: auto;
    margin-right: auto;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}