.report-summary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--white);

  &__summary-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.9375rem;

    @media(min-width: $sm) {
      width: 50%;
    }
    @media(min-width: $md) {
      width: 25%;
    }

    & label {
      font-weight: var(--font-weight-heavy);
      font-size: 0.75rem;
      margin-bottom: 0.1875rem;
    }

    & span {
      @include small;
    }
  }
}