.dashboard-block {
  position: relative;
  padding-top: 1.5625rem;
  margin-top: 4rem;
  background-color: transparent;
  background-size: cover;
  background-position: center center;

  //To make the background span the full viewport width
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50vw);
    width: 100vw;
    height: 100%;
    background-color: var(--dashboardBackgroundColor);
    z-index: -1;  // Place the background behind the content
  }

  @media(min-width: $lg) {
    padding: 4.6875rem 0 6.25rem 0;
  }

  h2 {
    color: var(--dashboardTitleColor);
  }

  .widget-wrapper {
    position: relative;
    min-height: 29.625rem;

    .panel {
      height: 100%;

      .panel__content.widget {
        height: 100%;
      }
    }

    @media(min-width: $md) {
      min-height: auto;
      height: 29.625rem;
    }

    @media(min-width: $lg) {
      padding: 0;
    }
  }

  &__widgets-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .widget-wrapper {
      flex: 1 1 100%;
      padding: 0;

      .panel__content.widget {
        border-radius: 0;

        @media (min-width: $lg) {
          border-radius: 0.9375rem;
        }
      }

      @media (min-width: $lg) {
        padding: 0.9375rem;
      }
    }

    &.even-nodes {
      .widget-wrapper {
        @media (min-width: $lg) {
          flex: 1 1 50%;
        }
      }
    }

    &.odd-nodes {
      .widget-wrapper {
        @media (min-width: $lg) {
          flex: 1 1 30%;
        }
      }
    }
  }
}