.link-container {
  padding-top: 2.5rem;

  & .linkbadge-root {
    margin-bottom: 0.625rem;
  }
  
  .content-area {
    row-gap: 0;
  }
}