.step-1 {
  max-width: 62.5rem;
  padding: 0 0.9375rem 0 0.9375rem;

  &__form {
    width: 100%;
    @media(min-width: $md) {
      width: 43.75rem;
    }
    margin-left: auto;
    margin-right: auto;

    .detail-form {
      margin-bottom: 3rem;
    }
  }

  &__top {
    text-align: center;
    margin-bottom: 3rem;

    .button-wrapper {
      margin-top: 2rem;

      button {
        width: 17.1875rem;
        @include button-style-lg;
      }
    }

    .top-header {
      @include h3;
    }
  }

  &__bottom {
    text-align: center;
    margin-top: 2.1875rem;

    .button-wrapper {
      button {
        width: 17.1875rem;
        @include button-style-lg;
      }

      .panicon {
        transform: translateY(0.125rem);
        margin-left: 4.0625rem;
      }
    }
  }

  &__modal-content {
    text-align: center;

    button {
      @include button-style-lg;
    }
  }

  &__modal-button {
    margin-top: 2rem;
  }
}