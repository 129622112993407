// Container max widths
$container-max-widths: (
        sm: 768px,
        md: 992px,
        lg: 930px,
        xl: 1200px,
        xxl: 1400px
);

// Media breakpoints
$xs: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Grid breakpoints
$grid-breakpoints: (
        sm: $sm,
        md: $md,
        lg: $lg,
        xl: $xl,
        xxl: $xxl
);
