.loading-indicator{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
        display: block;
        &--sm {
            font-size: 0.4rem;
        }

        &--md {
            font-size: 0.7rem;
        }
        
        &--lg {
            font-size: 1rem;
        }

        font-weight: var(--font-weight-heavy);
        font-family: var(--font-family-base);
        color: var(--colorTextLoading);

        &--inverted {
            color: var(--colorTextLoadingInverted);
        }
        &--darkmode {
            color: var(--colorTextLoadingDarkMode);
        }
    }
    &__dot-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    &__dot {
        display: inline-block;
        background-color: var(--backgroundLoadingDot);
        border-radius: 50%;

        &.inverted {
            background-color: var(--backgroundLoadingDotInverted);
        }
    
        &.dot1 {
            animation: fade1 1s ease reverse forwards infinite;
        }
        &.dot2 {
            animation: fade2 1s ease reverse forwards infinite;
        }
        &.dot3 {
            animation: fade3 1s ease reverse forwards infinite;
        }
    
        @keyframes fade1 {
            0% {
                opacity: 0.25;
            }
            37.5% {
                opacity: 1;
            }
            87.5% {
                opacity: 0;
            }
            100% {
                opacity: 0.25;
            }
        }
        @keyframes fade2 {
            0% {
                opacity: 0.5;
            }
            25% {
                opacity: 1;
            }
            75% {
                opacity: 0;
            }
            100% {
                opacity: 0.5;
            }
        }
        @keyframes fade3 {
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
        &--sm {
            width: 0.3125rem;
            height: 0.3125rem;
            margin: 0.25rem 0.1875rem;
        }

        &--md {
            width: 0.625rem;
            height: 0.625rem;
            margin: 0.4375rem 0.3125rem;
        }
        
        &--lg {
            width: 0.9375rem;
            height: 0.9375rem;
            margin: 0.625rem 0.5rem;
        }

    }
}