.deposit-stats-block {
  margin: 3.125rem 0;
  overflow: hidden;

  & h2 {
    margin-bottom: 3.125rem;
  }

  @media (min-width: $xl) {
    overflow: visible;
  }
}