.language-tooltip {
  position: relative;
  @include focusStyle;

  .panicon {
    font-size: 24px;
    min-width: 24px;
  }
}

.language-selection {
  &__choice-container {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    color: var(--colorMenuTopIcon);
    font-size: 1.125rem;
    font-weight: bold;
    font-family: var(--font-family-base);
    margin-bottom: 1rem;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    & span {
      cursor: pointer;
    }

    & label {
      cursor: pointer;
    }
  }

  &__radio-button {
    border-radius: 0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;

    &.checked {
      border: none;
      background-color: var(--secondary);
    }

    &:not(.checked) {
      border: 0.125rem solid var(--colorMenuTopIcon);
    }

    span.panicon {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.header-top__navigation ul li.header-top--link:hover .language-selection__link span {
  color: var(--colorMenuTopIcon);
}

.language-selection__choice-container:focus-within {
  @include focusStyle;
}

a.language-selection__link:focus {
  ::before {
    content: none;
  }
}