.submitted-registration-item {
  display: flex;
  flex-direction: column;

  &__header-top {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625rem;

    & .article-description {
      margin-bottom: 0.625rem;
    }

    & .process-step-label {
      align-self: flex-start;
    }

    @media(min-width: $sm) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.3125rem;
      & .article-description {
        margin-bottom: 0;
      }
      & .process-step-label {
        align-self: auto;
      }
    }
  }

  .article-description {
    @include headingCommon;
    font-size: 1.125rem;
    margin-right: 0.625rem;
    color: var(--colorTextDarkBg);
  }

  &__header-bottom {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
  }

  &__step-content {
    margin-top: 1.25rem;

    & h4 {
      margin-top: 0;
    }

    & .confirm-registration-button {
      & button {
        @include button-style-lg
      }
    }
  }
}