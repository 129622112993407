@mixin dark-mode {
  h1, h2, h3, h4, h5, h6, span.heading {
    color: var(--colorHeadingDarkBG);
  }

  p {
    color: var(--ceramicwhite);
  }
  .button--outlined {
    @include button-outlined-inverted;
  }
}

@mixin light-mode {
  h1, h2, h3, h4, h5, h6, span.heading {
    color: var(--colorHeadingPrimary);
  }

  p {
    color: var(--black);
  }
}
