.video-controls {
  position: absolute;
  background-color: var(--backgroundVideoControls);
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 0 1.5625rem 1.5625rem 0;

  &__play-button {
    position: absolute;
    top: 0.75rem;
    left: 0.1875rem;
    background-color: transparent;
    border: 0;
    font-size: 0.625rem;
    font-weight: var(--font-weight-heavy);
    cursor: pointer;
    padding-block-end: 1px;
    padding-block-start: 1px;
    padding-inline-end: 6px;
    padding-inline-start: 6px;

    @include focusStyle;

    .ctrl-btn {
      border-color: transparent transparent transparent var(--colorVideoControls);
      transition: 100ms all ease;
      border-style: solid;
      border-width: 0.4375rem 0 0.4375rem 0.875rem;
      height: 0.875rem;
      width: 0.875rem;
      display: block;
      margin: 0 auto;
    }

    &.playing {
      .ctrl-btn {
        border-style: double;
        border-width: 0 0 0 0.875rem;
      }
    }

    .ctrl-text {
      display: block;
      color: var(--colorVideoControls);
    }
  }
}