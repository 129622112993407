@mixin related-links-title {
  height: 1.625rem;
  margin-bottom: 1rem;
  border-bottom: 0.063rem solid var(--borderColorBase);
  font-weight: var(--font-weight-heavy);
  &.yellow {
    border-bottom: 0.063rem solid var(--borderColorYellow);
  }
  &.turquoise {
    border-bottom: 0.063rem solid var(--borderColorTitleTurquoise);
  }
}

@mixin link-wrap {
  min-height: 3.875rem;
  padding: 0.01rem;

  &.sm {
    min-height: 3.125rem;

    @media(min-width: $md) {
      min-height: 3.75rem;
    }
  }

  &.md {
    min-height: 3.75rem;

    @media(min-width: $md) {
      min-height: 4.6875rem;
    }
  }
}