.alert {
    position: relative;
    border-radius: 0.3125rem;
    padding: 0.75rem;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    display: inline-block;

    &--compact {
        padding: 0.625rem;
    }

    /* Types of alert: primary|info|success|danger */
    &--primary {
        background-color: var(--backgroundPrimary);
        color: var(--colorTextAlertDefault);
    }
    &--info {
        background-color: var(--backgroundInfo);
        color: var(--colorTextAlertInfo);
    }
    &--success {
        background-color: var(--backgroundSuccess);
        color: var(--colorTextAlertDefault);
    }
    &--danger {
        background-color: var(--backgroundDanger);
        color: var(--colorTextAlertDefault);
    }

    & span {
        font-weight: normal;
    }

    &__title {
        font-weight: var(--font-weight-super-heavy);
        font-size: 0.875rem;
        margin-bottom: 0.3125rem;
    
        &--compact {
            font-size: 0.75rem;
            margin-bottom: 0;
        }
    }
}