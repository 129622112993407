#CybotCookiebotDialog {
  $md: 48rem;
  $lg: 62rem;

  font-family: var(--font-family-base) !important;
  border-radius: 0 !important;
  background-color: var(--offwhite) !important;

  .CybotCookiebotDialogContentWrapper {

    .CybotCookiebotScrollContainer {
      border-bottom: none;
      padding: 0;
      color: var(--darkblue);
    }

    /* Logotypes */

    #CybotCookiebotDialogHeader {
      display: none;
    }

    /* Tabs */

    #CybotCookiebotDialogNav {
      border-bottom: 1px solid var(--lightblue);

      @media(min-width: $lg) {
        padding: 1rem 2.5rem 0;
      }

      ul {
        li {
          a {
            color: var(--darkblue);

            &:hover {
              color: var(--blue);
            }

            &.CybotCookiebotDialogActive {
              color: var(--darkblue);
              border-bottom-color: var(--darkblue);
            }
          }
        }
      }
    }

    /* Dialog body text */

    #CybotCookiebotDialogTabContent {
      .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
        input:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
          background-color: var(--darkblue);
        }

        .CybotCookiebotDialogBodyLevelButtonSlider {
          background-color: var(--lightGrey);
          &:before {
            background-color: var(--lightblue);
          }
        }
      }

      #CybotCookiebotDialogBody {
        color: var(--darkblue);

        .CybotCookiebotScrollContainer {
          max-height: none;

          .CybotCookiebotScrollArea {
            scrollbar-color: var(--darkblue) var(--lightGrey) !important;

            @media (min-width: $lg) {
              padding: 0 2.5rem;
            }

            h2 {
              font-family: var(--font-family-pantamera);
              font-size: 1.5rem;
              @media (min-width: $md) {
                font-size: 2.25rem;
              }
            }
          }
        }

        .CybotCookiebotDialogBodyBottomWrapper {

          #CybotCookiebotDialogBodyLevelButtonsSelectPane {
            border-bottom: none;
            padding: 0;

            .CybotCookiebotDialogBodyLevelButtonWrapper {
              border-right: none;

              label {
                strong {
                  &:hover {
                    color: var(--blue);
                  }
                }
              }
            }
          }
        }
      }

      #CybotCookiebotDialogDetailBody {
        #CybotCookiebotDialogDetailBodyContentCookieContainerTypes {
          .CookieCard {
            color: var(--darkblue);

            button {
              &:hover {
                color: var(--blue);
              }
            }
          }
        }
      }
    }

    /* Buttons */

    #CybotCookiebotDialogFooter {

      #CybotCookiebotDialogBodyButtonsWrapper {
        button {
          border-color: var(--darkblue);
          color: var(--darkblue);
          border-radius: 0;
          background-color: var(--lightblue);
        }
      }
    }
  }

  /* Link */

  #CybotCookiebotDialogBodyContentText a, #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink, #CybotCookiebotDialogDetailBulkConsentList dt a, #CybotCookiebotDialogDetailFooter a, .CybotCookiebotDialogBodyLevelButtonIABDescription a, .CybotCookiebotDialogDetailBodyContentCookieLink, #CybotCookiebotDialogDetailBodyContentTextAbout a, .CookieCard a {
    color: var(--blue) !important;
  }
}

.CybotCookiebotDialogActive + #CybotCookiebotDialogBodyUnderlay {
  opacity: 0.8 !important;
}

.CybotCookiebotFader {
  display: none;
}

.cookieconsent-optout-marketing {
  background: var(--ceramicwhite);
  font-family: var(--font-family-base);
  border-radius: 0.9375rem;
  text-align: center;
  width: 100%;
  margin-top: 3rem;

   a {
     outline: none;
     position: relative;
     font-weight: 700;
     color: var(--blue);
     text-decoration: none;
     margin-right: 0.3rem;
   }
}