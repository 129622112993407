@mixin a {
  position: relative;
  font-weight: var(--font-weight-heavy);
  color: var(--colorLinkBase);
  text-decoration: none;
  transition: border-bottom .5s;

  @include focusStyle;
  &:focus::before {
    top: -0.25rem;
    left: -0.25rem;
    height: calc(100% + 0.5rem);
    width: calc(100% + 0.5rem);
  }

  &[href^="http"]:not(.link-badge, .button),
  &[href^="mailto:"]:not(.link-badge, .button),
  &[href^="tel:"]:not(.link-badge, .button),
  &[href^="/globalassets"]:not(.link-badge, .button) {
    border-bottom: 2px solid var(--colorLinkBorder);

    &:hover {
      border-bottom: 2px solid var(--colorLinkBorderHover);
    }
  }
}