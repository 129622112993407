@mixin block {
    @include make-container-max-widths;
    width: 100%;
}

@mixin page {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;

    &__container {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        $min: map-get($breakpoints, $breakpoint);

        @if $min {
            @media (min-width: $min) { max-width: $container-max-width; }
        } @else { max-width: $container-max-width; }
    }
}
