@mixin flex-centered-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.create-or-edit-report {
  &__left {
    display: flex;
    flex-direction: column;
    @media(min-width: $lg) {
      flex-direction: row;
    }
  }

  &__start-report {
    @include flex-centered-style;
    flex: 1;
    margin: 0.625rem 0;
  }

  &__text {
    font-size: 1.125rem;
    text-align: center;
  }

  &__period-date {
    font-weight: var(--font-weight-heavy);
    display: block;
    text-align: center;
    @media(min-width: $md) {
      display: inline;
    }
  }

  &__report-button {
    margin-top: 1.25rem;

    & button {
      @include button-style-lg;
    }
  }

  &__dashes-wrapper {
    position: relative;
    height: 0.0625rem;
    margin: 1.875rem 0;
    @media(min-width: $lg) {
      height: auto;
      margin: 0;
      width: 0.0625rem;
    }
  }

  &__dashes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
                    to right,
                    var(--primary) 50%,
                    transparent 50%
    );
    background-size: 1.875rem 100%;

    @media(min-width: $lg) {
      background-image: linear-gradient(
                      to bottom,
                      var(--primary) 50%,
                      transparent 50%
      );
      background-size: 100% 1.875rem;
    }
  }

  &__excel-upload {
    @include flex-centered-style;
  }

  &__file-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.9375rem 0 1.25rem;
  }
}