.a-to-z-container {
  margin-bottom: 5rem;
  padding-top: 1rem;

  &__letter-selection {
    max-width: calc(var(--option-width) * 7);
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 0.0625rem solid var(--backgroundToggleOptionHover);
    border-radius: 10px;
    overflow: hidden;

    .input-toggle-button__option {
      width: var(--option-width);
    }

    @media(min-width: $sm) {
     margin: 0;
    }

    @media(min-width: $xxl) {
      display: inline-block;
      max-width: 100%;

      & .input-toggle-button__option {
        max-width: var(--option-width);
      }
    }
  }

  &__links {
    margin-top: 3.75rem;

    .link-item {
      &:not(:last-child) {
        margin-bottom: 1.25rem;
      }

      .link > span {
        font-size: 1.125rem;
        line-height: 1.5;
      }
    }
  }
}