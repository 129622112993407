@import '../../../../../Frontend/scss/layout-variables';

.ean-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  
  @media (min-width: $lg) {
    margin: 5.625rem 0;    
  }

  h2 {
    text-align: center;
  }

  h2 + span {
    max-width: 37.5rem;
    text-align: center;
    margin-bottom: 1.25rem;
    line-height: 1.5;
  }

  button {
    width: 10.625rem;
  }

  img {
    max-width: 15.625rem;
  }

  &__search {
    position: relative;
    width: 100%;
    margin-bottom: 0.625rem;

    @media (min-width: $sm) {
      width: 25rem;
    }

    .input-search {
      width: 100%;

      & .icon-search {
        font-size: 1rem !important;
        min-width: 1rem !important;
        top: 1.125rem;
        left: 1.125rem;

        @media (min-width: $md) {
          font-size: 1.25rem !important;
          min-width: 1.25rem !important;
          top: 1.25rem;
          left: 1.25rem;
        }
      }

      .input-text__wrapper {
        &::before {
          border-radius: 2.1875rem;
        }
      }

      #ean-input {
        height: 3.5rem;
        border-radius: 1.75rem;
        padding: 0 0.9375rem 0 3rem !important;
      }
    }
  }

  &__result {
    max-width: 23rem;
    margin-top: 1.25rem;
    width: 100%;
  }

  &__result-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.875rem;

    strong {
      margin-right: 0.3125rem;
    }

    &:not(:last-child) {
      margin-bottom: 0.3125rem;
    }
  }

  ul {
    li {
      font-size: 0.875rem;
      margin-top: 0;
      padding-bottom: 0;
      &::before {
        width: 0.5rem;
        height: 0.5rem;
        top: 0.625rem;
      }
    }
  }

  &__error {
    width: 100%;
    margin-top: 0.625rem;
    & .danger {
      width: 100%;
    }
    @media (min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}