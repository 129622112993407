.material-spec-review-item {
  & h6 {
    margin-top: 0;
  }

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  &__spec-items {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .spec-item {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding-right: 0.625rem;

      @media(min-width: $md) {
        width: 25%;
      }

      & label {
        font-weight: var(--font-weight-heavy);
        font-size: 0.75rem;
        margin-bottom: 0.1875rem;
      }

      & span {
        @include small;
        word-break: break-word;
      }
    }
  }
}