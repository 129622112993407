.faq-list {
  padding-bottom: 6.25rem;

  &__link-container {
    min-height: calc(3.625rem + var(--hover-expand-size) * 2 + 0.9375rem);

    @media (min-width: $md) {
      min-height: calc(5.625rem + var(--hover-expand-size) * 2 + 0.9375rem);
    }

    .link-badge__container {
      height: 3.625rem;

      &:hover {
        height: calc(3.625rem + var(--hover-expand-size) * 2) !important;
      }

      @media (min-width: $md) {
        height: 5.625rem;

        &:hover {
          height: calc(5.625rem + var(--hover-expand-size) * 2) !important;
        }
      }

      .link-badge__link-text {
        max-height: none;
        font-size: 0.875rem;
        -webkit-line-clamp: 3;

        @media (min-width: $md) {
          font-size: 1.125rem;
        }
      }
    }
  }
}