.pending-registration {
  margin-top: 1.75rem;

  &__text {
    margin-top: 1.15rem;
    padding: 1.875rem 1.875rem 1.5rem 1.875rem;
    border-radius: 0.9375rem;
    background-color: var(--pendingRegistrationTextBgColor);

    p {
      color: var(--colorTextBase);
    }
  }
}