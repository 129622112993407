﻿.bar-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0.0625rem;
  width: 0.3125rem;

  &:last-child {
    width: 0.625rem;

    & .bar-part {
      opacity: 1;
    }
  }

  &:hover {
    box-shadow: 0.125rem -0.125rem 0.625rem 0 var(--shadowColorBarHover);
  }

  & .barchart-tooltip-wrapper,
  & .barchart-tooltip-trigger {
    height: 100%;
    width: 100%;

    & > div[role='tooltip'] {
      padding: 0.625rem;
    }
  }

  @media (min-width: $sm) {
    width: 0.5rem;
    margin: 0 0.125rem;
    &:last-child {
      width: 0.9375rem;
    }
  }

  @media (min-width: $md) {
    width: 0.9375rem;
    &:last-child {
      width: 1.875rem;
    }

    & .barchart-tooltip-wrapper,
    & .barchart-tooltip-trigger {
      & > div[role='tooltip'] {
        padding: 1.25rem;
      }
    }
  }

  .bar-part {
    width: 100%;
    opacity: 0.5;

    &.primary {
      background-color: var(--backgroundBarPartPrimary);
    }

    &.secondary {
      background-color: var(--backgroundBarPartSecondary);
    }

    &.other {
      background-color: var(--backgroundBarPartOther);
    }

    &:first-child {
      border-top-left-radius: 0.1875rem;
      border-top-right-radius: 0.1875rem;
    }
  }
}

.tool-tip-content {
  max-width: 12.5rem;
  width: max-content;
  text-align: center;

  &__type {
    font-weight: var(--font-weight-heavy);
    color: var(--colorHeadings);
    font-size: 1rem;

    &:not(:first-child) {
      margin-top: 0.625rem;
    }

    @media (min-width: $md) {
      font-size: 1.125rem;
    }
  }
  
  &__type-part {
    margin-top: 0.3125rem;
    & span:first-child {
      font-weight: var(--font-weight-heavy);
      margin-right: 0.3125rem;
    }

    &.previous {
      font-size: 0.625rem;
    }

    @media (min-width: $md) {
      &.previous {
        font-size: 0.75rem;
      }
    }
  }
}
