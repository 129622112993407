.form-summary {
  margin-bottom: 1.25rem;
  padding: 1.875rem 1.875rem 0.9375rem 1.875rem;
  border-radius: 0.9375rem;
  background-color: var(--depositLabelOrderSummaryBgColor);

  &__shipping {
    margin-bottom: 1.25rem;
  }

  &__header {
    @include h5;
    margin-bottom: 0.75rem;
  }

  &__summary-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625rem;

    & label {
      font-weight: var(--font-weight-heavy);
      font-size: 0.75rem;
      margin-bottom: 0.1875rem;
    }

    & span {
      @include small;
    }
  }
}