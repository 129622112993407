.related-links-column {
  margin-bottom: 2.125rem;

  &__title {
    font-size: 0.875rem;
    @include related-links-title;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    padding: 0;
    margin: 0;

    &::before {
      content: none;
    }
  }  

  .link-badge__link-wrap {
    @include link-wrap;
  }
  
  .content-area {
    gap: 0 !important;
  }
}

.related-links {
  margin-bottom: 3.125rem;

  &__title {
    @include related-links-title;
  }
  
  .link-badge__link-wrap {
    @include link-wrap;
  }
  
  .content-area {
    gap: 0 !important;
  }
}