.expandable-panel {
  $DesktopHeight: 90rem;
  $MobileHeight: 110rem;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button--expandable {
      height: 1.5625rem;
      width: 1.5625rem;
      @media (min-width: $md) {
        height: 2.25rem;
        width: 2.25rem;
        & div {
          height: 1rem;

          &:after {
            height: 1rem;
          }

          &.expanded:after {
            height: 0;
          }
        }
      }
    }
  }

  &__separator {
    height: 0;
    margin-top: 0;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    transition: margin-top 0.5s ease-in, opacity 0.3s 0.15s ease, background-color 0.3s 0.15s ease;
    opacity: 0;
    background-color: transparent;

    &.show {
      transition: margin-top 0.5s ease-out, opacity 0.3s 0.15s ease, background-color 0.3s 0.15s ease;
      background-color: var(--borderPanelDefault);
      opacity: 1;
      margin-top: 1.875rem;
      height: 0.0625rem;
    }
  }

  &__content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out, padding-top 0.5s ease-out;
    padding-top: 0;

    &.expanded {
      max-height: $MobileHeight;
      overflow: none;
      height: auto;
      transition: max-height 0.5s ease-in, padding-top 0.5s ease-in;
      padding-top: 1.875rem;
    }

    @media(min-width: $md) {
      &.expanded {
        max-height: $DesktopHeight;
      }
    }
  }

  &__inner-content {
    overflow-y: auto;
    max-height: calc($MobileHeight - 10rem);

    @media(min-width: $md) {
      max-height: calc($DesktopHeight - 10rem);
    }
  }

  &__icon-wrapper {
    @media(min-width: $md) {
      & .button--expandable {
        height: 2.25rem;
        width: 2.25rem;

        & div {
          height: 1rem;

          &:after {
            height: 1rem;
          }

          &.expanded:after {
            height: 0;
          }
        }
      }
    }
  }
}