.flyers-block {
  &__form {
    text-align: center;
    max-width: 43.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  &__buttons {
    text-align: center;
    
    margin-bottom: 2.5rem;

    & button .panicon {
      margin-right: 0.3125rem;
      margin-left: 0;
    }
  }
}