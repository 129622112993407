.details-summary {
  display: flex;
  flex-direction: column;
  @media(min-width: $sm) {
    flex-direction: row;
  }
  @media(min-width: $lg) {
    margin-left: 1.25rem;
  }

  &__left-col {
    @media(min-width: $sm) {
      flex: 1;
    }
  }

  &__detail-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.9375rem;

    & label {
      font-size: 0.75rem;
      font-weight: var(--font-weight-heavy);
      margin-bottom: 0.1875rem;
    }

    & span {
      font-size: 0.875rem;
    }
  }

  &__right-col {
    @media(min-width: $sm) {
      flex: 1;
    }
  }
}