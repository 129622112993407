@mixin ul {
  padding-left: 2.5rem;
  list-style: none;

  & > li {
    position: relative;

    @include p;

    margin-top: .1rem;
    padding-top: .25rem;
    padding-bottom: .25rem;

    &:before {
      content: ' ';
      position: absolute;
      background-color: var(--listBulletColor);
      top: 0.813rem;
      left: -1.25rem;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 50%;
    }

    ul {
      padding-left: 1.25rem;

      li {
        font-size: 0.875rem;

        &:before {
          left: -1.125rem;
          top: 0.625rem;
          width: 0.5rem;
          height: 0.5rem;
          border: 0.125rem solid var(--listBulletColor);
          background-color: transparent;
        }

        li {
          font-size: 0.75rem;

          &:before {
            top: 0.625rem;
            left: -0.9375rem;
            width: 0.375rem;
            height: 0.375rem;
            border: none;
            background-color: var(--listBulletColor);
          }
        }
      }
    }
  }
}

@mixin ol {
  list-style: none;
  counter-reset: pantamera-counter;
  padding-left: 1.5625rem;

  @include p;

  & li {
    padding-top: .25rem;
    padding-bottom: .25rem;
    counter-increment: pantamera-counter;

    &:before {
      content: counter(pantamera-counter) '. ';
      padding-right: 0.375rem;
      color: var(--listBulletColor);
      font-weight: bold;
    }
  }

  & > li > ol > li {
    font-size: 0.875rem;
  }

}
