.content-area {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  row-gap: 2rem;

  &__item {
    flex: 0 1 auto;
    width: 100%;
  }

  &.margin-bottom {
    margin-bottom: 1.5625rem;

    @media(min-width: $md) {
      margin-bottom: 3.125rem;
    }
  }
  
  &.Link {
    row-gap: 1rem;
    @media(min-width: $md) {
      .content-area__item {
        width: 49%
      }
    }
  }
  
  &.LargeTeaser {
    row-gap: 2rem;
  }
  
  &.SmallTeaser {
    row-gap: 2rem;
    
    @media(min-width: $md) {
      .content-area__item {
        width: 48%
      }
    }
  }
}