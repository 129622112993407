.county-stats {
  @include container;
  display: flex;
  flex-direction: row;
  
  &__sweden {
    width: 100%;
    position: relative;
    padding: 1rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .county-stats-content {
      width: 100%;

      &__title {
        @include h1;
        margin-top: 1.5rem;
      }

      &__body {
        @include p;
        margin-bottom: 1.5rem;
      }

      &__select-container {
        max-width: 20rem;
        margin-bottom: 2rem;
      }

      &__top-list {
        margin-bottom: 2rem;

        & > div {
          @include h2;
          font-weight: 300;
        }
      }
    }
  }
}