.article-search-and-filter {
  margin-bottom: 1.875rem;

  & .panel__content {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    color: var(--white);
  }

  @media(min-width: $xl) {
    & .panel__content {
      flex-direction: row;
    }
  }

  &__first-section {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media(min-width: $lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__display-search {
    display: flex;
    flex-direction: column;

    & #article-displaymode {
      flex: 1;
    }

    & .article-displaymode {
      margin-bottom: 0.9375rem;
    }

    & > div:last-child {
      flex: 1;
    }

    @media(min-width: $sm) {
      flex-direction: row;
      & .article-displaymode {
        margin-bottom: 0;
        margin-right: 0.625rem;
      }
    }
    @media(min-width: $md) {
      & > div:last-child {
        flex: initial;
      }
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: column;
    margin-top: 0.9375rem;

    & .article-include-inactive {
      margin-top: 0.625rem;
    }

    @media(min-width: $sm) {
      flex-direction: row;
      & .article-include-inactive {
        margin-left: 1.25rem;
        margin-top: 0;
      }
    }

    @media(min-width: $lg) {
      margin-top: 0;
      & .article-select-all {
        margin-left: 1.25rem;
      }
    }
  }

  &__last-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.9375rem;

    & .excel-export-button {
      margin-top: 0.625rem;
    }

    & .excel-export-button,
    & .unregister-button,
    & button {
      width: 100%;
    }

    @media(min-width: $sm) {
      flex-direction: row;
      & .unregister-button {
        margin-right: 0.625rem;
        flex: initial;
        width: auto;
      }
      & .excel-export-button {
        margin-top: 0;
        flex: initial;
        width: auto;
      }
      & button {
        width: auto;
      }
    }
    @media(min-width: $xl) {
      margin-top: 0;
    }
  }
}