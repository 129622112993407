.rvm {
  display: flex;
  flex-direction: column;
  border-radius: 0.9375rem;
  border: 0.0625rem solid var(--borderPanelDefault);
  padding: 1.25rem;

  & button {
    width: 100%;
  }

  & .payments-btn {
    display: block;
  }

  & .contact-info-btn {
    display: block;
    margin-top: 0.625rem;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.875rem 0;
    background-color: var(--backgroundInfo);
    border-radius: 0.9375rem;
    color: var(--primary);
    margin-bottom: 1.25rem;

    .icon-wrap {
      position: relative;
      display: inline-block;

      &.faded {
        & .panicon.icon-pantautomat {
          opacity: 0.3;
        }
      }
    }

    .status-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -1.25rem;
      right: 0.3125rem;
      width: 3.625rem;
      height: 3.625rem;
      border-radius: 50%;
      z-index: 1;

      & .panicon:first-child {
        position: absolute;
        color: var(--backgroundInfo);
      }

      &.Active {
        color: var(--backgroundSuccess);
      }

      &.NotRead {
        color: var(--backgroundAlert);

        & .panicon:first-child {
          top: -0.3125rem;
          right: -0.4375rem;
        }
      }

      &.InActive {
        color: var(--backgroundVeryDanger);
      }

      &__icon-bg {
        background-color: var(--backgroundInfo);
        height: 80%;
        width: 1.875rem;
        position: absolute;
      }
    }
  }

  &__info-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

  }

  &__name-and-id {
    @include h5;
  }

  &__info-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.3125rem;

    @include small;

    & > span:first-child {
      margin-right: 0.3125rem;
      font-weight: var(--font-weight-heavy);
    }

    &.hide {
      visibility: hidden;
    }
  }
}