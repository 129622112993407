﻿.panel-wrapper {

}

.fields-wrapper {
  margin-top: 3.75rem;
  max-width: 43.75rem;
  width: 100%;

  .toggle-field {
    [role='radio'] {
      flex: 1;
    }
  }

  &__title {
    margin-bottom: 0.9375rem;
    transition: opacity 0.25s;

    &--disabled {
      opacity: 0.3;
    }
  }

  &__field {
    margin-bottom: 1.25rem;
    transition: opacity 0.25s;

    .field-info {
      font-size: 0.75rem;
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;

    }

    .custom-date-wrapper {
      width: 100%;

      @media (min-width: $md) {
        width: 50%;
      }

      &__input {
        width: 100%;
      }
    }

    .custom-date-label {
      font-weight: 700;
    }
  }

  &__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
  }
}

.buttons-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .next-button {
    margin-top: 0.625rem;
    margin-bottom: 1.875rem;

    & button {
      @include button-style-lg;

      @media (min-width: $md) {
        width: 17.1875rem;
      }

      span {
        line-height: 1.2;
      }
    }
  }

  & .save-button {
    margin-bottom: 0.9375rem;

    & button {
      @include button-style-lg;
      width: 17.1875rem;
    }
  }

  & .prev-button {
    margin-bottom: 1.875rem;

    button {
      width: 8.75rem;
    }
  }

  & .cancel-button {
    button {
      width: 5.625rem;
    }
  }

  &__button-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
      margin-left: 0 !important;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }

    &--next {
      width: 100%;
      &> *:only-child {
        margin: 0 auto;
      }
    }

    &--prev {
      width: 100%;
      padding: 0 0.625rem;
    }
  }
}

.form-step-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 62.5rem;
  width: 100%;

  &--wide {
    @media (min-width: $md) {
      min-width: 31.25rem;
    }

    @media (min-width: $lg) {
      min-width: 43.75rem;
    }

    .fields-wrapper {
      margin-top: 1.875rem;
    }
  }

  &--producer-form-step-5 {
    .panel__preview,
    .panel-terms {
      @media (min-width: $lg) {
        min-width: 62.5rem;
      }
    }

    .panel-terms {
      label {
        font-size: 0.875rem;
      }
    }

    .f-col {
      .psi {
        flex: 1 0 18%;
      }
    }
  }

  &__button-wrapper {
    width: 100%;
    height: 1.875rem;
    position: relative;

    .edit-button, .submit-button {
      position: absolute;
      right: 0;
    }
  }

  .panel-wrapper {
    width: 100%;
  }

  &__panel-container {
    width: 100%;
    margin-bottom: 2.5rem;

    .fields-wrapper__title {
      margin-top: 0;
    }

    .copy {
      @media (min-width: $md) {
        position: absolute;
        top: 1.9375rem;
        right: 1.875rem;
      }
    }
  }

  &__content {
    text-align: center;
  }

  &__error {
    @include formErrorMessages;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;

    @media (min-width: $md) {
      width: 31.25rem;
    }
  }

  &__terms-wrapper {
    @include formTermsWrapper;
  }

  &__terms-wrapper-text {
    p {
      margin-bottom: 0 !important;
      margin-left: 1rem;
    }

    .button-link-block {
      margin-bottom: 0;
      margin-left: 2rem;

      .link > span {
        @include p;
      }
    }
  }
}

