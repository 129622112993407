.unregister-confirm-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.1875rem;

    & button {
      @include button-style-lg;
      width: 17.1875rem;
    }
  }
}