@import '../../../../../../Frontend/scss/layout-variables';
@import '../../../../../../Frontend/scss/variables';

.deposit-label-preview {
  height: 10rem;
  line-height: 1.2;
  font-size: 0.375rem;
  width: 10rem;
  border-radius: 0.625rem;
  background-color: white;

  &.nutritions {
    height: 15.75rem;
    width: 22.3125rem;
  }

  @media(min-width: $md) {
    width: 20.8125rem;
    height: 20.8125rem;
    &.nutritions {
      width: 35rem;
      height: 22rem;
    }
    font-size: 0.625rem;
  }

  &__outer-frame {
    height: 100%;

    &.nutritions {
      border-radius: 0.5rem;
      border: 0.0625rem solid var(--depositCodeColor);
      padding: 0.375rem;
    }

    @media(min-width: $md) {
      &.nutritions {
        padding: 0.75rem;
        border-radius: 0.625rem;
        border: 0.125rem solid var(--depositCodeColor);
      }
    }
  }

  &__inner-frame {
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--depositCodeColor);
    padding: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: row;

    @media(min-width: $md) {
      border-radius: 0.375rem;
      border: 0.125rem solid var(--depositCodeColor);
      padding: 0.625rem;
    }
  }

  .left-column {
    display: flex;
    flex-direction: column;
    flex: 6;

    &__ingredients {
      flex: 6;
      white-space: pre-wrap;
      overflow-y: hidden;

      &.nutritions {
        padding-right: 1rem;
      }
    }

    &__code-wrapper {
      display: flex;
      flex-direction: row;
      flex: 4;

      .barcode {
        padding: 0.0625rem 0.3125rem 0.3125rem 0.3125rem;

        & img {
          width: 6.25rem;
          height: 3.4375rem;
        }

        @media(min-width: $md) {
          padding: 0.1875rem 0.625rem 0.5rem 0.625rem;
          & img {
            width: auto;
            height: auto;
          }
        }
      }

      .deposit-code {
        align-self: flex-end;
        border-radius: 0.3125rem;
        border: 0.0625rem solid var(--depositCodeColor);
        height: 2.5rem;
        width: 1.6875rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &.has-image {
          border: none;
        }

        & img {
          width: 100%;
          height: 100%;
        }

        @media(min-width: $md) {
          height: 5rem;
          width: 3.4375rem;
          border-radius: 0.375rem;
          border: 0.125rem solid var(--depositCodeColor);
        }
      }
    }
  }

  .right-column {
    display: flex;
    flex-direction: column;
    flex: 4;
    padding-left: 0.625rem;

    &__nutrition {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}