table {
  .table-row {
    height: 3.75rem;

    &.skinny {
      height: 2.5rem;
    }

    &--avoid-page-break {
      page-break-inside: avoid;
    }
  }

  .table-column {
    @media (min-width: $xs) {
      padding-right: 0.625rem;
      padding-left: 0.625rem;

      &.first-column {
        &:not(.expandable) {
          padding-left: 1.25rem;
        }
      }
      .button--expandable {
        margin-right: 0.625rem;
      }
      &:not(.visible-on-mobile) {
        display: none;
      }
    }

    &.sm {
      @media (min-width: $sm) {
        @include table-column-mobile-breakpoint;
      }
    }

    &.md {
      @media (min-width: $md) {
        @include table-column-mobile-breakpoint;
      }
    }

    &.lg {
      @media (min-width: $lg) {
        @include table-column-mobile-breakpoint;
      }
    }
  }

  .mobile-table-row {
    &.sm {
      @media (min-width: $sm) {
        display: none;
      }
    }

    &.md {
      @media (min-width: $md) {
        display: none;
      }
    }

    &.lg {
      @media (min-width: $lg) {
        display: none;
      }
    }

    .content {
      overflow: hidden;
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      font-size: 0.875rem;
      font-weight: normal;
      transition: max-height 0.4s ease-in-out;

      & label {
        margin-right: 0.3125rem;
        min-width: 4.375rem;
        font-size: 0.75rem;
        font-weight: var(--font-weight-heavy);
        display: block;
      }

      & > div {
        &:not(:last-child) {
          margin-bottom: 0.625rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;
        }
      }

      &:not(.expanded) {
        max-height: 0;
      }

      &.expanded {
        max-height: calc(var(--num-table-row-content-cols) * 3.125rem + 4rem);
      }
    }
  }
}