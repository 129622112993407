@import '../../../../../../Frontend/scss/variables';

.label-text-ingredients {
  & .label-ingredients-info-panel {
    font-size: 0.75rem;
    line-height: 1.5;
    padding: 0.9375rem;
    border-radius: 0.3125rem;
    color: var(--colorTextDarkBg);
  }

  &__text-area {
    margin-top: 0.9375rem;
  }

  &__chars-text {
    font-size: 0.75rem;
    margin-top: 0.4375rem;
    text-align: right;
    margin-right: 0.24rem;
  }

  &__format-text-button {
    margin-top: 1rem;
  }
}