.deposit-labels-block {
  position: relative;
  margin-bottom: 0.9375rem;

  &:last-child {
    padding-bottom: 6rem;
  }

  &__modal-content {
    text-align: center;

    button {
      @include button-style-lg;
    }
  }

  &__modal-content-button {
    margin-top: 2rem;
  }
}