@import './layout-variables';

.input-select {
    &__label {
        display: block;
        font-weight: 700;
        font-size: 0.75rem;
        margin-bottom: 0.3125rem;
    }

    &__select-wrapper {
        position: relative;
        &::before {
            position: absolute;
            top: -0.125rem;
            left: -0.125rem;
            width: calc(100% + 0.25rem);
            height: calc(100% + 0.25rem);
            border-radius: 0.4375rem;
        }
        &:focus-within::before {
            content: '';
            border: 0.125rem solid var(--outlineFocusTextInput);
        }
        &.error::before {
            content: '';
            border: 0.125rem solid var(--darkorange);
        }

        & .panta-select-container {
            position: relative;
        }
        & .panta-select__control {
            border-radius: 0.3125rem;
            min-height: 2.5rem;
            background-color: var(--backgroundSelectOptionDefault);
            border-color: var(--borderColorSelect);
            &:hover,
            &:focus,
            &.panta-select__control--is-focused {
                border-color: var(--borderColorSelect);
            }
        }

        & .panta-select__value-container {
            font-size: 0.875rem;
            margin-left: 0.3125rem;
            color: var(--colorTextInput);
        }
        
        & .panta-select__single-value {
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
        }

        & .panta-select__placeholder {
            font-style: italic;
        }
        
        & .panta-select__indicators {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: var(--backgroundSelectDropdownIndicator);
            border-top-right-radius: 0.3125rem;
            border-bottom-right-radius: 0.3125rem;
            color: var(--colorTextDarkBg);
            width: 2.5rem;
            margin: -0.0625rem -0.0625rem -0.0625rem 0;

            & .panta-select__indicator-separator {
                display: none;
            }

            & .panta-select__clear-indicator {
                position: absolute;
                right: 2.5rem;
            }
        }

        & .panta-select__menu {
            border-radius: 0.3125rem;
            border: 0.0625rem solid var(--borderColorSelect);
            z-index: 10;
        }

        & .panta-select__option {
            font-size: 0.75rem;font-size: 0.75rem;
            line-height: 0.9375rem;
            cursor: pointer;
            font-weight: var(--font-weight-heavy);
            color: var(--colorSelectOption);
            padding: 0.5rem 1.25rem 0.5rem 1.25rem;
            transition: border-left 0.2s ease-in-out, padding 0.2s ease-in-out;
            border-left: 0 solid
            var(--colorLeftSelectOptionSelected);
            &:hover,
            &:focus,
            &.panta-select__option--is-focused,
            &:active {
                background-color: var(--backgroundSelectOptionHover);
            }
            &.panta-select__option--is-selected {
                padding: 0.5rem 1.25rem 0.5rem 0.875rem;
                background-color: var(--backgroundSelectOptionHover);
                border-left: 0.375rem solid
                var(--colorLeftSelectOptionSelected);
            }
        }

        & .panta-select__menu-notice {
            font-weight: var(--font-weight-base);
            font-size: 0.875rem;
        }

        /* InputSelect widget style theme */
        &.widget {
            & .panta-select__control {
                min-height: 1.5rem;
                border: none;
                background-color: transparent;
            }
            & .panta-select__value-container {
                display: flex !important;
                font-size: 1.5rem;
                min-width: 7.5rem;
                margin-left: 0;
                padding: 0;
                font-weight: var(--font-weight-heavy);

                & .panta-select__single-value {
                    color: var(--colorSelectWidgetText);
                    line-height: 1.2;
                }
                & .panta-select__placeholder {
                    font-style: normal;
                    color: var(--colorSelectWidgetText);
                    font-weight: var(--font-weight-heavy);
                    white-space: nowrap;
                }
            }
            & .panta-select__indicators {
                background-color: var(--backgroundSelectDropdownIndicatorWidget);
                border-radius: 50%;
                color: var(--backgroundSelectDropdownIndicatorWidgetIcon);
                width: 1.5rem;
                height: 1.5rem;
                margin: auto 0 auto 0.375rem;
                & .panicon {
                    font-size: 0.75rem;
                }
            }
        }
        @media (min-width: $sm) {
        &.widget {
            & .panta-select__value-container {
                font-size: 1.875rem;
            }
        }
    }

        /* IE specific fix */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            & .panta-select__indicators {
                min-height: 2.5rem;
            }
        }
    }

    &__multi-select-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 2.1875rem;
        padding: 0 1.25rem;

        &:first-child {
            margin-top: 0.3125rem;
        }
        &:last-child {
            margin-bottom: 0.3125rem;
        }
        & label {
            font-weight: 700;
            color: var(--blue);
            pointer-events: none;
        }

        &.panta-select__option--is-selected {
            padding: 0 1.25rem !important;
            background-color: var(--offwhite) !important;
            border-left: 0 solid
                var(--brightsun)!important;
        }

        &:hover,
        &:focus,
        &.panta-select__option--is-focused,
        &:active {
            background-color: var(--lightblue) !important;
        }
    }

    &__multi-placeholder {
        &.widget {
            color: var(--ceramicwhite);
        }
    }

    &__empty-message {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-style: italic;
        height: 3.125rem;
    }
}