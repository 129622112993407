﻿.input-checkbox {
  display: flex;
  font-size: 0.75rem;
  line-height: 20px;
  position: relative;
  padding-left: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    background: var(--backgroundCheckbox);
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--borderCheckbox);
    transition: all 0.1s ease-in-out 0s;

    &:before {
      visibility: hidden;
      position: absolute;
      left: 4px;
      top: 5px;
      text-align: center;
      color: var(--colorCheckmarkIcon);
      font-size: 11px;
      transition: all ease-in-out 0.1s;
    }
  }

  &:hover input {
    ~ .input-checkbox__checkmark {
      background-color: var(--backgroundCheckedCheckbox)
    }
  }

  input {
    &:disabled ~ .input-checkbox__checkmark {
      background-color: var(--backgroundDisabledCheckbox);
      opacity: 0.5;
    }

    &:focus-visible ~ .input-checkbox__checkmark {
      outline: 0.125rem solid var(--skyblue);
    }

    &:checked ~ .input-checkbox__checkmark {
      background-color: var(--backgroundCheckedCheckbox);
      border-color: var(--backgroundCheckedCheckbox);

      &:before {
        visibility: visible;
      }
    }
  }
}