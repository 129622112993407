.order-summary {
  &__header {
    @include h4;
  }

  .deposit-label-group {
    background-color: var(--depositLabelSummaryImageBgColor);
    border-radius: 0.9375rem;
    padding: 1.25rem;
    margin-bottom: 1.25rem;

    div:last-of-type {
      margin-bottom: 0;
    }

    &__image-and-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.625rem;
    }

    &__group-name {
      @include h5;
      margin-top: 1rem;
    }

    &__deposit-label-image {
      height: 100%;

      div {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }
    }

    &__order-summary {
      margin-bottom: 2rem;
      opacity: 1;
      transition: opacity 0.5s;

      &.editing {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }
  
  &__price {
    margin-top: 1rem;
    padding-top: 2rem;
    border-top: 0.0625rem solid var(--borderColorBase);
    
    .price {
      margin-bottom: 0.5rem;
      text-align: right;
    }
    
    .shipping-price-info {
      text-align: right;
      font-style: italic;
      margin-bottom: 0.5rem;
    }
  }
}