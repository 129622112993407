.bag-labels-block {
  //Variables in baglabels block
  --items-per-row-xs: 2;
  --items-per-row-sm: 3;
  --items-per-row-lg: 2;
  --items-per-row-xl: 2;

  position: relative;
  height: 100%;

  .panel .panel {
    height: auto !important;

    & > .panel__content {
      padding: 1.25rem;
    }
  }

  &__heading {
    font-size: 1.125rem;
    color: var(--colorWidgetText);
    margin-bottom: 0.375rem;
  }

  &__sub-heading {
    min-height: 0.0625rem;
    margin-bottom: 1.25rem;
    font-size: 1.875rem;
    font-weight: var(--font-weight-heavy);
    color: var(--colorWidgetText);
  }

  .create-order-panel-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media(min-width: $sm) {
      flex-direction: row;
    }

    @media(min-width: $lg) {
      flex-direction: column;
    }

    @media(min-width: $xl) {
      flex-direction: row;
    }

    &__nr-of-bag-labels {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.125rem;
      margin-bottom: 0.625rem;

      & .panicon {
        color: var(--secondary);
        margin-right: 0.3125rem;
      }

      @media(min-width: $sm) {
        margin-bottom: 0;
      }

      @media(min-width: $lg) {
        margin-bottom: 0.625rem;
      }

      @media(min-width: $xl) {
        margin-bottom: 0;
      }
    }

    &__bag-count {
      margin: 0 0.3125rem;
      font-weight: var(--font-weight-heavy);
    }
  }

  &__latest-order-heading {
    @include h5;
    color: var(--colorWidgetText);
    margin-top: 1.25rem;
  }

  .latest-order-panel-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__order-detail-item {
      display: flex;
      flex-direction: column;
      width: calc((100% - var(--items-per-row-xs) * 0.3125rem) / var(--items-per-row-xs));
      margin-right: 0.3125rem;

      &:first-child {
        margin-bottom: 0.9375rem;
      }

      &:last-child {
        margin-top: 0.9375rem;
      }

      & label {
        font-weight: var(--font-weight-heavy);
        font-size: 0.75rem;
        margin-bottom: 0.1875rem;
      }

      & span {
        @include small;
      }

      @media(min-width: $sm) {
        width: calc((100% - var(--items-per-row-sm) * 0.3125rem) / var(--items-per-row-sm));
        &:last-child {
          margin-top: 0;
        }
      }

      @media(min-width: $lg) {
        width: calc((100% - var(--items-per-row-lg) * 0.3125rem) / var(--items-per-row-lg));
        &:last-child {
          margin-top: 0.9375rem;
        }
      }

      @media(min-width: $xl) {
        width: calc((100% - var(--items-per-row-xl) * 0.3125rem) / var(--items-per-row-xl));
      }
    }
  }
}