@keyframes slideInLeft {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}