.map-wrapper {
    & .cluster {
        background-color: var(--backgroundColorMapCluster);
        border-radius: 50%;

        & > div {
            color: var(--textColorMapCluster) !important;
            font-size: 1rem !important;
            font-weight:var(--font-weight-heavy) !important;
            font-family: var(--font-family-base) !important;
        }
    }

    & .pexmap-container .gm-style .gm-style-pbc {
        pointer-events: none;
    }

    /* IE specific fix */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        & .pexmap-container {
            & div[style*='width: 30px; height: 36px;'] {
                opacity: 1 !important;
                & img[src^='data:image/svg'] {
                    width: auto !important;
                    height: auto !important;
                }
            }
        }
    }

    & .gm-style-iw-t {
        &:after {
            content: none;
        }
    }

    & .gm-style-iw {
        width: 18.75rem;
        max-width: 18.75rem!important;
        border-radius: 0.9375rem;
        overflow: visible !important;

        & > button {
            display: none!important;
        }
    }
    @media(min-width: $sm) {
        & .gm-style-iw {
            width: 28.125rem;
            max-width: 28.125rem!important;
        }
    }

    @media(min-width: $md) {
        & .gm-style-iw {
            width: 45rem;
            max-width: 45rem!important;
        }
    }
}