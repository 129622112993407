.link {
    display: inline-flex;
    position: relative;

    & span {
        position: relative;
        font-weight: var(--font-weight-heavy);
        font-size: 0.875rem;
        color: var(--colorLinkBase);
        text-decoration: none;
        border-bottom: 0.125rem solid var(--colorLinkBorder);
        transition: border-bottom 0.5s;
        cursor: pointer;

        &:hover {
            border-bottom: 0.125rem solid
                var(--colorLinkBorderHover);
        }

        &.loading {
            opacity: 0.5;
            pointer-events: none;
        }
        
        .panicon {
            border-bottom: none;
        }

        /* LinkTheme.DarkMode */
        &.darkmode, &.darkmode span {
            color: var(--colorLinkDarkBg);
            &:hover {
                border-bottom: 0.125rem solid
                    var(--colorLinkDarkBg);
            }
        }
    }
}

.inline-link {
    &:after {
        content: '';
        position: absolute;
        bottom: -0.075rem;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-color: var(--colorLinkBorder);
        transition: background-color 0.5s;
    }

    &:hover:after {
        background-color: var(--colorLinkBorderHover);
    }
    
    /* LinkTheme.DarkMode */
    &.darkmode {
        color: var(--colorLinkDarkBg);

        &:after {
            background-color: var(--colorLinkDarkBg);
        }

        &:hover:after {
            background-color: var(--colorLinkDarkBg);
        }
    }
}