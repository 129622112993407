@mixin expand-on-visible-style {
  max-height: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, max-height 0.2s ease-in-out;

  &.visible {
    opacity: 1;
    max-height: 12.5rem;
  }
}

.register-flow-step-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 62.5rem;

  & .deposit-code-panel {
    border-radius: 0.3125rem;
    padding: 0.9375rem;
    text-align: center;
    color: var(--white);
  }

  .register-field {
    position: relative;
    margin-top: 3.75rem;
    width: 100%;
    @media(min-width: $md) {
      width: 43.75rem;
    }

    &__loading-overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.85);
      z-index: 1;
    }

    &__field {
      margin-bottom: 1.25rem;
    }

    &__ean {
      margin-bottom: 1.25rem;

      & .ean-info-panel {
        font-size: 0.75rem;
        line-height: 1.5;
        padding: 0.9375rem;
        border-radius: 0.3125rem;
        color: var(--white);
      }

      & .ean-field {
        margin-bottom: 0.375rem;
      }
    }

    &__material {
      margin-bottom: 1.25rem;
      @media(min-width: $md) {
        & #material > div {
          flex: 0 1 var(--material-width);
        }
      }
    }

    &__color {
      margin-bottom: 1.25rem;
      @include expand-on-visible-style;

      @media(min-width: $md) {
        & #color > div {
          flex: 0 1 var(--color-width);
        }
      }
    }

    &__volume {
      margin-bottom: 1.25rem;
      @include expand-on-visible-style;

      @media(min-width: $md) {
        & #volume > div {
          flex: 0 1 var(--volume-width);
        }
      }
    }

    &__volume-other {
      margin-bottom: 1.25rem;
      @include expand-on-visible-style;
    }
  }

  .deposit-code-panel {
    &__label {
      @include small;
      font-weight: var(--font-weight-heavy);
      margin-right: 0.3125rem;
    }

    &__deposit-code {
      @include small;
      line-height: 1.5;
    }
  }

  &__error-messages {
    width: 100%;
    margin: 1.25rem 0 0.625rem 0;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .next-button {
      margin-top: 0.625rem;
      margin-bottom: 1.875rem;

      & button {
        @include button-style-lg;
        width: 17.1875rem;
      }
    }

    .save-button {
      margin-bottom: 0.9375rem;

      & button {
        @include button-style-lg;
        width: 17.1875rem;
      }
    }

    .cancel-button {
      button {
        width: 5.625rem;
      }
    }
  }

  &__cancel-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
      margin-left: 0;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }

  &__next-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .panicon {
      margin-right: 0;
      margin-left: 0.3125rem;
    }

    & > span:first-child {
      line-height: 0.75rem;
    }
  }
}