﻿.header {
  position: relative;
  display: flex;
  flex-direction: column;

  &__unsupported-browser {
    background-color: var(--backgroundUnsupportedBrowser);
    text-align: center;
    padding: 3.75rem 1.5625rem;

    button {
      margin-top: 1rem;
    }

    .panicon {
      display: inline-block;
    }
  }
  
  .js-hero-block {
    order: 2;
    @media(min-width: $lg) {
      order: 1;
    }
  }
  
  .breadcrumbs {
    order: 1;
    @media(min-width: $lg) {
      order: 2;
    }
  }

  &__header-inner {
    position: fixed;
    top: 0;
    width: 100%;
    height: 3.75rem;
    z-index: 1;

    @media(min-width: $xl) {
      position: relative;
      width: auto;
      min-height: 8.75rem;
    }

    &.header-sticky-ready {
      .brand-logo {
        opacity: 1;
        cursor: pointer;
        pointer-events: auto;
        margin: 0 1rem;
      }

      .h-top-container {
        opacity: 0;
      }

      .h-bottom {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
      }

      &--hide {
        .h-bottom {
          transform: translateY(-100%);
        }
      }

      &--animate {
        .h-bottom {
          transition: transform 0.25s ease-in-out;
        }
      }

      .header-bottom__nav-right {
        visibility: visible !important;
        display: block !important;
        pointer-events: auto !important;
        opacity: 1 !important;
        animation: slideInLeft 0.4s normal forwards !important;
      }

      .hidden {
        display: none !important;
      }

      .mega-menu-dropdown {
        top: 3.75rem;
      }
    }

    &.header-sticky--default {
      min-height: 3.75rem;

      .brand-logo-small {
        opacity: 1;
      }

      .header-bottom__nav-right {
        transform: translateX(0%);
        animation: none;
      }
    }
  }
}  
