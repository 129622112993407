.search-wrapper {
  position: relative;

  .input-text input {
    height: 3.125rem;
    padding-left: 2.5rem;
    border-radius: 2.1875rem;
    font-size: 1rem;
    transition: border-radius 0.25s;
    outline: none;

    &::placeholder {
      font-style: normal;
      font-weight: 700;
      color: var(--searchResultsInputTextColor);
    }

    @media(min-width: $md) {
      height: 3.75rem;
      padding-left: 3.125rem;
    }

    &--deafult-theme {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-text__wrapper {
    &::before {
      border-radius: 2.1875rem;

      &--default-theme {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &__icon-container {
    position: absolute;
    top: 1.1rem;
    left: 1.25rem;
    z-index: 1;

    @media(min-width: $md) {
      top: 1.3rem;
      left: 1.25rem;
    }

    & .panicon {
      font-size: 1rem !important;

      @media(min-width: $md) {
        font-size: 1.25rem !important;
      }
    }
  }

  &__recommendations-container {
    opacity: 0;
    max-height: 0;
    overflow: hidden;

    &.hasRecommendations {
      max-height: 18.75rem;
      opacity: 1;
      overflow: visible;
      padding: 0.9375rem 0.625rem 0.625rem 0.625rem;

      .button {
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
        font-size: 0.625rem;

        @media(min-width: $md) {
          margin-right: 0.375rem;
          font-size: 0.75rem;
        }
      }

      @media(min-width: $md) {
        padding: 1.5625rem 0.625rem 0.625rem 0.625rem;
      }

      & > span {
        display: block;
        margin-bottom: 0.9375rem;
        font-weight: 700;
        font-size: 0.625rem;

        @media(min-width: $md) {
          font-size: 0.75rem;
        }

        &.dark-mode {
          color: var(--searchRecommendationsHeaderColorLight)
        }
      }
    }
  }

  &__result-container {
    max-height: 0rem;
    background-color: var(--searchResultsBgColor);
    opacity: 0;
    visibility: hidden;
    text-align: center;
    transform: translateY(-3.75rem);
    z-index: 1;
    transition: none;
    overflow: hidden;

    &--default-theme {
      border: 0.0625rem solid var(--searchBorderColor)
    }

    &--show-results {
      max-height: none;
      padding: 0.0625rem 0.9375rem 0.9375rem 0.9375rem;
      overflow: visible;
      border-bottom-left-radius: 1.875rem;
      border-bottom-right-radius: 1.875rem;
      opacity: 1;
      visibility: visible;
      transform: translateY(0.25rem);
      transition: transform 0.5s, opacity 0.25s;

      @media(min-width: $md) {
        padding: 0.0625rem 1.875rem 1.875rem 1.875rem;
      }
    }
    
    .link {
      display: block;
    }

    .link span {
      display: block;
      margin-bottom: 0.5625rem;
      color: var(--searchResultLinkColor);
      font-size: 0.875rem;
      text-align: left;
      border-bottom: none;

      &:hover {
        color: var(--searchResultLinkColor);
        border-bottom: none;
      }

      @media(min-width: $md) {
        font-size: 1.125rem;
      }
    }

    .button {
      margin-top: 1.5625rem;
      font-size: 0.625rem;

      @media(min-width: $md) {
        @include button-style-lg;
      }
    }
  }

  &__result-header {
    font-weight: 700;
    margin-top: 1.25rem;
    margin-bottom: 0.9375rem;
    padding-bottom: 0.625rem;
    color: var(--searchResultHeaderTextColor);
    border-bottom: 0.0625rem solid var(--searchBorderColor);
    font-size: 0.625rem;
    text-align: left;

    @media(min-width: $md) {
      margin-top: 1.875rem;
      font-size: 0.875rem;
    }
  }
}
