@font-face {
  font-family: 'PanIcons';
  src: url('../fonts/PanIcons/PAN-Iconset.eot');
  src: url('../fonts/PanIcons/PAN-Iconset.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PanIcons/PAN-Iconset.woff') format('woff'),
  url('../fonts/PanIcons/PAN-Iconset.ttf') format('truetype'),
  url('../fonts/PanIcons/PAN-Iconset.svg#PAN-Iconset') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// PanIcons

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'PanIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  text-align: center;
  display: block;
  font-size: 12px;
  min-width: 12px;
  
  &.inline {
    display: inline-block;
  }

  &.bgColor {
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding-top: calc(12px / 2.05);
    transform: translateY(-1px);
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-thumb_down:before {
  content: "\e982";
}

.icon-thumb_up:before {
  content: "\e983";
}

.icon-facility:before {
  content: "\e980";
}

.icon-floorball:before {
  content: "\e981";
}

.icon-pantbag_x3:before {
  content: "\e97f";
}

.icon-inte_pantas:before {
  content: "\e97d";
}

.icon-saft_juice:before {
  content: "\e97e";
}

.icon-paus:before {
  content: "\e97c";
}

.icon-headset:before {
  content: "\e97a";
}

.icon-paper-plane:before {
  content: "\e97b";
}

.icon-user-management:before {
  content: "\e943";
}

.icon-business-info:before {
  content: "\e944";
}

.icon-diploma:before {
  content: "\e945";
}

.icon-faq:before {
  content: "\e948";
}

.icon-bag:before {
  content: "\e949";
}

.icon-bird:before {
  content: "\e94a";
}

.icon-bowling:before {
  content: "\e94b";
}

.icon-bubbles:before {
  content: "\e94c";
}

.icon-cake:before {
  content: "\e94d";
}

.icon-camera:before {
  content: "\e94e";
}

.icon-can-tap:before {
  content: "\e94f";
}

.icon-can:before {
  content: "\e950";
}

.icon-car:before {
  content: "\e951";
}

.icon-cloud-sun:before {
  content: "\e952";
}

.icon-coin:before {
  content: "\e953";
}

.icon-container-double:before {
  content: "\e954";
}

.icon-container-smal:before {
  content: "\e955";
}

.icon-container-store:before {
  content: "\e956";
}

.icon-container-wellpapp:before {
  content: "\e957";
}

.icon-container:before {
  content: "\e958";
}

.icon-document:before {
  content: "\e959";
}

.icon-flower:before {
  content: "\e95a";
}

.icon-football:before {
  content: "\e95b";
}

.icon-globe-2:before {
  content: "\e95c";
}

.icon-guitar:before {
  content: "\e95d";
}

.icon-headphones:before {
  content: "\e95e";
}

.icon-heart-arrows:before {
  content: "\e95f";
}

.icon-heart:before {
  content: "\e960";
}

.icon-hockey:before {
  content: "\e961";
}

.icon-igloo:before {
  content: "\e962";
}

.icon-juice:before {
  content: "\e963";
}

.icon-laptop:before {
  content: "\e964";
}

.icon-megaphone:before {
  content: "\e965";
}

.icon-moose:before {
  content: "\e966";
}

.icon-pallet:before {
  content: "\e967";
}

.icon-paypal:before {
  content: "\e968";
}

.icon-pencil:before {
  content: "\e969";
}

.icon-pex:before {
  content: "\e96a";
}

.icon-phone:before {
  content: "\e96b";
}

.icon-receipt:before {
  content: "\e96c";
}

.icon-sailboat:before {
  content: "\e96d";
}

.icon-santa:before {
  content: "\e96e";
}

.icon-shower:before {
  content: "\e96f";
}

.icon-skate:before {
  content: "\e970";
}

.icon-snow-flake:before {
  content: "\e971";
}

.icon-star:before {
  content: "\e972";
}

.icon-stock:before {
  content: "\e973";
}

.icon-tag:before {
  content: "\e974";
}

.icon-tennis:before {
  content: "\e975";
}

.icon-tones:before {
  content: "\e976";
}

.icon-tree:before {
  content: "\e977";
}

.icon-tv:before {
  content: "\e978";
}

.icon-office-chair:before {
  content: "\e979";
}

.icon-truck:before {
  content: "\e936";
}

.icon-pin:before {
  content: "\e937";
}

.icon-apple:before {
  content: "\e938";
}

.icon-pantautomat:before {
  content: "\e939";
}

.icon-park:before {
  content: "\e93a";
}

.icon-amplifier:before {
  content: "\e93b";
}

.icon-book:before {
  content: "\e93c";
}

.icon-knife-fork:before {
  content: "\e93d";
}

.icon-store:before {
  content: "\e93f";
}

.icon-tent:before {
  content: "\e940";
}

.icon-skies:before {
  content: "\e941";
}

.icon-smile:before {
  content: "\e942";
}

.icon-dropdown:before {
  content: "\e935";
}

.icon-play-outlined:before {
  content: "\e902";
}

.icon-play:before {
  content: "\e915";
}

.icon-info-i:before {
  content: "\e932";
}

.icon-download:before {
  content: "\e947";
}

.icon-shopping-cart:before {
  content: "\e930";
}

.icon-pdf:before {
  content: "\e931";
  color: var(--white);
}

.icon-excel:before {
  content: "\e93e";
  color: var(--white);
}

.icon-print:before {
  content: "\e946";
}

.icon-lock:before {
  content: "\e92f";
}

.icon-outline-dots:before {
  content: "\e923";
}

.icon-outline-question:before {
  content: "\e924";
}

.icon-outline-minus:before {
  content: "\e925";
}

.icon-outline-plus:before {
  content: "\e926";
}

.icon-outline-cross:before {
  content: "\e927";
}

.icon-outline-check:before {
  content: "\e928";
}

.icon-cirlce-dots:before {
  content: "\e929";
}

.icon-cirlce-question:before {
  content: "\e92a";
}

.icon-cirlce-minus:before {
  content: "\e92b";
}

.icon-cirlce-plus:before {
  content: "\e92c";
}

.icon-cirlce-cross:before {
  content: "\e91b";
}

.icon-circle-check:before {
  content: "\e92d";
}

.icon-calendar:before {
  content: "\e92e";
}

.icon-arrow-down:before {
  content: "\e908";
}

.icon-arrow-up:before {
  content: "\e920";
}

.icon-home:before {
  content: "\e91f";
}

.icon-close:before {
  content: "\e91c";
}

.icon-gear:before {
  content: "\e91d";
}

.icon-more:before {
  content: "\e91e";
}

.icon-arrow-2-left:before {
  content: "\e913";
}

.icon-arrow-2-right:before {
  content: "\e914";
}

.icon-articles:before {
  content: "\e933";
}

.icon-reporting:before {
  content: "\e916";
}

.icon-invoice:before {
  content: "\e917";
}

.icon-messages:before {
  content: "\e918";
}

.icon-deposit-label:before {
  content: "\e919";
}

.icon-dashboard:before {
  content: "\e91a";
}

.icon-check:before {
  content: "\e921";
}

.icon-pantamera-logo:before {
  content: "\e912";
}

.icon-info:before {
  content: "\e90e";
}

.icon-info-2-color .path1:before {
  content: "\e90f";
  color: rgb(255, 214, 24);
}

.icon-info-2-color .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(0, 105, 180);
}

.icon-warning:before {
  content: "\e911";
}

.icon-logout:before {
  content: "\e90d";
}

.icon-globe:before {
  content: "\e900";
}

.icon-user:before {
  content: "\e901";
}

.icon-search:before {
  content: "\e934";
}

.icon-recycle-symbol:before {
  content: "\e903";
}

.icon-cup:before {
  content: "\e904";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-youtube:before {
  content: "\e984";
  margin-right: -8px;
  margin-left: -7px;
}

.icon-facebook:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e922";
}

.icon-arrow-left:before {
  content: "\e909";
}

.icon-external-link:before {
  content: "\e90a";
}

.icon-bottle-can:before {
  content: "\e90b";
}

.icon-services:before {
  content: "\e90c";
}

