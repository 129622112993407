  .tabs-container {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;

    &__navbar {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 1.25rem;
    }

    &__content {
      width: 100%;
    }
    
    .tab-panel {
      display: none;
    }
    .tab-panel.active {
      display: block;
    }
  }