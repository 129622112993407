.prev-reportings-block {
  position: relative;
  margin-bottom: 0.9375rem;
  padding-right: 1rem;
  padding-left: 1rem;

  &:last-child {
    padding-bottom: 6rem;
  }

  &__report {
    margin-bottom: 0.9375rem;
  }

  &__show-older {
    display: flex;
    justify-content: center;
    margin-top: 1.5625rem;

    & button {
      @include button-style-lg;
    }
  }
}