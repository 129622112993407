.panta-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &__step-indicator {
    position: relative;
    display: flex;
  }
  
  &__step {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 0.9375rem;
  }
  
  &__extra-content {
    position: relative;
    display: flex;
    flex-direction: column;
  }
}