.sales-statement-details {
  margin-left: 1.25rem;
  margin-right: 1.25rem;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $md) {
      flex-direction: row;
    }
  }

  &__cancel-button {
    flex: 1;
  }

  &__header-title {
    flex: 2;
  }

  &__cancel-button-text {
    display: flex;

    & .panicon {
      margin-right: 0.3125rem;
      margin-left: 0;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }

  &__table {
    tbody {
      color: var(--colorTextBase);
    }
    
    .table-wrapper table thead {
      box-shadow: none;
    }

    th {
      &.light {
        background-color: var(--colorDarkTableHeaderBackground);
      }

      &.text-two-rows {
        min-width: 12rem;
        white-space: break-spaces;

        .header-text {
          align-items: end;
        }
      }
    }

    th,
    td {
      padding-left: 1rem;
      padding-right: 1rem;
      white-space: nowrap;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .ean {
      white-space: nowrap;
    }

    .articleDescription {
      white-space: normal;
      @media(min-width: $md) {
        white-space: nowrap;
      }
    }

    .visible-on-mobile {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    $months: jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec;

    @each $month in $months {
      .#{$month}Quantity,
      .#{$month}Weight,
      .#{$month}RecycledPlasticFraction,
      .#{$month}BottleWeight {
        text-align: right;
      }
    }
    @each $month in $months {
      td.table-column.#{$month}Quantity,
      td.table-column.#{$month}Weight,
      td.table-column.#{$month}RecycledPlasticFraction,
      td.table-column.#{$month}BottleWeight {
        padding-right: calc(1.25rem + 17px);
      }
    }

    .weight,
    .salesYear,
    .totalWeight,
    .totalQuantity,
    .recycledPlasticWeight {
      text-align: right;
    }

    td.table-column {
      &.weight,
      &.salesYear,
      &.totalWeight,
      &.totalQuantity,
      &.recycledPlasticWeight {
        padding-right: calc(1.25rem + 17px);
      }
    }

    .mobile-expand-row {
      .content {
        display: flex;
        flex-wrap: wrap;

        .group-heading {
          flex-basis: 100%; // Ensure the heading spans the full width

          // Target the first 4 sibling divs directly after each group-heading
          & + div {
            flex-basis: 50%;
          }

          & + div + div {
            flex-basis: 50%;
          }

          & + div + div + div {
            flex-basis: 50%;
          }

          & + div + div + div + div {
            flex-basis: 50%;
          }
        }

        > div {
          text-transform: capitalize;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(64),
          &:nth-child(65) {
            flex-basis: 50%;
          }

          &:nth-child(64),
          &:nth-child(65) {
            margin-top: 2rem;
            padding-top: 1rem;
            border-top: 0.0625rem solid var(--colorBorderSalesStatementsMobileTable);
          }

          &:nth-child(3) {
            flex-basis: 100%;
          }

          > label {
            text-transform: none;

            &:first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}