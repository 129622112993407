@import "@sass/layout-variables";
.map-search-filter {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.875rem;

    @media(min-width: $lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__input-fields {
        position: relative;
        display: flex;
        flex-direction: column;
        column-gap: 1rem;

        & .map-deposit-options-field {
            width: 100%;
            margin: 0.625rem 0;
        }

        & .panta-select__menu {
            width: 17.5rem;
        }
        & .panta-select__option {
            height: 4.25rem;
            & > div {
                width: 100%;
                & label {
                    width: 100%;
                }
            }
        }
        & .panta-select__menu-list {
            max-height: 25rem;
        }
        
        & .panta-select__value-container {
            display: flex;
        }

        @media(min-width: $sm) {
            flex-direction: row;
            margin-bottom: 0.625rem;
            & .map-deposit-options-field {
                width: 16.25rem;
                margin: 0 0.9375rem;
            }
        }

        @media(min-width: $md) {
            & .panta-select__menu {
                width: 23.625rem;
            }
        }

        @media(min-width: $lg) {
            margin-bottom: 0;
        }
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;

        /* Fix alignment in IE */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin-left: auto;
        }

        & .panicon {
            margin-left: 0.3125rem;
            color: var(--blue);
        }
    }

    &__selection-fade-area {
        z-index: 1;
        position: absolute;
        display: flex;
        align-items: center;
        top: 100%;
        left: 0;
        height: 2.1875rem;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0 1.875rem;

        & span:first-child {
            font-size: 0.75rem;
            font-weight: var(--font-weight-heavy);
            margin-right: 0.3125rem;
        }
        & span:not(:first-child) {
            font-size: 0.75rem;
            font-weight: var(--font-weight-medium);
        }
    }

}