.tab {
  position: relative;
  color: var(--colorTextSecondary);
  cursor: pointer;
  font-size: 0.9375rem;
  font-weight: var(--font-weight-heavy);
  margin-right: 1.25rem;
  opacity: 0.3;
  padding-bottom: 0.625rem;
  transition: opacity 0.2s ease-in-out;
  background: transparent;
  border: 0;

  @include focusStyle;

  &:has(.panicon) {
    &:focus::before {
      width: calc(100% + 1.1rem);
    }
  }
  &.active {
    opacity: 1;
    border-bottom: 0.125rem solid var(--colorTextSecondary);
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.1;
  }
  @media (min-width: $md) {
  font-size: 1.3125rem;
}

  .panicon {
    position: absolute;
    right: -1rem;
    top: 0;
  }
}