﻿@import '../../../../../Frontend/scss/layout-variables';

.external-video-wrapper {
  width: 100%;

  .panel__content {
    overflow: hidden;
    padding: 0 !important;
  }

  .external-video {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
    background-size: cover;
    background-position: center center;

    & .cookieconsent-optout-marketing {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 5rem;
      margin-top: 0;
    }
    
    .cookiebot-renew-link {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    &__button-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      pointer-events: none;
    }

    &__button {
      width: 3.75rem;
      height: 3.75rem;
      padding-top: 1rem;
      padding-left: 0.25rem;
      background-color: rgba(var(--backgroundColorExternalVideoPlayButton), 0.8);
      border-radius: 50%;
      outline: none;
      text-align: center;
      opacity: 1;
      pointer-events: all;
      transform: scale(1);
      transition: transform 0.25s, opacity 0.25s;

      .panicon:last-child {
        display: none !important;
      }

      @media (min-width: $md) {
        width: 10rem;
        height: 10rem;
        padding-top: 3rem;
        padding-left: 0.625rem;

        .panicon:first-child {
          display: none !important;
        }

        .panicon:last-child {
          display: block !important;
        }
      }

      &:hover,
      &:focus {
        cursor: pointer;
        transform: scale(1.1);
      }

      &:focus {
        border: 0.125rem solid var(--focusBorderExternalVideoPlayButton);
      }

      &--hidden {
        transform: scale(0.5) !important;
        opacity: 0;
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__title {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: var(--backgroundColorExternalVideoTitle);
      color: var(--textColorExternalVideoTitle);
      font-weight: 700;
      padding: 0.75rem 1.25rem 0.625rem 0.75rem;
      border-top-right-radius: 0.9375rem;
      font-size: 0.625rem;
      transition: transform 0.5s;
      transition-delay: 0.75s;

      @media (min-width: $md) {
        padding: 0.875rem 1.625rem 0.75rem 1.125rem;
        font-size: 0.875rem;
      }

      &--hidden {
        transform: translateX(-100%);
      }
    }
  }
}