@mixin table-column-mobile-breakpoint {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  .button--expandable {
    margin-right: 0;
    display: none;
  }
  &:not(.visible-on-mobile) {
    display: table-cell;
  }
}

@mixin table-head-mobile-breakpoint {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  &:first-child {
    padding-left: 1.25rem;
  }
  &.first-column:not(:first-child) {
    padding-left: 1.25rem;
    border-top-left-radius: 0;
  }
  &.last-column:not(:last-child) {
    border-top-right-radius: 0;
  }
  &:not(.visible-on-mobile) {
    display: table-cell;
  }
}

@mixin table-body-mobile-breakpoint {
  &.last-row:not(.mobile-expand-row) {
    & td:first-child {
      border-bottom-left-radius: 0.9375rem;
    }
    & td:last-child {
      border-bottom-right-radius: 0.9375rem;
    }
    & td.first-column:not(:first-child) {
      border-bottom-left-radius: 0;
    }
    & td.last-column:not(:last-child) {
      border-bottom-right-radius: 0;
    }
  }
}

@mixin widget-table {
  & thead {
    font-size: 0.625rem !important;
  }
  & tbody td {
    font-size: 0.75rem !important;
  }
  & th {
    padding-left: 0.9375rem !important;
    padding-right: 0.9375rem !important;
    &:not(:first-child) {
      padding-left: 0.3125rem !important;
    }
    &:not(:last-child) {
      padding-right: 0.625rem !important;
    }
  }
  & td {
    padding: 0 0.9375rem !important;
    &:not(:first-child) {
      padding-left: 0.3125rem !important;
    }
    &:not(:last-child) {
      padding-right: 0.625rem !important;
    }
  }
}