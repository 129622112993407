﻿.news-container {
  &__title {
    margin-bottom: 1.875rem;

    &--sm {
      height: 1.625rem;
      border-bottom: 0.063rem solid var(--borderColorBase);
      font-weight: var(--font-weight-heavy);
      font-size: 1.125rem;
    }

    &--lg {
      border-bottom: 0.0625rem solid var(--borderColorBase);
      padding-bottom: 1.125rem;
      margin-top: 1.875rem;
      display: flex;
      align-items: flex-end;

      h2 {
        margin: 0 1.25rem 0 0;
      }
    }
    
    .button-link-block {
      margin-bottom: 0;
      width: auto;
    }
  }

  &__wrapper {
    min-height: 16.25rem;

    & .linkbadge-root {
      margin-bottom: 1.25rem;
    }
  }
}