.donut {
  position: relative;
  max-width: 100%;
  text-align: center;

  img {
    position: absolute;
    top: calc(50% - 1.875rem);
    right: calc(50% - 1.25rem);
    height: 3.75rem;
    width: 2.5rem;
    animation: initPetCan 1s;
  }

  svg {
    max-height: 17.1875rem;
    max-width: 100%;
    animation: initDonut 1s;
    transform: scale(1) rotate(-90deg);
  }

  circle.donut-ring {
    stroke: var(--monthlyStatsPetColor);
  }

  circle.donut-segment {
    stroke: var(--monthlyStatsCanColor);
  }

  @keyframes initDonut {
    0% {
      opacity: 0.5;
      transform: scale(0) rotate(-270deg);
    }

    100% {
      opacity: 1;
      transform: scale(1) rotate(-90deg);
    }
  }

  @keyframes initPetCan {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}