.pickups-widget {
  height: 100%;

  .day-container {
    margin-top: 7.5rem;
  }

  &__next-pickup {
    position: relative;
    z-index: 0;
  }

  &__equipment {
    position: absolute;
    bottom: 1.5625rem;
    right: 0.9375rem;

    @media(min-width: $md) {
      right: 1.875rem;
    }

    .future-pickups-equipment__n-bins {
      color: var(--nBinsColorDashboard);
    }
  }
}