:root {
    --max-full-width-text: 900px;
    --max-full-width-text-wide: 1370px;
    --zIndexGoToContent: 2060;
    
    --option-border-radius: 0.3125rem;
    --items-per-row-when-many: 7;

    --hover-expand-size: 0.1875rem;
    --hover-expand-size-lg: 0.3125rem;
    
    --option-width: 2.5rem;

    --helpWidthSm: 100%;
    --helpWidthLg: 31.25rem;
    --helpScrollFadeHeight: 6.25rem;
}