.report-modal-content {
  &__modal-header {
    @include h5;
  }

  &__outer-table {
    position: relative;
  }

  &__table {
    position: relative;
    margin: 1.25rem 0;
    max-height: 50vh;
    overflow-y: auto;
    padding: 0.125rem 0.125rem 5.125rem 0.125rem;

    & .amount {
      text-align: right;
    }

    & tbody td.amount {
      font-weight: var(--font-weight-heavy);
      min-width: 6.875rem;
      white-space: nowrap;
    }

    & th.previousYearAmount {
      max-width: 7rem;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .edit-report-button {
      width: 100%;

      & button {
        @include button-style-lg;
        width: 100%;
      }
    }

    @media(min-width: $md) {
      flex-direction: row;

      & .edit-report-button {
        width: auto;
        margin-left: auto;
        align-self: flex-start;

        & button {
          width: auto;
          margin-left: auto;
        }
      }
    }
  }

  &__badge {
    width: 100%;
    margin-bottom: 0.9375rem;

    @media(min-width: $md) {
      width: auto;
      min-height: 3.75rem;
      margin-right: 0.625rem;
      margin-bottom: 0;
    }
    @media(min-width: $lg) {
      width: 17.5rem;
    }
  }
}