a {
  @include a;
}

a.button--pantamera-primary,
a.button--pantamera-secondary
{
  border-bottom: none;
    &:hover {
      border-bottom: none;
    }
}

a:not([href], .button) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}