.general-message {
  padding: 0.625rem;
  text-align: center;
  display: flex;
  justify-content: center;

  &--info {
    background-color: var(--backgroundInfo);
  }

  &--error {
    background-color: var(--backgroundDanger);

    .panicon {
      color: var(--white);
      font-size: 20px;
      min-width: 20px;
    }
  }
  
  .text {
    font-size: 0.875rem;
    margin-left: 0.4rem;
    line-height: 1rem;
    
    &--info {
      color: var(--colorTextSecondary);
    }
    
    &--error {
      color: var(--colorTextDarkBg);
    }
  }
}