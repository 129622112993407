.hero-bg-video {
  position: relative;
  width: 100%;
  height: 45vw;
  overflow: hidden;
  mask-image: url("../images/wave-mask.svg");
  mask-position: left bottom;
  mask-size: cover;

  &.ratio25 {
    height: 25vw;
  }

  .hero_vid_ctrls {
    display: none;

    @media(min-width: $md) {
      display: block;
      bottom: 3.4375rem;
    }

    @media(min-width: $lg) {
      bottom: 6.25rem;
    }
  }
}