.button {
  &--expandable {
    min-height: 0;
    height: 1.5625rem;
    width: 1.5625rem;
    border-radius: 50%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    border: none;
    cursor: pointer;

    @include focusStyle;
    &:focus::before {
      border-radius: 50%;
    }

    &.menu-level-1 {
      background-color: var(--backgroundMenuExpandLevel1);
    }
    &.menu-level-2 {
      background-color: var(--backgroundMenuExpandLevel2);
    }
    &.menu-level-1.expanded {
      background-color: var(--backgroundMenuExpandLevel1Expanded);
    }
    &.menu-level-2.expanded {
      background-color: var(--backgroundMenuExpandLevel2Expanded);
    }
    &.menu-level-1.active {
      background-color: var(--backgroundMenuExpandActive);
    }
    &.menu-level-2.active {
      background-color: var(--backgroundMenuExpandActive);
    }
    &.primary {
      background-color: var(--backgroundExpandPrimary);
      display: inline-flex;
    }
  }
  
  .expandable {
    &__icon {
      height: 0.85rem;
      width: 0.2rem;
      transform: rotate(90deg);
      background-color: var(--backgroundMenuExpandIcon);
      
      &.menu-level-2 {
        background-color: var(--backgroundMenuLevel2);
        &:after {
          background-color: var(--backgroundMenuLevel2);
        }
      }
      &.menu-level-1.expanded {
        background-color: var(--backgroundMenuLinkHover);
      }

      &:after {
        height: 0.85rem;
        width: 0.2rem;
        transform: rotate(90deg);
        background-color: var(--backgroundMenuExpandIcon);
        position: absolute;
        right: 0;
        content: '';
      }

      &.primary {
        width: 0.125rem;
        border-radius: 0.125rem;
        background-color: var(--backgroundExpandIconPrimary);
        &:after {
          width: 0.125rem;
          border-radius: 0.125rem;
          background-color: var(--backgroundExpandIconPrimary);
        }
      }

      &.expanded:after {
        height: 0;
      }
    }
  }
  
}