.flyers-cancel-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & .cancel-modal-close-button {
    margin-top: 1.25rem;

    & button {
      @include button-style-lg;
    }
  }

  & .cancel-modal-button-lg {
    & button {
      @include button-style-lg;
    }
  }

  &__button {
    margin-top: 0.9375rem;
  }
}