.company-information-general {
  &__icon {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  &__button {
    text-align: right;
    margin-right: 1rem;
    margin-top: 1rem;

    @media(min-width: $sm) {
      margin-right: 1.5rem;
    }

    @media(min-width: $lg) {
      margin-right: 2.5rem;
    }

    @media(min-width: $xl) {
      margin-right: 3.3rem;
    }
  }
}