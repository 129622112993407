.report-flow-step-1 {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: $md) {
      flex-direction: row;
    }
  }

  &__cancel-button {
    flex: 1;
  }

  &__cancel-button-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }

  &__header-title {
    flex: 2;
  }

  &__table {
    & .amount,
    .prevAmount,
    .previousYearAmount,
    .reportedAmount {
      text-align: right;
    }

    td.table-column {
      &.prevAmount,
      &.previousYearAmount,
      &.reportedAmount {
        padding-right: calc(1.25rem + 0.75rem + 0.3125rem);
      }
    }

    & .input-amount input {
      font-weight: var(--font-weight-heavy);
      font-family: var(--font-family-base);
      text-align: right;
    }

    & th.comment {
      min-width: 10.3rem;
    }

    & th.previousYearAmount {
      max-width: 10rem;
    }

    @media(min-width: $md) {
      overflow-x: scroll;
    }
    @media(min-width: $lg) {
      overflow-x: initial;
    }

    .mobile-expand-row .content {
      .comment {
        &.error,
        &:focus-within,
        &:focus,
        &:active,
        &:focus-visible {
          margin-left: 0.1rem;
          margin-right: 0.1rem;
        }
      }
    }
  }

  &__table-footer {
    margin: 1.25rem;
    float: right;

    & .preview-button {
      & button {
        @include button-style-lg;
      }
    }
  }

  &__error-messages {
    width: 100%;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}