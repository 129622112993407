.panel {
  position: relative;

  &__content {
    position: relative;
    border-radius: 0.9375rem;
    padding: 1.875rem;
    background-color: var(--backgroundColorPanelSecondary);

    &.compact {
      padding: 0.9375rem;
      border-radius: 0.3125rem;
    }

    &.default {
      border: 0.0625rem solid var(--borderPanelDefault);
    }

    &.widget {
      padding: 1.25rem 0.9375rem;
      border: 0.0625rem solid var(--borderPanelWidget);

      @media (min-width: $md) {
        padding: 1.875rem;
      }
    }

    &.primary {
      border: none;
      color: var(--colorTextPanelPrimary);
    }

    &--dark {
      background-color: var(--backgroundColorPanelPrimary);
      @include dark-mode;
    }
    
    /* Indicate that panel contains errors (e.g. if used in form) */
    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border-radius: 0.9375rem;
    }

    &.error::before {
      content: '';
      border: 0.125rem solid var(--outlineErrorTextInput);
    }
  }
}

