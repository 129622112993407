.sales-statement-confirmation-modal {
  &__header {
    @include h5;
  }

  &__modal-body {
    line-height: 1.5;
  }
  
  &__button {
    display: flex;
    justify-content: center;
    gap: 0.625rem;
    margin-top: 1.875rem;
    button {
      @include button-style-lg;
    }
  }
  
  &__error-message {
    display: flex;
    justify-content: center;
    margin-top: 1.875rem;
  }
}