.pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__page-buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.25rem;
  }

  &__page-text {
    font-size: 0.75rem;
  }

  &__button {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border: 0.0625rem solid var(--borderPaginationPage);
    border-radius: 0.3125rem;
    font-size: 1.125rem;
    font-weight: var(--font-weight-heavy);
    color: var(--colorTextSecondary);
    background-color: var(--backgroundPaginationPage);
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    @include focusStyle;

    &:not(:last-child) {
      margin-right: 0.625rem;
    }

    &:not(.selected):hover {
      background-color: var(--backgroundHoverPaginationPage);
    }

    &.selected {
      background-color: var(--backgroundSelectedPaginationPage);
      border: none;
    }
  }
}