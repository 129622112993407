.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0.3125rem);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.15s linear;
  z-index: -1;

  @media(min-width: $lg) {
    padding-right: 1.0625rem;
    padding-left: 1.0625rem;
  }

  &--show {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    z-index: 500;
    
    & .modal__dialog {
      transform: translate(0, 0);
    }
  }

  &__dialog {
    display: block !important;
    position: relative;
    width: auto;
    margin: 0.9375rem;
    transition: transform 0.3s ease-out;
    transform: translate(0, -30%);

    @include focusStyle;

    &:focus::before {
      border-radius: 0.9375rem;
    }

    @media(min-width: $lg) {
      margin: 11.25rem auto;

      &.--xs {
        max-width: 36rem;
      }
      &.--md {
        max-width: 48rem;
      }
      &.--lg {
        max-width: 62.5rem;
      }
      &.--xl {
        max-width: 75rem;
      }
      &.--xxl {
        max-width: 87.5rem;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--backgroundModal);
    background-clip: padding-box;
    border-radius: 0.9375rem;
    outline: 0;
    padding: 2.5rem;

    &--noPadding {
      padding: 0;
      font-weight: normal;
      margin-bottom: 1.25rem;
      line-height: 1.5;
      transform: translateZ(-0.125rem);

      .modal__body {
        margin-bottom: 0 !important;
      }
    }
  }

  &__header {
    margin-bottom: 1.25rem;

    span {
      font-size: 1.3125rem;
      font-weight: 900;
      color: var(--colorModalHeader);
    }
  }

  &__body {
    font-weight: normal;
    margin-bottom: 1.25rem;
    line-height: 1.5;
    transform: translateZ(-0.125rem);
  }

  &__footer {
    margin-bottom: 0.625rem;
  }

  &__close-button {
    position: absolute;
    top: -0.1875rem;
    right: -0.1875rem;
    background-color: var(--backgroundModalCloseButton);
    border-radius: 50%;
    border: 0;
    width: 2.0625rem;
    height: 2.0625rem;
    color: var(--colorModalCloseButton);
    font-weight: 300;
    font-size: 1.875rem;
    line-height: 1;
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    transform-style: preserve-3d;
    transition: background-color 0.2s ease-in;
    text-align: center;
    z-index: 600;

    @include focusStyle;

    &:hover {
      background-color: var(--hoverBackgroundModalCloseButton);
    }

    &:before {
      content: '';
      position: absolute;
      top: -0.3125rem;
      left: -0.3125rem;
      background-color: var(--backgroundModal);
      width: 2.625rem;
      height: 2.625rem;
      transform: translateZ(-0.0625rem);
      border-radius: 50%;
    }

    /* IE specific fix */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &:before {
        background-color: transparent;
      }
    }
  }
}