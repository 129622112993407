.producer-panel-wrapper {
  color: var(--colorTextDarkBg);
  margin-bottom: 2.5rem;

  .panel--crb {
    min-width: 43.75rem;
  }

  h3 {
    font-size: 1.1875rem;
    max-width: 28.125rem;
  }

  .copy {
    position: absolute;
    top: 1.9375rem;
    right: 1.875rem;
  }
}

.producer-form {
  &__fields {
    margin-top: 1.875rem;
    max-width: 43.75rem;
    width: 100%;
  }

  &__field {
    margin-bottom: 1.25rem;

    .toggle-field {
      [role='radio'] {
        flex: 1;
      }
    }
  }

  &__content {
    text-align: center;
  }
  
  &__combined {
    margin-bottom: 2.5rem;
  }

  &__error {
    @include formErrorMessages;
    margin-top: 0.625rem;

    @media (min-width: $md) {
      float: right;
    }
  }
  
  &__panel-sub-section {
    margin-bottom: 1.875rem;
  }
}
.optional-connection-wrapper {
  margin-bottom: 0.9375rem;
  max-width: 43.75rem;
  width: 100%;

  .optional-connection-info {
    margin-bottom: 0.9375rem;

    p {
      font-size: 0.75rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}