.hero {
  position: relative;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.8s, transform 1s;
  transform: translateY(-1.25rem);

  &.dark {
    @include dark-mode;
    color: var(--colorTextDarkBg);
  }

  &.hasChildren {
    margin-bottom: 18.75rem;

    @media(min-width: $lg) {
      min-height: 37.5rem;
    }

    @media(min-width: $xxl) {
      min-height: 42rem;
    }
  }

  &.hasChildren, .noMargin {
    margin-bottom: 0;
  }

  .hero_vid_ctrls {
    z-index: 2;
    bottom: 1.25rem;
  }

  &.wave-bottom {
    .hero_vid_ctrls {
      bottom: 5.9375rem;
    }
  }

  &.isMounted {
    opacity: 1;
    transform: translateY(0rem);
  }

  &__scroll-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;

    &.wave-bottom {
      mask-image: url('../images/wave-mask.svg');
      mask-position: left bottom;
      mask-size: cover;
    }
  }

  &__cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;

    video {
      min-height: 100%;
      min-width: 100%;
    }

    &.bgImage {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__inner {
    max-width: calc(79rem + 3.75rem);
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width: $md) {
      padding-bottom: 5rem;
    }

    @media(min-width: $lg) {
      padding-bottom: 7.5rem;
    }

    &.left {
      position: relative;
      flex-direction: column;
      z-index: 1;

      .hero-image {
        padding-left: 3.75rem;
        padding-right: 3.75rem;
        text-align: center;
      }

      .hero-text {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
      }

      .hero-image,
      .hero-text {
        width: 100%;
      }

      .hero-image {
        padding-bottom: 1.875rem;
      }

      @media(min-width: $lg) {
        flex-direction: row;

        .hero-image,
        .hero-text {
          min-width: 50%;
          padding-bottom: 0;
        }

        .hero-image {
          padding-left: 1.875rem;
          padding-right: 3.75rem;

          img {
            float: right;
          }
        }

        .hero-text {
          padding-right: 1.875rem;
          padding-left: 3.75rem;

          img {
            float: left;
          }
        }

        .hero-text:only-child {
          width: 100%;
          max-width: 47.5rem;
          padding-left: 1.875rem;
          padding-right: 1.875rem;
        }
      }
    }

    &.right {
      position: relative;
      flex-direction: column-reverse;

      .hero-image {
        padding-left: 3.75rem;
        padding-right: 3.75rem;
        text-align: center;
      }

      .hero-text {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
      }

      .hero-image,
      .hero-text {
        width: 100%;
      }

      .hero-image {
        padding-bottom: 1.875rem;
      }

      @media(min-width: $lg) {
        flex-direction: row-reverse;

        .hero-image,
        .hero-text {
          min-width: 50%;
          padding-bottom: 0;
        }

        .hero-image {
          padding-left: 1.875rem;
          padding-right: 3.75rem;

          img {
            float: right;
          }
        }

        .hero-text {
          padding-right: 1.875rem;
          padding-left: 3.75rem;

          img {
            float: left;
          }
        }

        .hero-text:only-child {
          width: 100%;
          max-width: 47.5rem;
          padding-left: 1.875rem;
          padding-right: 1.875rem;
        }
      }
    }
  }
}