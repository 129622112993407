.submit-confirmation-modal {
  &__timeline {
    margin-top: 0.9375rem;
    margin-bottom: 2.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;

    & button {
      @include button-style-lg;
      width: 17.1875rem;
    }
  }
}