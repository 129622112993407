.search-and-filter {
  --period-width: 13rem;

  & .panel__content {
    padding: 1.25rem;
  }

  & .payments-filter-reset-button {
    margin-right: 0.625rem;
  }

  & .payments-filter-search-button button {
    width: 8.25rem;
  }

  &__filter-inputs {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.625rem;
    color: var(--white);

    @media(min-width: $xxl) {
      flex-direction: row;
    }
  }

  &__filter-fields {
    .filter-field {
      display: block;
      width: 100%;
    }

    @media(min-width: $sm) {
      .filter-field {
        &:first-child {
          width: var(--period-width)
        }

        display: inline-block;

        &:not(:first-child) {
          width: var(--period-width);
          margin-right: 0.625rem;
        }
      }
    }

    @media(min-width: $lg) {
      .filter-field {
        &:not(:first-child) {
          margin-right: 0.625rem;
        }

        &:last-child {
          display: inline-block;
        }

      }
    }

    @media(min-width: $xl) {
      .filter-field {
        &:not(:first-child) {
          margin-right: 0.625rem;
        }

        &:last-child {
          display: inline-block;
        }
      }
    }
  }

  .filter-field {
    height: 3.5rem;
    margin-top: 0.225rem;

    .custom-date__label {
      font-weight: 700;
    }

    & .payments-filter-period {
      width: 100%;

      & .date-input-field {
        height: 2.5rem;
        font-weight: 400;
      }

      & .custom-datepicker-button {
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    .panta-select__value-container {
      display: flex;
    }

    @media(min-width: $sm) {
      margin-right: 0.625rem;
      & .payments-filter-period {
        width: var(--period-width);
      }
    }
  }

  &__search-fields {
    display: flex;
    flex-direction: column;

    & .icon-search {
      top: 1.75rem !important;
      left: 0.55rem !important;
    }

    .filter-field {
      &:not(:first-child) {
      }

      width: 100%;

      & > div {
        width: 100%;
      }
    }

    @media(min-width: $sm) {
      flex-direction: row;
      .filter-field {
        &:not(:first-child) {
          margin-right: 0;
        }

        width: var(--period-width);
      }
    }

    @media(min-width: $md) {
      .filter-field {
        width: var(--period-width);
      }
    }
  }
}