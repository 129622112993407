.order-flow {
  &__form {
    position: relative;

    & .extra-content > .error-messages-order-flow {
      display: block;
      max-width: 100%;
      margin-top: 1rem;
      margin-bottom: 0.5rem;

      @media(min-width: $sm) {
        width: 23.4375rem;
      }
    }

    @media(min-width: $xxl) {
      margin-left: 16.25rem;
      margin-right: 16.25rem;
      transition: margin-left 0.15s ease 0.1s, margin-right 0.15s ease 0.1s;

      .open + & {
        margin-left: 33.75rem;
        margin-right: 1.25rem;
      }
    }
  }

  &__cancel-button {
    margin-top: 1.5rem;
    text-align: center;

    button {
      .panicon {
        transform: translateY(0rem);
        margin-right: 0.625rem;
        margin-left: -0.3125rem;
      }
    }
  }
}