.balance-sheet {
  position: relative;
  padding: 0 0.125rem 0.9375rem 0.125rem;
  overflow-x: auto;

  table {
    border-collapse: collapse;
    position: relative;
    width: 100%;
  }

  caption {
    @include h3;
    display: table-caption;
    text-align: left;
  }

  thead {
    @include headingCommon;
    @include small;
    display: table-header-group !important;
    border-bottom: 0.0625rem solid var(--balanceSheetSeparatorColor);

    & td {
      padding: 0.3125rem 1.25rem;
      &:not(:first-child) {
        text-align: right;
      }
    }
  }

  &__separator {
    height: 0.0625rem;
    background-color: var(--balanceSheetSeparatorColor);
    width: 100%;
    margin: 0.9375rem 0 0.3125rem 0;
  }
}