@import './_layout-variables.scss';

.input-file {
  position: relative;
  display: inline-flex;
  flex-direction: column;

  &__label {
    font-weight: var(--font-weight-heavy);
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }

  &__input {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border-radius: 0.4375rem;
    }

    &.error {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineErrorTextInput);
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    input {
      position: absolute;
      width: 0.0625rem;
      height: 0.0625rem;
      opacity: 0;
      overflow: hidden;
      z-index: -1;

      & + label {
        display: inline-block;

        span {
          display: inline-block;
          margin-top: 0.42rem;
        }
      }
    }
  }
}