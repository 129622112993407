.add-contact-person-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .add-user-panel {
    width: 100%;
    margin-top: 3.75rem;

    h4 {
      margin-top: 0;
    }
  }

  &__content {
    margin-bottom: 1.875rem;
  }

  &__choose-user {
    width: 100%;
    max-width: 31.25rem;
    text-align: left;
    margin-bottom: 2.5rem;
  }

  &__buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    & button {
      width: 100%;
    }

    & .add-contact-button {
      display: block;
      width: 100%;
      max-width: 17.1875rem;
      margin-bottom: 0.9375rem;

      button {
        @include button-style-lg;
        width: 100%;
      }
    }

    & .cancel-button {
      margin-left: 0.625rem;
    }
  }

  &__field {
    margin-bottom: 1.25rem;
  }

  &__Label {
    display: block;
    font-weight: var(--font-weight-heavy);
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
  }

  &__checkbox-list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__checkbox-list-item {
    display: inline-block;
    margin-bottom: 0.9375rem;
    width: 100%;

    @media(min-width: $sm) {
      width: 50%;
    }
  }

  &__error-messages {
    width: 100%;
    margin-bottom: 0.9375rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
    }
  }
}