.user-management-block {
  position: relative;
  margin-bottom: 0.9375rem;

  &:last-child {
    padding-bottom: 6rem;
  }

  &__top-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    & .new-user-button {
      margin-top: 0.625rem;
    }

    @media(min-width: $lg) {
      flex-direction: row;
    }
  }

  &__top-html {
    flex: 2;
    margin-bottom: 1.875rem;

    & .new-user-button {
      & button {
        @include button-style-lg;
      }
    }

    @media(min-width: $lg) {
      margin-bottom: 0;
      padding-right: 3rem;
    }
  }

  &__info {
    flex: 1;

    & p {
      @include small;
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 0;
    }

    & .info-panel {
      padding: 1rem 0.5rem;
    }

    @media(min-width: $md) {
      & .info-panel {
        padding: 2rem 1rem;
      }
    }
  }

  &__user-management {
    margin-top: 2.5rem;
  }

  &__bottom {
    padding-top: 2.5rem;
  }

  &__new-user-button-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & .panicon {
      margin-right: 0.625rem;
    }
  }
}