:root {
  // Font weight
  --font-weight-base: 400;
  --font-weight-medium: 500;
  --font-weight-heavy: 700;
  --font-weight-super-heavy: 900;

  @font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham/Gotham-Light.eot');
    src: url('../fonts/Gotham/Gotham-Light.woff2') format('woff2'),
    url('../fonts/Gotham/Gotham-Light.woff') format('woff'),
    url('../fonts/Gotham/Gotham-Light.ttf') format('truetype'),
    url('../fonts/Gotham/Gotham-Light.svg#Gotham-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham/Gotham-LightItalic.eot');
    src: url('../fonts/Gotham/Gotham-LightItalic.woff2') format('woff2'),
    url('../fonts/Gotham/Gotham-LightItalic.woff') format('woff'),
    url('../fonts/Gotham/Gotham-LightItalic.ttf') format('truetype'),
    url('../fonts/Gotham/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham/Gotham-Medium.eot');
    src: url('../fonts/Gotham/Gotham-Medium.woff2') format('woff2'),
    url('../fonts/Gotham/Gotham-Medium.woff') format('woff'),
    url('../fonts/Gotham/Gotham-Medium.ttf') format('truetype'),
    url('../fonts/Gotham/Gotham-Medium.svg#Gotham-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham/Gotham-Black.eot');
    src: url('../fonts/Gotham/Gotham-Black.woff2') format('woff2'),
    url('../fonts/Gotham/Gotham-Black.woff') format('woff'),
    url('../fonts/Gotham/Gotham-Black.ttf') format('truetype'),
    url('../fonts/Gotham/Gotham-Black.svg#Gotham-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham/Gotham-Book.eot');
    src: url('../fonts/Gotham/Gotham-Book.woff2') format('woff2'),
    url('../fonts/Gotham/Gotham-Book.woff') format('woff'),
    url('../fonts/Gotham/Gotham-Book.ttf') format('truetype'),
    url('../fonts/Gotham/Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Pantamera';
    src: url('../fonts/Pantamera/Pantamera-Black.otf');
    src: url('../fonts/Pantamera/Pantamera-Black.woff2') format('woff2'),
    url('../fonts/Pantamera/Pantamera-Black.woff') format('woff'),
    url('../fonts/Pantamera/Pantamera-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  // Font family
  --font-family-base: Gotham, Arial;
  --font-family-pantamera: Pantamera, Arial;

  // Font size
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-24: 1.5rem;
  --font-size-40: 2.5rem;
  --font-size-48: 3rem;
  --font-size-64: 4rem;
  --font-size-72: 4.5rem;

  --font-size-base: var(--font-size-16);

  // Line height
  --line-height-small: 0.75rem;
  --line-height-small-plus: 1;
  --line-height-medium: 1.2;
  --line-height-base: 1.5;
  --line-height-date-picker: 2.25rem;
}

/* General style rules */

body {
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-base);
  font-size: var(--font-size-base);
  line-height: var(--line-height-small-plus);
  color: var(--colorTextBase);
  display: flex;
  flex-direction: column;
}

button,
input,
textarea,
select {
  color: inherit;
  font: inherit;
}

strong {
  font-weight: var(--font-weight-heavy);
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: var(--font-weight-heavy);
}

small {
  @include small;
}

p {
  @include p;
  margin-top: 0;
  margin-bottom: 1rem;
}