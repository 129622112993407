.pin {
  position: absolute;
  transform: translateX(-50%) translateY(-100%);
  //animation: scaleIn 0.5s; // Commented out as the animation is being stupid, maybe we can look at it in the future.
  animation-fill-mode: both;

  @keyframes scaleIn {
    0% {
      opacity: 0;
      transform: translateX(-50%) translateY(-100%) scale(0);
    }
    60% {
      opacity: 1;
      transform: translateX(-50%) translateY(-100%) scale(1.25);
    }
    100% {
      transform: translateX(-50%) translateY(-100%) scale(1);
    }
  }

  &.no-animation {
    animation: none;
  }

  &__svg-container {
    position: relative;
    width: 2.125rem;
    height: 2.8125rem;

    &--featured {
      width: 2.597rem;
      height: 3.4375rem;
    }

    backface-visibility: hidden;
    transform: scale(1) translateZ(0);
    transition: transform 0.25s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2) translateZ(0);
    }

    svg {
      position: absolute;
      top: 0.3125rem;
      left: 0;
      width: 100%;
      z-index: 0;
    }
  }

  &__label {
    z-index: 1;
    font-weight: 700;
    position: relative;
    font-size: 0.75rem;
    text-align: center;
    padding-top: 1.0625rem;
    color: var(--swedenPinLabelColor);

    &--featured {
      padding-top: 1.3125rem;
      color: var(--swedenPinLabelColorFeatured);
    }
  }
}