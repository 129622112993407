.register-flow-step-3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 62.5rem;

  &__panel {
    margin: 1.25rem 0;
    width: 100%;
    color: var(--colorTextDarkBg);

    .panel__content {
      border: 0.0625rem solid;
    }

    @media(min-width: $xl) {
      width: 62.5rem;
    }

    .article-info {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__item {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0.9375rem;

        @media(min-width: $sm) {
          width: 50%;
        }
        @media(min-width: $md) {
          width: 25%;
        }

        & label {
          font-weight: var(--font-weight-heavy);
          font-size: 0.75rem;
          margin-bottom: 0.1875rem;
        }

        & span {
          @include small;
        }
      }
    }

    .material-specification-review-panel {
      position: relative;
    }

    .edit-button {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1.25rem;

      & button {
        width: 6.875rem;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .submit-button {
      margin-top: 0.625rem;
      margin-bottom: 0.9375rem;

      & button {
        @include button-style-lg;
        width: 17.1875rem;
      }
    }

    & .cancel-button {
      button {
        width: 5.625rem;
      }

      &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .panicon {
          margin-right: 0.3125rem;
          margin-left: 0;
        }

        & > span:last-child {
          line-height: 0.75rem;
        }
      }
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 0.625rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}