﻿@mixin allInline {
  & div[data-name="row"] {
    margin: 0;
  }

  label {
    & > div {
      & > div {
        & > div {
          display: inline;
          width: auto;

          & > div {
            display: inline;
            padding: 0;

            p {
              display: inline;
            }
          }
        }
      }
    }
  }
}

@mixin formErrorMessages {
  width: 100%;

  & .danger {
    width: 100%;
  }

  @media (min-width: $md) {
      width: 31.25rem;
  }
}

@mixin formTermsWrapper {
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6,
  & p {
    margin: 0;
  }

  @include allInline;

  .inline-link {
    @include p;
    margin: 0 0.125rem 0 0;
    padding: 0;
    line-height: 1.2;
  }

  .button-link-block {
    margin-bottom: 0;
    width: auto;
    .link span {
      @include p;
    }
  }

  .button-link-block-popup-content {
    & p {
      color: var(--colorTextBase);
    }

    & h1 {
      @include h1;
    }

    & h2 {
      @include h2;
    }

    & h3 {
      @include h3;
    }

    & h4 {
      @include h4;
    }

    & h5 {

      @include h5;
    }

    & h6 {

      @include h6;
    }

    & p {
      margin-block-start: 1rem;
      margin-block-end: 1rem;
    }
  }
}