.footer-lower {
  padding-top: 3.75rem;
  padding-bottom: 3.4375rem;
  text-align: center;
  max-width: 100%;
  margin: auto;

  @media(min-width: $md) {
    max-width: 65%;
  }

  &__container {
    position: relative;
  }

  &__social-link-list {
    padding-left: 0;
    list-style: none;
    margin-bottom: 1.875rem;

    @media(min-width: $xl) {
      margin-bottom: 0;
    }

    li {
      display: inline-block;
      padding: 0 0.9375rem;

      & a {
        display: inline-block;
        border-bottom: none !important;

        &::before {
          border-radius: 50%;
        }
      }
    }
  }

  &__icon-bubble {
    display: block;
    background-color: var(--backgroundFooterUpper);
    padding: 1.0625rem;
    border-radius: 50%;

    .panicon {
      color: var(--colorSocialMediaIcon);
      font-size: 2.25rem;
      min-width: 2.25rem;
    }
  }

  &__policy-link-list {
    padding-left: 0;
    list-style: none;

    @media(min-width: $xl) {
      position: absolute;
      right: 0;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    li {
      display: inline-block;
      color: var(--colorFooterLower);

      &:not(:last-child) {
        &:after {
          content: '|';
          padding: 0 0.9375rem;
          color: var(--colorSeperatorFooterLower);
        }
      }
    }

    .policy-link {
      color: var(--colorLinkFooterLower);
      font-size: 0.75rem;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: var(--colorLinkHoverFooterLower);
      }
    }
  }
}