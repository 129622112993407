.connected-producers-list-header {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-bottom: 1.5625rem;

  @media(min-width: $md) {
    flex-direction: row;
  }

  &__search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0.9375rem;
    width: 100%;

    @media(min-width: $md) {
      align-items: flex-start;
      width: 31.25rem;
      margin-top: 0;
    }

    & .input-search {
      width: 100%;

      & .icon-search {
        font-size: 1rem;
        top: 0.75rem;
        left: 0.55rem;

        @media(min-width: $md) {
          font-size: 1.25rem;
          top: 0.75rem;
          left: 0.55rem;
        }
      }

      & #ean-input {
        height: 3.125rem;
        border-radius: 2.1875rem;
        font-size: 0.75rem;
        padding-left: 2.5rem;

        &::placeholder {
          font-style: normal;
          font-weight: var(--font-weight-heavy);
          color: var(--searchResultsInputTextColor);
        }

        @media(min-width: $md) {
          height: 3.75rem;
          padding-left: 3.125rem;
          font-size: 1.125rem;
        }
      }
    }
  }
}