﻿@import "../../../Frontend/Scss/mixins/button-mixins";
@import "../../../Frontend/Scss/layout-variables";

.company-registrations-block {
  display: inline-block;
  & .new-company-button {
    & button {
      @include button-style-lg;
    }
  }
}