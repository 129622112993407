.report-history-table {
  &__table {
    margin: 1.875rem 0.0625rem;
  }

  &__options {
    display: flex;
    flex-direction: column;

    & button {
      width: 100%;
    }

    & .show-invoice-button,
    & .view-report-button {
      margin: 0 1.25rem 0.625rem 0;
    }

    .button--outlined {
      @include button-outlined;
    }

    @media(min-width: $md) {
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;

      & .show-invoice-button {
        margin: 0.125rem 0.625rem 0.125rem 0;
      }
      & .view-report-button {
        margin: 0.125rem 0.625rem 0.125rem 0;
      }
    }
    @media(min-width: $lg) {
      flex-wrap: initial;
      & .show-invoice-button {
        margin: 0 0.625rem 0 0;
      }
      & .view-report-button {
        margin: 0;
      }
    }
  }
}