.feature-boxed {
  position: relative;
  width: 100%;

  &__container {
    .panel__content {
      padding: 0;
    }
  }

  &__media {
    border-top-left-radius: 0.9375rem;
    border-top-right-radius: 0.9375rem;
    overflow: hidden;
    position: relative;

    video {
      width: 100%;

      &.videoNotVisibleMobile {
        display: none;
        @media(min-width: $md) {
          display: block;
        }
      }
    }

    img {
      width: 100%;
    }

    .feature_vid_ctrls {
      bottom: 1.25rem;
    }
  }

  &__content {
    padding: 1.5rem 1.25rem 1.5rem 1.25rem;
    
    &.boxed-mini {
      text-align: center;
      padding: 2rem 1.25rem 2rem 1.25rem;

      @media(min-width: $md) {
        padding: 2.75rem 2.5rem 2.75rem 2.5rem;
      }
      
      .button-link-block {
        .button-wrapper {
          justify-content: center;
        }
      }
    }

    @media(min-width: $md) {
      padding: 2.75rem 2.5rem 2.75rem 2.5rem;
    }

    .feature {
      &__title {
        @include h4;
        @media(min-width: $md) {
          @include h3;

          &.boxed-mini {
            margin-bottom: 0.75rem;
          }
        }
      }

      &__body {
        &.boxed-mini {
          font-size: 0.875rem;
        }
      }

      &__buttons {
        &:not(:empty) {
          margin-top: 0;

          @media(min-width: $md) {
            margin-top: 0.25rem;
          }
        }
        
        .button-link-block {
          margin-bottom: 0;
        }
      }
    }
  }
}