.edit-user-confirmation-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__info-text {
    max-width: 36rem;
    text-align: center;
  }

  &__button {
    margin-top: 1.25rem;

    & button {
      @include button-style-lg;
      width: 17.1875rem;
    }
  }
}