.next-pickup-order-flow {
  text-align: center;

  .alert {
    margin-top: 1rem;
  }

  &__input {
    text-align: left;
    max-width: 31.25rem;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__button {
    margin-top: 1.875rem;

    button {
      @include button-style-lg;
    }
  }

  &__summary {
    max-width: 43.75rem;
    margin-top: 1.875rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    border-radius: 0.3125rem;
    background-color: var(--extraPickupSummaryBgColor);
    font-size: 0.875rem;

    .summary-property {
      &:first-child {
        margin-right: 1.25rem;
      }
    }
  }
}