.next-pickup {
  &--small {
    .next-pickup__heading {
      color: var(--nextPickupTextColorSmall);
    }

    .next-pickup__date {
      color: var(--nextPickupTextColorSmall);
    }
  }

  &--large {
    margin-top: 2.8125rem;
    padding: 1.875rem;
    border-radius: 0.9375rem;
    background-color: var(--nextPickupBgColorLarge);

    .button-wrapper {
      width: 100%;

      button {
        width: 100%;

        @include button-style-lg;
      }
    }
  }

  &__heading {
    font-size: 1.125rem;
    color: var(--nextPickupTextColorLarge);
    margin-bottom: 0.375rem;
  }

  &__date {
    min-height: 0.0625rem;
    margin-bottom: 0.75rem;
    font-size: 1.875rem;
    font-weight: bold;
    color: var(--nextPickupTextColorLarge);

    &:first-letter {
      text-transform: uppercase;
    }
  }
}