/* Base style and resets */

// General
html,
*,
*::after,
*::before {
  background-color: var(--transparent);
  border: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

html {
  height: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  padding-top: 3.75rem;
  background-color: var(--backgroundBody);
  display: flex;
  position: relative;
  margin: 0;
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(var(--black), 0);
  min-height: 100%;
  overflow-x: hidden;
  
  &.no-padding {
    padding-top: 0;
  }
  
  @media (min-width: $xs) {
    padding-bottom: 0;
  }
  @media (min-width: $xl) {
    padding-top: 0;
  }

  &.backdrop-blur {
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(0, 0, 0, .2);
      opacity: 1;
      z-index: 1;
    }
  }

  &.mobile-menu-open {
    overflow: hidden;
    @media (min-width: 768px) {
      overflow: auto;
    }
  }
  
  &.fullscreen-open {
    overflow: hidden;
    padding-right: 1.0625rem;
  }

  /* if backdrop support: very transparent and blurred */
  @supports ((-webkit-backdrop-filter: blur(0.1875rem)) or (backdrop-filter: blur(0.1875rem))) {
    &.backdrop-blur {
      &:before {
        -webkit-backdrop-filter: blur(0.1875rem);
        backdrop-filter: blur(0.1875rem);
      }
    }
  }
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

// Images
img {
  vertical-align: middle;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1 1 auto;
  width: 100%;

  > div {
    width: 100%;
  }

  ul {
    @include ul;
  }

  ol {
    @include ol;
  }
}

// Tables
table {
  border-collapse: collapse;
  text-align: left;
  vertical-align: top;
}

// Forms
button,
[type="button"],
[type="submit"],
[type="reset"] {
  cursor: pointer;
}

button,
input,
textarea {
  -webkit-appearance: none;
  appearance: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

// Focus style
:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  border: none;
  outline: none;
  outline-offset: 0;
}

/* Helper classes */

.clearfix {
  display: flow-root;
}

// Hide elements from everyone
.hidden,
[hidden] {
  display: none;
}

// Hide elements visually
.sr-only:not(:focus):not(:active) {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 0.0625rem;
  height: 0.0625rem;
  margin: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

.disable-scroll {
  overflow: hidden;
}
