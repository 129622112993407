@mixin small {
  font-size: var(--font-size-12);

  @media (min-width: $md) {
    font-size: var(--font-size-14);
  }
}

@mixin p {
  font-size: 1rem;
  line-height: var(--line-height-base);

  @media (min-width: $md) {
    font-size: 1.125rem;
  }
}