.producers-list {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--backgroundTopListHeader);
    padding: 0.625rem;
    border-radius: 0.3125rem;
    margin-bottom: 0.625rem;
    font-size: 0.625rem;

    & span {
      color: var(--colorTextDarkBg);
      text-transform: uppercase;
      font-weight: var(--font-weight-heavy);
    }

    @media(min-width: $md) {
      font-size: 0.75rem;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--backgroundTopListItem);
    color: var(--colorTextSecondary);
    font-size: 0.875rem;
    text-transform: uppercase;
    padding: 0.4375rem 0.625rem;
    border-radius: 0.3125rem;
    margin-top: 0.375rem;

    & .name {
      font-weight: var(--font-weight-heavy);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & .org-no {
      text-align: right;
      min-width: 5rem;
      text-transform: none;
    }

    @media(min-width: $md) {
      font-size: 1.125rem;

      & .org-no {
        min-width: 6.25rem;
      }
    }
  }
  
  &__button {
    margin-top: 1.25rem;
  }
  
  &__no-results {
    margin-top: 2.5rem;
    text-align: center;
  }
}