.delete-contact-person-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.25rem;

    & .delete-modal-confirm-button {
      width: 100%;
      margin-bottom: 0.625rem;

      button {
        @include button-style-lg;
      }
    }

    @media(min-width: $sm) {
      & .delete-modal-confirm-button {
        width: auto;
      }
    }
  }
}