.sales-statement-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;

  &__sales-year {
    margin-bottom: 0.625rem;
  }

  &__sales-year-text {
    font-size: 1.125rem;
    color: var(--colorTextSalesYear);
    font-weight: var(--font-weight-heavy);
  }

  &__state {
    margin-left: 0;
    max-width: fit-content;
    display: block;
    color: var(--colorTextDarkBg);
    padding: 0.125rem 0.5rem;

    &.preliminary {
      background-color: var(--backgroundDanger);
    }

    @media(min-width: $sm) {
      margin-left: 0.625rem;
      display: inline;
    }
  }

  &__approval-info {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }
}