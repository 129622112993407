.header-bottom {
  position: relative;

  &__bar {
    position: relative;
    display: flex;
    background-color: var(--backgroundColorHeadingTop);
    height: 3.75rem;
    flex-direction: row;

    @media(min-width: $xl) {
      background-color: var(--primary);
    }

    .brand-logo {
      display: flex;
      align-items: center;
      width: 12.5rem;
      transition: opacity 0.2s ease-in-out;
      margin: 0px 1rem;

      & > span {
        text-align: left !important;
      }

      @media(min-width: $xl) {
        pointer-events: none;
        cursor: default;
        opacity: 0;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &__navigation {
    @media(min-width: $xl) {
      margin: 0 auto;
    }

    &.menuOpen {
      .navigation-container {
        left: 0;

        @media(min-width: $md) {
          max-width: 26.25rem;
          left: calc(100% - 26.25rem);
        }
      }
    }

    .navigation-container {
      background-color: var(--primary);
      position: fixed;
      left: 100%;
      height: 100%;
      border: none;
      width: 100%;
      margin-top: 3.75rem;
      transition: left 0.3s ease-in;

      @media(min-width: $xl) {
        position: relative;
        width: 100%;
        left: auto;
        margin-top: 0;
        height: 3.75rem;
        transition-duration: 0s;
      }

      nav {
        height: 100%;

        @media(min-width: $xl) {
          & .expandable-icon {
            display: none;
          }
        }
      }

      &__root-level {
        list-style: none;
        margin-bottom: 0;
        width: 100%;
        position: relative;
        height: calc(100% - 3.75rem);
        padding: 0;
        overflow: auto;

        @media(min-width: $xl) {
          display: flex;
          justify-content: center;
          flex-direction: row;
          border-top: none;
          height: 100%;
        }

        span[role='button'] {
          display: block;

          & span.panicon {
            width: 3.75rem;
            font-size: 1.875rem;
            padding-right: 0.5rem;
          }

          @media(min-width: $xl) {
            & span.panicon {
              width: auto;
            }
          }
        }

        li.current-parent,
        li.current-page {
          position: relative;

          & > span,
          & > a {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 0.25rem;
              height: 100%;
              background-color: var(--backgroundBorderActiveMenuItem);
              @media(min-width: $xl) {
                display: none;
              }
            }
          }
        }
      }

      &__menu-item {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        font-weight: var(--font-family-base);
        border-bottom: 0.0625rem solid var(--borderMenu);
        transition: background-color 0.2s ease-in-out;

        @media(min-width: $xl) {
          height: 3.75rem;
          padding-right: 1.25rem;
          align-items: center;
          justify-content: space-between;
        }

        .panicon {
          color: var(--colorMenuIcon);
          min-width: 1.813rem;
          display: inline-block;
        }

        .expandable-icon {
          position: absolute;
          top: 0.9375rem;
          right: 1.25rem;
        }

        &.expanded {
          & > span {
            color: var(--colorTextMenuLevel1Expanded);

            &:hover {
              color: var(--colorTextMenuLevel1Expanded);
            }
          }

          background-color: var(--backgroundMenuLinkHover);

          &:after {
            position: absolute;
            top: 3.1875rem;
            right: 1.5rem;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5625rem solid var(--backgroundMenuLevel2);
            content: '';
          }
        }

        &:hover {
          @media(min-width: $md) {
            background-color: var(--backgroundMenuLinkHover);
          }
        }

        .menu-item-link {
          color: var(--colorTextMenuLevel1);
          @media(min-width: $xl) {
            pointer-events: none;
          }
        }

        & > span,
        & > a {
          position: relative;
          display: block;
          padding: 0.9375rem;
          height: 100%;
          white-space: nowrap;
          color: var(--colorTextMenuLevel1);
          font-weight: var(--font-weight-heavy);
          font-size: 0.875rem;
          transition: background-color 0.2s ease-in;

          &:hover,
          &:focus {
            cursor: pointer;
            outline: none;
          }

          @media(min-width: $xxl) {
            padding: 0.9375rem 1.75rem;
            font-size: 1rem;
          }
        }

        @media(min-width: $xl) {
          display: flex;
          height: auto;
          border: none;
          padding: 0;
          margin: 0 0.0625rem;
          flex-direction: column;

          &:first-child {
            border: none;
          }

          &.active {
            &:after {
              opacity: 1;
            }

            span:not(.panicon) {
              color: var(--colorTextMenuLevel1);
            }
          }

          &.current-parent {
            &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 0.25rem;
              background-color: var(--backgroundBorderActiveMenuItem);
            }
          }
        }

        &.my-pages {
          @media(min-width: $xl) {
            display: none;
          }
        }
      }

      &__menu-item-logout {
        @media(min-width: $xl) {
          display: none;
        }

        .logout-button {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--backgroundLogoutButton);
          margin: 1.25rem 33%;
          height: 1.875rem;
          border-radius: 1.25rem;
          color: var(--colorTextLogoutButton);
          font-size: 0.75rem;
          padding: 0.4375rem 2.50rem;

          @media(min-width: $xl) {
            display: none;
          }
        }
      }

      &__sub-menu-level {
        padding: 0;
        margin: 0;
        max-height: 0;
        opacity: 0;
        list-style: none;
        position: relative;
        visibility: hidden;
        pointer-events: none;
        background-color: var(--borderMenu);

        transition: max-height 0.3s linear, opacity 0.4s ease-in-out,
        visibility 0.4s ease-in-out;

        &.expanded {
          max-height: 43.75rem;
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
        }

        @media(min-width: $xl) {
          display: none;
        }
      }

      &__sub-menu-item {
        position: relative;
        background-color: var(--backgroundSecondLvl);
        margin-bottom: 0.0625rem;
        transition: background-color 0.2s ease-in-out;

        a {
          position: relative;
          display: block;
          width: 100%;
          padding: 1.25rem 1.875rem;
          color: var(--colorSecondLvl);
          font-size: 0.75rem;
          border: none !important;

          &.link__external:after {
            position: absolute;
            font-family: 'PanIcons';
            transition: right 0.2s linear;
            content: '\e90a';
            font-size: 0.75rem;
            right: auto;
            bottom: 1.3125rem;
            padding-left: 0.3125rem;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          @media(min-width: $md) {
            background-color: var(--backgroundHoverSecondLvl);

            > a {
              color: var(--colorSecondLvl);
            }
          }
        }

        ul {
          background-color: var(--backgroundThirdLvl);
          position: relative;
          margin-top: 0;
          transition: all 0.3s ease-in-out;

          &.expanded {
            &:after {
              position: absolute;
              top: -0.5625rem;
              right: 1.5rem;
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              border-bottom: 0.5625rem solid var(--backgroundThirdLvl);
              content: '';
            }
          }

          li {
            background-color: var(--backgroundThirdLvl);

            &:first-child {
              padding-top: 1.875rem;
            }

            &:last-child {
              padding-bottom: 1.875rem;
            }

            &:hover {
              background-color: var(--backgroundHoverThirdLvlLi);

              > a {
                color: var(--colorThirdLvl);
              }
            }

            a {
              display: block;
              width: 100%;
              padding: 0.625rem 1.875rem;
              color: var(--colorThirdLvl);
              font-size: 0.75rem;
              font-weight: 600;
              background-color: var(--backgroundThirdLvl);
              transition: background-color 0.2s ease;

              span:hover,
              &:hover {
                @media(min-width: $md) {
                  background-color: var(--backgroundHoverThirdLvl);
                }
              }
            }
          }
        }
      }
    }
  }

  .sub-header {
    &__navigation {
      &:not(.bottom) {
        position: absolute;

        @media(min-width: $xs) {
          right: 5.5rem;
        }

        @media(min-width: $md) {
          right: 4.0625rem;
        }

        top: 1.25rem;
      }

      &.header-bottom__nav-right {
        visibility: visible;
        transition: opacity 0.2s ease-in-out;
        pointer-events: auto;

        @media(min-width: $xl) {
          visibility: hidden;
          display: block;
          pointer-events: none;
          opacity: 0;
        }
      }

      &.bottom {
        display: block;
        margin-left: auto;

        ul {
          text-align: center;
        }

        @media(min-width: $xl) {
          margin-left: 0;
          transform: translateX(100%);
          animation: slideOutRight 2s normal backwards;
        }

        .sub-header__navigation-inner {
          position: relative;
          display: flex;
          width: auto;
          height: 100%;
          align-items: center;

          @media(min-width: $xl) {
            width: 12.5rem;
          }

          @media(min-width: $xl) {
            background-color: var(--backgroundHeaderBottomMenuPanel);
            border-top-left-radius: 1.875rem;
            border-bottom-left-radius: 1.875rem;
            padding: 0 1rem;
          }

          & .header-top--link {
            & .panicon:not(.icon-check):hover {
              color: var(--headerTopLinkNavigationInnerHover);
            }
          }
        }
      }
    }

    &__nav {
      width: 100%;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        white-space: nowrap;

        li {
          display: inline-block;
          vertical-align: bottom;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          @media(min-width: $sm) {
            padding: 0 0.75rem;
          }

          @media(min-width: $xl) {
            padding: 0 1.5rem;
          }

          &.header-top--link {
            button,
            span,
            a {
              transition: color 0.2s ease-in-out;
            }

            &:hover {
              button,
              span,
              a {
                color: var(--headerTopLinkHover);
              }
            }
          }
        }

        a,
        button {
          background-color: transparent;
          display: block;
          font-size: 0.875rem;
          font-weight: var(--font-weight-heavy);
          text-decoration: none;
          line-height: 1.6;
          transition: color 0.3s linear;
          border: none;
          padding: 0;
          margin: 0;
          color: var(--colorMenuTopIcon);
          cursor: pointer;

          span.panicon {
            font-size: 1rem;
            color: var(--colorMenuTopIcon);
            line-height: 1.2;
          }

          span:not(.panicon) {
            display: none;

            @media(min-width: $xl) {
              display: inline-block;
              font-size: 0.875rem;
            }
          }
        }
      }

      &.bottom ul {
        li {
          text-align: center;
          padding: 0 0.3rem;

          @media(min-width: $xs) {
            padding: 0 0.625rem;
          }

          span.panicon {
            @media(min-width: $md) {
              color: var(--colorMenuTopIcon);
              font-size: 1.125rem;
              font-weight: var(--font-weight-base);
            }
          }

          span:not(.panicon) {
            display: none;

            @media(min-width: $md) {
              color: var(--colorMenuTopIcon);
              font-size: 0.625rem;
              font-weight: var(--font-weight-base);
            }
          }

          &.header-top--link {
            button,
            span,
            a {
              transition: color 0.2s ease-in-out;
            }

            &:hover {
              button,
              span:not(.panicon),
              a {
                color: var(--headerTopLinkHover);
              }
            }
          }
        }

        .tooltip__wrapper {
          span {
            font-size: 1.125rem;
            font-weight: var(--font-weight-heavy);

            &,
            &.panicon {
              color: var(--colorLinkBase);
            }

            &.panicon:not(.icon-globe) {
              font-size: 0.75rem;
            }
          }
        }
      }

      span {
        display: inline-block;
        max-width: 9.375rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }

  &__menu-button {
    background-color: var(--backgroundHeaderBottomMenuPanel);
    position: relative;
    min-width: 4.375rem;
    cursor: pointer;

    @media(min-width: $xl) {
      display: none;
    }

    .menu-icon {
      height: 0.225rem;
      width: 1.625rem;
      background: var(--colorHamburgerMenuButton);
      margin: 1.75rem auto 0 auto;
      position: relative;
      transition: background 10ms 300ms ease;

      &:before,
      &:after {
        position: absolute;
        transition: top 300ms 350ms ease, transform 300ms 50ms ease;
        background: var(--colorHamburgerMenuButton);
        height: 0.225rem;
        width: 1.625rem;
        content: '';
      }

      &:before {
        top: -0.5rem;
      }

      &:after {
        top: 0.5rem;
      }

      &.menuOpen {
        background: transparent;

        &:after,
        &:before {
          transition: top 300ms 50ms ease, transform 300ms 350ms ease;
          top: 0;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      @media(min-width: $xl) {
        display: none;
      }
    }
  }

  &__message-container {
    left: 0;
    right: 0;
    top: 3.75rem;
  }
}