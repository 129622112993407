.breadcrumbs {
  @include block;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  background-color: var(--backgroundInfo);
  color: var(--colorTextBreadcrumb);
  font-weight: var(--font-weight-heavy);

  @media (min-width: $xs) {
    font-size: 0.625rem;
    border-radius: 0;
    padding: 0.625rem 1.25rem;
  }

  @media (min-width: $md) {
    font-size: 0.75rem;
    padding: 0.875rem 1.25rem;
  }

  @media (min-width: $lg) {
    border-radius: 1.25rem;
    margin-top: 1.875rem;
    max-width: calc(var(--max-full-width-text) + 60px);
  }

  &.wide {
    @media(min-width: $lg) {
      max-width: calc(var(--max-full-width-text-wide) + 60px);
    }
  }
  
  &.my-pages {
    margin-top: 0;
  }

  &__item {
    display: inline-flex;

    &:first-child a {
      margin-left: 0.3125rem;
    }

    &:last-child {
      color: var(--colorTextSecondary);
      font-weight: var(--font-weight-heavy);
    }
  }

  &__separator {
    margin: 0 0.625rem;
    font-weight: var(--font-family-base);
  }
}