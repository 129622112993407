.user-table {
  &__access-level-text {
    font-weight: var(--font-weight-heavy);
    word-break: break-all;
  }

  &__options {
    text-align: left;

    & .delete-user-button {
      display: inline-block;
      width: calc(50% - 0.625rem);
    }

    & .edit-user-button {
      display: inline-block;
      width: calc(50% - 0.625rem);
      margin: 0 0.625rem;
    }

    & button {
      width: 100%;
    }

    @media(min-width: $md) {
      text-align: right;
      min-width: 13.75rem;
      & .delete-user-button {
        width: 6.25rem;
      }
      & .edit-user-button {
        width: 6.25rem;
        margin: 0 0 0 0.625rem;
      }
      & button {
        width: 6.25rem;
      }
    }
  }
}