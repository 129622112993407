@import '../../../../../../Frontend/scss/layout-variables';
@import '../../../../../../Frontend/scss/mixins/layout-mixins';
@import '../../../../../../Frontend/scss/mixins/heading-mixins';

.deposit-label-summary {
  .panel__content {
    padding: 1.25rem 1.25rem 1.75rem 1.25rem;
  }

  .button-wrapper {
    display: block;

    button {
      width: 100%;

      @include button-style-lg
    }
  }

  & > p {
    @include small
  }

  table {
    td:last-child {
      font-weight: 800;
    }
  }

  &__image-container {
    height: 18.125rem;
    background-color: var(--depositLabelSummaryImageBgColor);
    border-radius: 0.9375rem;
    margin-bottom: 1.75rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      max-width: 80%;
    }
  }

  &__label-name {
    @include h5;
    color: var(--colorTextDarkBg);
  }

  &__price-info {
    @include h6;
    margin-bottom: 0rem;
    margin-top: 1.5rem;
  }

  &__shipping-price {
    font-size: 1rem;
    line-height: 1.5;
    font-style: italic;
    margin-bottom: 0.525rem;
  }

  &__price-info-table {
    & table thead th {
      font-size: 0.75rem;
      width: 50%;

      &:first-child {
        padding-right: 0.5rem;
      }
    }
  }
}