.article-details-modal-content {
  &__panel {
    display: flex;
    flex-direction: column;
    color: var(--white);
    @media(min-width: $lg) {
      flex-direction: row;
    }
  }

  &__first-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 4;
    margin-bottom: 0.9375rem;

    @media(min-width: $md) {
      margin-top: 0.9375rem;
    }

    & img {
      max-width: 100%;
      max-height: 25rem;
    }
  }

  &__second-section {
    flex: 6;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.25rem;

    & .unregister-article-button {
      width: 100%;

      & button {
        @include button-style-lg;
        width: 100%;
      }
    }

    @media(min-width: $md) {
      flex-direction: row;

      & .unregister-article-button {
        width: auto;
        margin-left: auto;
        align-self: flex-start;

        & button {
          width: auto;
          margin-left: auto;
        }
      }
    }
  }

  &__badge {
    width: 100%;
    margin-bottom: 0.9375rem;

    @media(min-width: $md) {
      width: auto;
      min-height: 3.75rem;
      margin-right: 0.625rem;
      margin-bottom: 0;
    }
    @media(min-width: $lg) {
      width: 17.5rem;
    }
  }
}