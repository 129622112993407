@mixin focusStyle {
  & {
    outline: none;
    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.25rem);
      border-radius: 0.4375rem;
    }
    &:focus {
      &::before {
        content: '';
        border: 0.125rem solid var(--skyblue);
      }
    }
  }
}
