.register-flow {
  &__form {
    position: relative;
    margin-left: 1.25rem;
    margin-right: 1.25rem;

    @media(min-width: $xxl) {
      margin-left: 16.25rem;
      margin-right: 16.25rem;
      transition: margin-left 0.15s ease 0.1s, margin-right 0.15s ease 0.1s;

      .open + & {
        margin-left: 33.75rem;
        margin-right: 1.25rem;
      }
    }
  }
}