.unregister-modal-content {
  & caption {
    @include h5;
    display: table-caption;
  }

  & td.salesStopDate {
    font-weight: var(--font-weight-heavy);
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.1875rem;

    & button {
      @include button-style-lg;
      width: 17.1875rem;
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 1.875rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }

  &__outer-table {
    position: relative;
  }

  &__table {
    position: relative;
    margin: 1.25rem 0;
    max-height: 40vh;
    overflow-y: auto;
    padding: 0.125rem 0.125rem 5.125rem 0.125rem;
  }

  &__scroll-fade {
    position: absolute;
    top: calc(100% - 5rem);
    left: 0;
    width: 100%;
    height: 5rem;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}