.footer-upper {
  background-color: var(--backgroundFooterUpper);
  padding: 3.75rem 0;
  color: var(--colorFooterUpper);

  .panicon {
    color: var(--colorIconFooterUpper);
  }

  &__sitemap {
    max-width: 53.6875rem;
    display: flex;
    flex-wrap: wrap;
    @media(min-width: $xxl) {
      max-width: 95rem;
    }
    margin: 0 auto;
  }

  &__column {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
    margin: 0 auto;

    @media(min-width: $sm) {
      flex: 1 0 50%;
      max-width: 50%;
    }

    @media(min-width: $xl) {
      flex: 1 0 33.3%;
      max-width: 33.3%;

      &:nth-child(4),
      &:nth-child(5) {
        margin-top: 1.875rem;
      }
    }

    @media(min-width: $xxl) {
      flex: 1 0 18%;
      max-width: 100%;

      &:nth-child(4),
      &:nth-child(5) {
        margin-top: 0;
      }
    }
  }

  &__link-list {
    margin-bottom: 1.875rem;

    @media(min-width: $xl) {
      margin-bottom: 0;
    }
  }

  .box-header {
    @media(min-width: $xs) {
      margin: 0 1.875rem;
    }
    @media(min-width: $md) {
      margin: 0;
    }
    text-align: center;
    border-bottom: 0.0625rem solid var(--colorBoxBodyContainerBorderBottom);
    padding-bottom: 0.9375rem;

    &__icon {
      margin-bottom: 1.5625rem;

      svg {
        max-height: 3.125rem;
        width: 100%;

        path {
          fill: var(--secondary);
          stroke: var(--secondary);
        }
      }

      .panicon {
        font-size: 3.125rem;
        min-width: 3.125rem;
      }
    }

    &__headline {
      font-size: 1.6875rem;
      font-weight: var(--font-weight-heavy);
    }

  }

  .box-body {
    text-align: center;

    &__menu-list {
      list-style: none;
      margin: 0;
      padding-left: 0;
      padding-top: 0.625rem;
    }

    &__menu-list-row {
      list-style: none;
      padding: 0.625rem 0;

      &:last-child {
        padding-bottom: 0;
      }

      a {
        color: var(--colorBoxBodyLink);
        border-bottom: none !important;

        .panicon {
          position: relative;
          right: 0;
          transition: right 0.2s linear;
        }

        &:hover {
          color: var(--colorBoxBodyLink);

          @media(min-width: $lg) {
            .panicon.icon-arrow-right {
              right: -0.25rem;
            }
          }
        }
      }
    }
  }
}