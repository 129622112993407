.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: var(--backgroundBody);
  overflow-y: scroll;
  z-index: -10;
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;
  padding: 3.75rem 0;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 500;
  }
  
  &__message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}