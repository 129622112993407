.checkbox-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.75rem;

  &__hidden-checkbox {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 0.0625rem;
    margin: -0.0625rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0.0625rem;
  }

  &__checkbox {
    display: inline-block;
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--borderCheckbox);
    transition: all ease-in-out 0.1s;
    background: var(--backgroundCheckbox);

    &--checked {
      background: var(--backgroundCheckedCheckbox);
      border: 0.0625rem solid var(--backgroundCheckedCheckbox);

      &.disbaled {
        background: var(--backgroundCheckedCheckbox);
      }

      .panicon {
        visibility: visible !important;
      }
    }

    .checkbox-wrapper__hidden-checkbox:focus + &::before {
      position: absolute;
      content: '';
      top: calc(50% - 0.625rem - 0.0625rem);
      left: -0.0625rem;
      width: calc(1.25rem + 0.125rem);
      height: calc(1.25rem + 0.125rem);
      outline: none;
      border-radius: 0.3125rem;
      border: 0.125rem solid var(--outlineFocusCheckbox);
    }

    &.disabled {
      opacity: 0.5;
      background: var(--backgroundDisabledCheckbox);
    }
  }

  & .panicon {
    position: relative;
    transition: all ease-in-out 0.1s;
    margin-top: 0.25rem;
    visibility: hidden;
  }

  &__label {
    margin-left: 0.3125rem;

    a {
      @include a;
      border-bottom: 2px solid var(--colorLinkBorder);

      &:hover {
        border-bottom: 2px solid var(--colorLinkBorderHover);
      }
    }
  }
}