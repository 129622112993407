.hero-bg-image {
  position: relative;
  width: 100%;
  height: 45vw;
  overflow: hidden;
  mask-image: url("../images/wave-mask.svg");
  mask-position: left bottom;
  mask-size: cover;
  
  &.ratio25 {
    height: 25vw;
  }
  
  &__image {
    width: 100%;
    background-repeat: no-repeat;
  }
}