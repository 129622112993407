.payments-block-widget {
  position: relative;
  height: 100%;

  &__header {
    font-size: 1.125rem;
    color: var(--colorWidgetText);
  }

  &__calculation {
    width: 1px; /* This fixes some strange overflow behaviour in dashboard grid */
    display: flex;
    flex-direction: row;
    color: var(--colorWidgetText);
    margin-top: 1.25rem;
  }

  &__calculation-part {
    display: flex;
    flex-direction: column;
    font-weight: var(--font-weight-heavy);
  }

  &__part-label {
    font-size: 0.5rem;
    margin-bottom: 0.3125rem;
    white-space: nowrap;

    @media(min-width: $sm) {
      font-size: 0.625rem;
    }

    @media(min-width: $xl) {
      font-size: 0.5625rem;
    }

    @media(min-width: $xxl) {
      font-size: 0.625rem;
    }
  }

  &__part-value {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--backgroundTextInput);
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--borderTextInput);
    height: 2.125rem;
    width: 4.375rem;

    & > span {
      color: var(--colorTextBase);
      font-size: 0.625rem;
      font-weight: var(--font-weight-heavy);
    }

    @media(min-width: $sm) {
      height: 3.125rem;
      width: 6.625rem;

      & > span {
        font-size: 0.75rem;
      }
    }

    @media(min-width: $xl) {
      height: 2.5rem;
      width: 5.5rem;

      & > span {
        font-size: 0.6875rem;
      }
    }

    @media(min-width: $xxl) {
      height: 3.125rem;
      width: 6.625rem;

      & > span {
        font-size: 0.75rem;
      }
    }
  }

  &__calculation-operation {
    font-size: 1rem;
    margin: auto 0.25rem;
    padding-top: 0.625rem;

    @media(min-width: $xxl) {
      font-size: 1.5rem;
    }
  }

  &__report-table {
    margin-top: 1.25rem;
    @include widget-table;

    & th.date,
    & td.date {
      min-width: 6.5625rem;
    }

    & td.date span {
      font-size: 0.75rem;
      padding-bottom: 0;
    }

    & td.amount,
    & td.net {
      font-weight: var(--font-weight-heavy);
    }

    @media(min-width: $md) {
      & th:not(.type):not(.date),
      & td:not(.type):not(.date) {
        text-align: right;
      }
    }
  }

  &__disclaimer {
    display: block;
    font-size: 0.625rem;
    color: var(--colorWidgetText);
    margin: 0.625rem 0 0.9375rem 0;
    font-weight: var(--font-weight-heavy);
  }

  &__bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__payments-header {
    @include headingCommon;
    color: var(--colorWidgetText);
    font-size: 1.875rem;
  }
}