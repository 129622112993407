.future-pickups-equipment {
  .panicon {
    margin-right: 1rem;
    color: var(--nBinsIconColor);
  }

  &__n-bins {
    margin-right: 1rem;
    font-size: 0.875rem;
    font-weight: 800;
    color: var(--nBinsColor);
  }

  &__submit-ok {
    text-align: center;

    .button-wrapper {
      margin-top: 1rem;

      button {
        @include button-style-lg;
      }
    }
  }
}