.upload-modal-content {

  &__heading {
    padding-top: 2.5rem;
    margin-bottom: 0;
    text-align: center;

    > span {
      @include h3;
    }
  }

  &__body {
    text-align: center;
  }

  &__file-name {
    font-size: 1.125rem;

    & .panicon {
      margin-right: 0.3125rem;
    }
  }

  &__close-button-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & .panicon {
      margin-right: 0.3125rem;
    }

    & > span:last-child {
      line-height: 0.75rem;
    }
  }

  &__error-text {
    display: block;
    font-size: 1.125rem;
  }

  &__button {
    margin-top: 1.25rem;

    & .upload-preview-button > button,
    & .close-button > button {
      @include button-style-lg;
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 1.25rem;
    text-align: left;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}