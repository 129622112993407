.delete-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__buttons {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.25rem;

    & .delete-modal-confirm-button {
      width: 100%;
      margin-bottom: 0.625rem;
    }

    & .delete-modal-close-button {
      width: 100%;
    }

    & button {
      @include button-style-lg;
      width: 100%;
    }

    @media(min-width: $sm) {
      flex-direction: row;

      & .delete-modal-confirm-button {
        width: auto;
        margin-right: 0.625rem;
        margin-bottom: 0;
      }
      & .delete-modal-close-button,
      & button {
        width: auto;
      }
    }
  }

  &__error-messages {
    width: 100%;
    margin-top: 0.625rem;

    & .danger {
      width: 100%;
    }

    @media(min-width: $md) {
      width: 31.25rem;
      float: right;
    }
  }
}