.order-confirmation-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & button {
    @include button-style-lg;
    width: 10rem;
  }

  & .bag-labels-confirmation-close-button {
    margin: 1.25rem 0;
  }
}