.unreported-block {
  position: relative;
  margin-bottom: 0.9375rem;

  &:last-child {
    padding-bottom: 6rem;
  }

  &__report {
    padding-bottom: 0.9375rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}