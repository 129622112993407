@import "../../../../Frontend/scss/layout-variables";

.top-list-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5625rem;
  flex-direction: row;

  @media (min-width: $md) {
    flex-direction: row;
  }

  &__header {
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
    margin-bottom: 2rem;

    & h1 {
      color: var(--associationBattleYellow);
      margin-bottom: 0;
    }
  }

  &__image {
    width: 5rem;

    & img {
      width: 100%;
    }
  }

  &__select {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__search {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 40rem;
    margin-top: 0;


    @media (min-width: $md) {
      align-items: flex-start;
      width: 31.25rem;
      margin-top: 0;
    }

    & .input-search {
      width: 100%;
      font-size: 1rem;

      & .icon-search {
        left: 0.6rem;
        font-weight: 1000;
        color: var(--brightsun) !important;
        top: 0.913rem;
      }

      & #ean-input {
        border-radius: 2.1875rem;
        height: 3.75rem;
        padding-left: 3.125rem;
        font-size: 1.125rem;

        &::placeholder {
          font-style: normal;
          font-weight: 700;
          color: var(--brightsun);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        @media(min-width: $md) {
          height: 3.75rem;
          padding-left: 3.125rem;
          font-size: var(--fontSize18);
        }
      }
    }
  }

    &__county-select {
      display: flex;
      flex-direction: column;
      width: 73%;
      margin-bottom: 0.9375rem;
      font-style: normal;
      font-weight: 700;

      & .panta-select__single-value {
        color: var(--blue);
        line-height: 1.2;
        font-size: 0.7rem;

        @media(min-width: $md) {
          font-size: 0.8rem;
        }
      }

      .input-select__select-wrapper {
          width: 100%;
        
        & .panta-select__indicators {
          width: 2.5rem;
          margin-top: 0rem;
          margin-bottom: 0rem;
          margin-right: 0rem;
          background-color: var(--brightsun);
          color: var(--blue) !important;

          & .panicon {
            font-size: 1.28rem;
          }
        }
      }
    }

    &__year-select {
      display: flex;
      flex-direction: column;
      width: 25%;
      margin-bottom: 0.9375rem;
      font-style: normal;
      font-weight: 700;

      & .panta-select__single-value {
        color: var(--blue);
        line-height: 1.2;
        font-size: 0.8rem;
      }

      & .panta-select__indicators {
        width: 2.5rem;
        margin-top: 0rem;
        margin-bottom: 0rem;
        margin-right: 0rem;
        background-color: var(--brightsun);
        color: var(--blue);

        & .panicon {
          font-size: 1.28rem;
        }

        @media(min-width: $md) {
          width: 2.5rem;
        }
      }
    }
}