.input-text-area {
  position: relative;
  display: flex;
  flex-direction: column;

  &__wrapper {
    position: relative;

    &::before {
      position: absolute;
      top: -0.125rem;
      left: -0.125rem;
      width: calc(100% + 0.25rem);
      height: calc(100% + 0.0625rem);
      border-radius: 0.4375rem;
    }

    &:focus-within {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineFocusTextInput);
      }
    }

    &.error {
      &::before {
        content: '';
        border: 0.125rem solid var(--outlineErrorTextInput);
      }
    }
  }

  label {
    font-weight: var(--font-weight-heavy);
    font-size: 0.75rem;
    margin-bottom: 0.3125rem;
  }

  textarea {
    position: relative;
    display: inline-block;
    width: 100%;
    font-family: var(--font-family-base);
    min-width: 6.25rem;
    background: var(--backgroundTextInput);
    border-radius: 0.3125rem;
    border: 0.0625rem solid var(--borderColorLightBlue);
    transition: all ease-in-out 0.1s;
    padding: 0.625rem 0.9375rem;
    resize: none;

    &:disabled {
      background: var(--backgroundDisabledTextInput);
    }

    &::placeholder {
      font-style: italic;
    }
    &:focus {
      outline: none;
    }
  }
}